<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Logs</span>
        </v-card-title>
        <v-card-text>
            <DataTable />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="$emit('cancel')"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DataTable from "./DataTable.vue";
export default {
    name: 'Dialog',
    props: ["value"],
    watch: {
        value(v){
            this.dialog = v
            
        }
    },
    components: {
        DataTable
    },
    data() {
        return {
            dialog: null
        }
    },


}
</script>