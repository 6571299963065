<template>
  <div>
    <Message msg="Confirme sua senha para continuar" color="primary" type="info" />
    <v-app id="inspire">
      <v-main>
        <v-container fluid fill-height>
          <v-layout align-center justify-center >
            <v-flex xs5 sm8 md4>
              <v-card class="elevation-8">
                <v-toolbar dark :color="colorCard == null ? 'white' : colorCard">
                  <v-toolbar-title>Confirmar Senha</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form ref="form" @submit.prevent="updatePassword()">
                    <v-col cols="12" align="center" >
                      <v-img  
                        :src="logo"
                         max-width="180"
                         max-height="200"
                      />
                    </v-col>
                    <v-text-field
                      name="confirm"
                      label="Senha antiga"
                      type="password"
                      v-model="oldPassword"
                    ></v-text-field>
                    <v-text-field
                      label="Nova senha"
                      type="password"
                      :rules="requiredRule"
                      v-model="password"
                    ></v-text-field>
                    <v-text-field
                      name="confirm"
                      label="Confirmar Senha"
                      type="password"
                      v-model="confirmPassword"
                    ></v-text-field>
                  <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="submit">Confirmar</v-btn>
                </v-card-actions>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Message from '../message/Message.vue';
export default {
    components: {
        Message
    },
    data(){
        return {
            password: '',
            confirmPassword: '',
            oldPassword: '',

            logo: null,
            colorCard: null,

            valid: true,
            requiredRule: [v => !!v || "Campo obrigatório!", v => v.length >= 6 || 
          'Precisa ter no minimo 6 caracteries', 
          v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'A senha deve conter pelo menos uma letra minúscula e um número, um caractere especial @%$ e uma letra maiúscula',
          v => /(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#;$%*(){}_+^&]/.test(v) || "Faltou algum caraterie especial"]
        }
    },

    methods: {

        validate() {
            return this.$refs.form.validate();
        },

        async getTenanty(){
          const lc = window.localStorage.getItem("user-login");
          const user = JSON.parse(lc);
          let tenanty = user[0];

          var req = await this.$profile.getByTenanty(tenanty);
          if(req.data.data != undefined){
            this.logo = `${process.env.VUE_APP_IMG_URL}/${req.data.data.logo}`;
            this.colorCard = req.data.data.color;
          }
        },

        async updatePassword(){
            if(this.validate()){
              if(this.password == this.confirmPassword && this.password != '' && this.confirmPassword != ''){
                  const data = await this.$auth.updatePassword(await this.getUserId(), this.oldPassword, this.password);
                  console.log(data)
                  if(data.success != false){
                      this.$notfy.success("Senha atualizada com sucesso");
                      await this.updateIndexDb();
                      this.$router.push("/home");
                  }
                  } else {
                      this.$notfy.error("Senhas não conferem");
                  }
            } else {
              this.$notfy.error("Algum campo não foi preenchido corretamente"); 
            }
        },

        async updateIndexDb(){
            const data = await this.$auth.getUserParams();
            data.firstLogin = false;
            await this.$auth.saveUserParams(data);
        },

        async getUserId(){
            const data = await this.$auth.getUserParams();
            const userId = data.userId;

            return userId;
        }
    },

    mounted() {
        this.getTenanty();
    }
}

</script>

<style scoped>
.v-card {
  margin-bottom: 100px;
}
</style>