import Vue from "vue";

export default {

    async insertSchemaRecord(body){
        return await Vue.prototype.$http.post('/SharedSchemaRecord/insert', body);
    },

    async updateSchemaRecord(body, id){
        return await Vue.prototype.$http.put('/SharedSchemaRecord/update', body, {
            params: { id: id }
        })
    },

    async deleteSharedSchemaRecord(id, idReceive) {
        return await Vue.prototype.$http.delete('/SharedSchemaRecord/deleteByUserIdReceive', { params: {
            id: id,
            userIdReceive: idReceive
        }})
    },

    async getByIdtSchemaRecord(id){
        return await Vue.prototype.$http.get('/SharedSchemaRecord/getById', {
            params: { id: id }
        })
    },

    async getByNameSchemaRecord(schemaName, userIdReceive){
        return await Vue.prototype.$http.get(`/SharedSchemaRecord/${schemaName},${userIdReceive}/getBySchemaNameAndUserIdReceive`)
    },

    async getAllBySchemaRecordId(recordId, idSender){
        return await Vue.prototype.$http.get('/SharedSchemaRecord/getAllBySchemaRecordIdAndUserIdSender', {
            params: {
                schemaRecordId: recordId,
                userIdSender: idSender,
                page: 1,
                limit: 5
            }
        })
    },

    async getAllByUserIdReceive(receiveId){
        return await Vue.prototype.$http.get('/SharedSchemaRecord/getAllByUserIdReceive', {
            params: {
                userIdReceive: receiveId
            }
        })
    },

    async getAllSchemaRecord(){
        return await Vue.prototype.$http.get('/SharedSchemaRecord/getAll')
    }

}