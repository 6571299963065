<template>
	<v-runtime-template :template="result.view"></v-runtime-template>
</template>

<script>
	import VRuntimeTemplate from "v-runtime-template";
	import * as VuetifyComponents from "vuetify/lib/components";

	export default {
		name: "ActionPdfMake",

		props: {
			result: Object,
		},

		components: {
			VRuntimeTemplate,
			...VuetifyComponents,
		},

		data() {
			return {
				...this.result?.data,
			};
		},

		methods: {
			async pdfPrinter(definition, name) {
				console.log('definition ', definition)
				try {
					var pdfMake = require("pdfmake/build/pdfmake.js");
					var pdfFonts = await require("pdfmake/build/vfs_fonts.js");
					pdfMake.vfs = pdfFonts.pdfMake.vfs;
					//console.log('definition ', definition.header.columns[0].image)

					if(definition?.header?.columns[0].image){
						const url = definition.header.columns[0].image
						const url64 = await this.getBase64FromUrl(url.toString())
						definition.header.columns[0].image = url64
					}
/*
					if(definition.content[0].table.body[0][0].image){
						const url = definition.content[0].table.body[0][0].image
						const url64 = await this.getBase64FromUrl(url.toString())
						definition.content[0].table.body[0][0].image = url64
					}
*/
					if(definition.content[0].image){
						const url = definition.content[0].image
						const url64 = await this.getBase64FromUrl(url.toString())
						definition.content[0].image = url64
					}

					if(this.result.footer){
						let columns = this.result.footer
						console.log("COLUMNS >>> ", columns)
						definition.footer = function(currentPage, pageCount) { //currentPage, pageCount

							return {
								margin: [20, 20, 20, 20],
								/*
								table: {
									body: 
									[
										[
											{
												text: 'Page ' +  currentPage.toString() + ' of ' + pageCount
											}
										],
									]
		
								},*/

								columns: [
									{
										columns: [{ text: 'Page ' +  currentPage.toString() + ' de ' + pageCount, alignment:"center", }]
									},
									/*
									{
									 ...columns,
									 alignment:"center"
									}*/
								]
							}
		
						}
					}

					console.log("FOOTER >>> ", definition.footer)

					pdfMake.createPdf(definition).download(name);
					this.$emit("close");
				} catch (err) {
					this.result.view = `<v-card-text class="black--text">
          ${err} </v-card-text>`;
				}
			},

          async getBase64FromUrl(url) {
            const data = await fetch(url);
            const blob = await data.blob();
            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob); 
              reader.onloadend = () => {
                const base64data = reader.result;   
                resolve(base64data);
				//console.log('base64data: ', base64data)
              }
            });
          },

			close() {
				this.$emit("close");
			},
		},

		created: function() {
			this.pdfPrinter(this.result.data, this.result.name);
		},

		mounted() {},
	};
</script>
