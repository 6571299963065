<template>
  <v-data-table
    :items="items"
    :headers="headers"
  >
    <template v-slot:[`footer.prepend`]>

      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" @click="exportAsExcelFile" class="ml-1">
            mdi-microsoft-excel
          </v-icon>
        </template>
        Exportar como XLSX
      </v-tooltip>

    </template>
  </v-data-table>
</template>

<script>
import xlsx from 'json-as-xlsx'

export default {

  name: 'ActionResutGrid',

  props: {
    result: Object
  },

  computed: {

    items () {
      if (!this.result?.data) return []
      else return this.result?.data
    },

    headers () {
      if (this.result?.headers) return this.result?.headers
      else if (!this.result?.data?.length) return []
      else return Object.keys(this.result?.data?.[0]).map(key => {
        return {
          text: key,
          value: key
        }
      })
    }
  },

  methods: {

    exportAsExcelFile () {
      const columns = this.headers.map(header => {
        return {
          label: header.text,
          value: header.value
        }
      })
      const content = this.items

      const data = [
        {
          sheet: 'Planilha',
          columns,
          content
        }
      ]

      const options = {
        fileName: 'SimjobExport'
      }

      xlsx(data, options)
    }

  }

}
</script>

<style>

</style>