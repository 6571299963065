<template>
    <v-row class="justify-center">
        <v-col>
            <h1>{{ viewName }}</h1>
            
            <v-row style="align-items: center; justify-content: center">
                
                <v-col cols="12" md="5" class="mt-7" v-for="(item, index) in params" :key="index">
                        <v-text-field
                            v-model="params[index].value"
                            outlined
                            :type="item.dataType"
                            :label="item.name"
                            :value="item.value"
                        ></v-text-field>
                </v-col>
                <v-btn @click="viewRun()" v-if="params.length > 0" color="green">Executar</v-btn>
            
                
                <v-col cols="5" md="5" style="margin: 5px">
                    <v-combobox
                        outlined
                        style="padding: 10px; height: 55px"
                        class="form-control"
                        label="Preset de pesquisa"
                        :items="presetList"
                        item-text="name"
                        item-value="rowsPreset"
                        v-model="selectPreset"
                        :append-icon="selectPreset !== '' ? 'mdi-close' : ''"
                        @click:append="selectPreset = ''"
                    ></v-combobox>
                </v-col>
                
                <v-icon @click="exportAsExcelFile()" style="font-size: 25px">
                    mdi-microsoft-excel
                </v-icon>
                
                <v-btn color="primary" icon dark class="mr-2">
                    <i
                        class="bi bi-funnel"
                        style="font-size: 25px"
                        @click="dialog = !dialog"
                    ></i>
                </v-btn>
            </v-row>

            <div v-if="result !== null">
                <ActionPivoTable 
                    @newsRows="newsRows" 
                    @newsCols="newsCols" 
                    :result="result" 
                    @rows="handleRows" 
                    :rows="objPreset.rowsPreset" 
                    :cols="objPreset.columnsPreset" />
            </div>

            <div v-else class="d-flex justify-center">
                <v-progress-circular 
                    model-value="20" 
                    :size="83" 
                    :width="10"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </div>
            
        </v-col>

        <v-dialog v-model="dialog" class="align-center pa-0">
            <v-card class="pa-5">
                <v-card-title>Salvar Preset</v-card-title>
                <v-text-field
                    v-model="objPreset.name"
                    label="Nome"
                    type="text"
                    class="form-control"
                    outlined
                ></v-text-field>
                    <v-combobox
                        v-model="rowsPreset"
                        outlined
                        :items="rows"
                        chips
                        label="Rows"
                        multiple
                    ></v-combobox>
                    <v-combobox
                        v-model="columnsPreset"
                        outlined
                        :items="columns"
                        chips
                        label="Cols"
                        multiple
                    ></v-combobox>
                <v-card-actions>
                     <v-btn color="primary" @click="savePreset">Salvar Relatório</v-btn>
                     <v-btn color="error" @click="dialog = !dialog">Cancelar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import ActionPivoTable from '../../components/dialogs/actionPivoTable.vue';
import EntityService from '../../services/entityService';
import xlsx from "json-as-xlsx";

export default {
    name: 'PivoTableView',
    props: ['viewId', 'viewName'],
    data(){
        return {
            entityService: new EntityService('Analysis'),
            select: '',
            searchData: '',
            search: '',
            analysis: [],
            result: null,
            key: 0,
            objAnalysis: {},
            params: [],
            valueParam: [],
            presetList: [],
            objPreset:
                {
                    name: '',
                    rowsPreset: [],
                    columnsPreset: []
            },
            selectPreset: '',
            componentKey: 0,
            dialog: false,
            rows: [],
            columns: [],
            
            rowsPreset: [],
            columnsPreset: []
        }
    },
    components: {
        ActionPivoTable
    },

    watch: {
        select(){
            this.pagination.page = 1
        },

        selectPreset(value){
            this.objPreset.name = value.name
            this.objPreset.rowsPreset = value.rowsPreset
            this.objPreset.columnsPreset = value.columnsPreset

            this.rowsPreset = value.rowsPreset
            this.columnsPreset = value.columnsPreset


            //this.componentKey += 1
        },

        async viewId(){
            this.onCreated()
        }
    },

    async mounted(){
        this.onCreated()
    },

    methods: {
        /*
        async getAnalysis(){
            const req = await this.entityService.getAll(1, 30, this.search, '', '')
            return req.data.data.data;
        },*/

        async onCreated(){
            this.params = []
            await this.viewRun()
            await this.viewSearchItem()
            let pretes = this.$cookies.get(`presetPivoTable@${this.viewName}`)
            if(pretes !== null){
                this.presetList = JSON.parse(pretes)
            } else {
                this.objPreset = {
                    name: '',
                    rowsPreset: [],
                    columnsPreset: []
                }

                this.selectPreset = ''
                this.presetList = []
            }
        },

        async viewRun(){
            //this.viewId = this.$route.params.viewId
            const data = await this.$viewService.viewRun(this.viewId, 1, this.params);
            this.result = data.data.data
            if(this.result.data.length > 0){
                this.rows = Object.keys(this.result.data[0])
                this.columns = Object.keys(this.result.data[0])
            } 
        },

        async viewSearchItem(){
            const dataGetView = await this.$viewService.viewSearch("_id", this.viewId)
            this.params = dataGetView.data.data.data[0].parameters
        },
        

        handleRows(value){
            /*
            this.objPreset.rowsPreset = value.rows;
            this.rowsPreset = value.rows;

            this.objPreset.columnsPreset = value.cols;
            this.columnsPreset = value.cols*/
            console.log(value)
        },

        savePreset(){
            let arrayPreset = []
            if(this.objPreset.name == ''){
                this.$notfy.error("Erro, nome não foi informado.");
                return;
            }  /*else if(this.objPreset.rowsPreset.length == 0 || this.objPreset.columnsPreset.length == 0){
                this.$notfy.error("Erro, não foi selecionado os items.");
                return;
            }*/

            this.objPreset.columnsPreset = this.columnsPreset
            this.objPreset.rowsPreset = this.rowsPreset

            let presets = this.$cookies.get(`presetPivoTable@${this.viewName}`)
            if(presets !== null){
                arrayPreset = JSON.parse(presets)

                arrayPreset.map((preset, index) => {
                    if(this.selectPreset.name === preset.name){
                        arrayPreset.pop(index)
                    }
                })
            }

            arrayPreset.push(this.objPreset)
            this.$cookies.set(`presetPivoTable@${this.viewName}`, JSON.stringify(arrayPreset))
            this.$notfy.success("Salvo com sucesso");
            this.objPreset.name = ''
            
            let pretes = this.$cookies.get(`presetPivoTable@${this.viewName}`)
            this.presetList = JSON.parse(pretes)
            this.dialog = false
        },

        newsRows(value){
            this.rowsPreset = value
        },

        newsCols(value){
            this.columnsPreset = value
        },

        exportAsExcelFile() {

            let rows = this.objPreset?.rowsPreset?.map((resp) => {
                return {
                    label: resp,
                    value: resp
                }
            })

            let colums = this.objPreset?.columnsPreset?.map((resp) => {
                return {
                    label: resp,
                    value: resp
                }
            })

            let cols = [ ...rows, ...colums ]

            const data = [
                {
                    sheet: "Planilha",
                    columns: cols,
                    content: this.result.data
                },
            ];

            const options = {
                fileName: this.objPreset.name === '' ? 'ExportSimjob' : this.objPreset.name,
            };

            xlsx(data, options);
        },
    }
}
</script>