import Vue from 'vue'

export default {

    async getNotification(page){
        return await Vue.prototype.$http.get('Notifications/getNotifications', 
            { params: { page: page, limit: 20, sortField: 'createAt', sortDesc: true } });
    },

    async verifyNotification(recordId){
        return await Vue.prototype.$http.get('Notifications/verifyNotification', { params: { 
            schemaRecordId: recordId
        }});
    },

    async notificationUpdate(obj){
        return await Vue.prototype.$http.put('Notifications/notificationsUpdate', obj);
    },

    async viewUpdate(notificationId){
        return await Vue.prototype.$http.put('Notifications/notificationsViewUpdate?notificationId=' + notificationId);
    }

}