<template>
    <div>
        <v-container>
            <v-row v-if="entityService">
                <v-col>
                    <FormTable :schemaName="'Bucket'" />
                </v-col>
            </v-row>

            <v-row v-else>
                <h3>Não possui bucket</h3>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import EntityService from '../../services/entityService';
import FormTable from "../table/FormTable.vue";
export default {
    components: {
        FormTable
    },
    data(){
        return {
            entityService: new EntityService('Bucket'),
        }
    },

    mounted(){
        //console.log('entityService ', this.entityService)
    }
}
</script>