import Vue from "vue";

export default {

    async getWeeklySchedule(page, dataInicio, dataFim, fornecedorId){
        return await Vue.prototype.$http.get('/Entity/WeeklySchedule', {
            params: {
                page: page, limit: 30, dataInicio: dataInicio, dataFim: dataFim, fornecedorId
            }
        });
    },
}