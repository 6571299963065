<template>
  <v-dialog
    v-model="dialog"
    @click:outside="$emit('close')"
    scrollable
    content-class="my-custom-dialog"
  >
    <v-card>
      <v-app-bar flat color="transparent">
        <v-btn large icon @click="$emit('cancel')">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Atualizar dados so usuário {{ name }}</v-toolbar-title>
        <v-spacer> </v-spacer>

        <!-- <v-btn large icon color="error" @click="$emit('delete')">
          <v-icon>mdi-delete</v-icon>
        </v-btn> $emit('save') $emit('cancel') -->

        <v-btn large icon color="success" @click="update()">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-app-bar>

      <v-container>
        <v-form>
          <v-text-field
            v-model="name"
            :readonly="true"
            label="Nome"
            placeholder="Nome do usuário"
            required
            :rules="codeRules"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="email"
            :readonly="true"
            label="E-mail"
            placeholder="E-mail do usuário"
            required
            :rules="emailRules"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="telefone"
            label="Telefone"
            placeholder="Telefone do usuário"
            required
            v-mask="'(##) #####-####'"
            outlined
            dense
          ></v-text-field>
          <v-autocomplete
            v-model="groupId"
            :items="group"
            :search-input.sync="search"
            item-text="name"
            item-value="id"
            prepend-icon="mdi-database-search"
            label="Grupos"
            dense
            outlined
          ></v-autocomplete>

        <v-autocomplete
          v-model="compayIds"
          :search-input.sync="searchCompany"
          :multiple="true"
          :items="companies"
          label="Filial"
          dense
          outlined
          item-text="code"
          item-value="id"
          prepend-icon="mdi-database-search"
          chips
        >
        </v-autocomplete>
        
        <v-autocomplete
            v-model="principal"
            @focus="getPrincipalCompany"
            type="text"
            label="Principal"
            :search-input.sync="searchPrincipalCompany"
            placeholder="Principal"
            outlined
            dense
            :items="principalCompay"
            item-text="code"
            item-value="id"
            chips
        ></v-autocomplete>

        </v-form>
        <h3>{{ msgH3 }}</h3>
        <v-switch v-model="logonAzure"
        ></v-switch>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import EntityService from '../../services/entityService';
export default {
  props: ["value", "user"],

  data() {
    return {
      searchCompany: '',
      searchPrincipalCompany: '',
      search: '',
      isLoading: true,
      select: null,
      groups: [],
      group: [],
      dialog: false,
      req: {},
      name: this.user.name,
      email: this.user.userName,
      telefone: this.user.telefone,
      groupId: this.user.groupId,
      logonAzure: this.user.logonAzure,
      codeRules: [(v) => !!v || "Campo obrigatório!"],
      emailRules: [
        (v) => !!v || "Campo obrigatório!",
        (v) => /.+@.+\..+/.test(v) || "E-mail invalido",
      ],
      compayIds: [],
      companies: [],
      principalCompay: [],
      principal: '',
      entityService: new EntityService("CompanySite")
    };
  },

  watch: {
    value(v) {
      this.dialog = v;
      this.id = this.user.id;
      this.name = this.user.name;
      this.email = this.user.userName;
      this.telefone = this.user.telefone;
      this.groupId = this.user.groupId;
      this.logonAzure = this.user.logonAzure
      this.compayIds = this.user.companySiteIds
      this.principal = this.user.companySiteIdDefault

      this.findGroupById(this.groupId)
      this.getCompanies()
      this.getPrincipalCompany()
    },

    search(){
        this.findGroups()
    },

    searchCompany(value){
      this.getCompanies(value)
    },

    searchPrincipalCompany(){
      //this.getPrincipalCompany()
    }
  },

  computed: {
    msgH3(){

      if(this.logonAzure == false){
        return 'Ativar login com azure ?'
      }

      return 'Desativar login com azure ?'
    }
  },

  methods: {
    async update() {
      const model = {
        userId: this.id,
        tenanty: this.getTenanty(),
        name: this.name,
        email: this.email,
        telefone: this.telefone,
        groupId: this.groupId,
        logonAzure: this.logonAzure,
        companySiteIds: this.compayIds,
        companySiteIdDefault: this.principal
      };

      const req = await this.$auth.updateUser(model);
      console.log(req);

      if (req.data.success == true) {
        this.$notfy.success("Usuário atualizado");
        this.dialog = false;
        this.$emit("updated");
      }
      //window.location.reload()
    },

    getTenanty() {
      const lc = window.localStorage.getItem("user-login");
      const user = JSON.parse(lc);
      return user[0];
    },

    async getUserCompaySite(){
      let data = await this.$auth.getUserParams()
      console.log('data ', data?.companySiteIds.split(','))
      return data?.companySiteIds.split(',')
    },

    async getCompanies(value){
      let data
      if(!value){
        data = await this.entityService.getAll(1, 1000, this.searchCompany, 
          'description,code,id', this.searchCompany /*? [] : [ ...this.compayIds ]*/); 
      }
      else {
        data = await this.entityService.getAll(1, 1000, this.searchCompany, 
          'description,code,id', this.searchCompany ? [] : [ ...this.compayIds ]);
      }

      this.companies = data.data.data.data
    },

    async getPrincipalCompany(){
      if(this.compayIds.length > 0){
        let data = await this.entityService.getAll(1, 1000, '', 'description,code', [ ...this.compayIds ]);
        this.principalCompay = data.data.data.data
      }

    },

    async findGroupById(id){
      let data = await this.$acessGroup.permissionGroupById(id);
      console.log('data ', data.data.data)
      let group = data.data.data
      this.select = { id: group.id, name: group.groupName }
      this.group.push({
        name: group.groupName,
        id: group.id,
      });
    },

    async findGroups() {
      let data = await this.$acessGroup.findAllGroup(this.search, 1, 3);
      this.groups = data.data.data.data;
      if (this.groups) {
        for (var i = 0; i < this.groups.length; i++) {
          this.group.push({
            name: this.groups[i].groupName,
            id: this.groups[i].id,
          });
        }
      }
    },

  },

  mounted() {
  },
};
</script>

<style scoped>
.v-toolbar {
  flex: none;
}
</style>
