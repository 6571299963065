import Vue from "vue";
import authService from "./authService";

export default {

    async updateTokenAzure(data){
        const req = await Vue.prototype.$http.put("User/UpdateTokenAzure", data)
        return req;
    },

    async tokenAzure(data){
        const res = await Vue.prototype.$http.post("User/TokenAzure", data)
        console.log('authAzure', res)
        if (res.data.success) {
            await authService.saveUserParams(res.data.data);
            let permissions = await authService.getPermissionsByUser(res.data.data.userId);
            
            permissions = permissions?.filter(item => item != null) || []
            res.data.data.modules = permissions;
            await authService.saveUserParams(res.data.data);
        }

        return res;
    },
}