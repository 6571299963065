<template>
  <div>
    <div
      class="row"
      v-if="tabGeneralComp.properties && tabGeneralComp.properties.length > 0"
    >
      <div
        v-show="schema.form.readyall ? !schema.form.readyall.readonly : true"
        v-for="(property, index) in tabGeneralComp.properties.filter((p) => !p.form.tab)"
        :key="index"
        :class="property.form.col || col"
      >
        <entity-input-auto-form
          v-if="
            visible.length > 0
              ? visible.indexOf(property.name) == -1
              : conditionBool.indexOf(property.name) == -1
          "
          v-show="
            property.form.visible == true ||
            !property.form.hasOwnProperty('visible')
          "
          :visible="
            property.form.visible || !property.form.hasOwnProperty('visible')
          "
          :readallPerField="
            schema.form.readallPerField
              ? schema.form.readallPerField
              : undefined
          "
          :readyall="
            schema.form.readyall
              ? schema.form.readyall.readonly || true
              : undefined
          "
          :readonly="
            readonlyAction != {} && readonlyAction.campo == property.name
              ? readonlyAction.valor
              : property.form.readonly
          "
          :relationCondition="
            property.schema ? property.schema.relationCondition : undefined
          "
          :readonlyCondition="property.form.readonlyCondition"
          :visibleConditionRelationSchema="
            property.form.visibleConditionRelationSchema
          "
          v-model="entity"
          :schemaName="schema.title"
          :property="property.name"
          :properties="property"
          :color="property.schema ? property.schema.color : undefined"
          :type="property.schema ? property.schema.type : undefined"
          :mask="property.schema ? property.schema.mask : undefined"
          :real="property.schema ? property.schema.real : undefined"
          :default="property.schema ? property.schema.default : undefined"
          :msg="property.schema ? property.schema.msg : undefined"
          :filterSchema="
            property.schema ? property.schema.filterSchema : undefined
          "
          :valorMaximo="
            property.schema ? property.schema.max : undefined
          "
          :sufixo="property.schema ? property.schema.sufixo : undefined"
          :prefixo="property.schema ? property.schema.prefixo : undefined"
          :objFilter="property.schema ? property.schema.objFilter : undefined"
          :valorMinimo="property.schema ? property.schema.min : undefined"
          :booleanCondition="schema.form.booleanCondition"
          :multiple="property.schema ? property.schema.multiple : undefined"
          :file="property.schema.file ? property.schema.file : ''"
          :campoAuxiliar="property.schema.campAux"
          :isOpen="isOpen"
          :key="arrayCap.indexOf(property.name) == -1 ? 0 : componentKey"
          :owner="property.schema.owner"
          :autoInc="property.schema.autoInc"
          :visibleCondition="schema.form.visibleCondition"
          :descriptor="schema.descriptor"
          :label="property.form.label"
          :relationSchema="
            property.schema ? property.schema.relationSchema : undefined
          "
          :relationField="
            property.schema ? property.schema.relationField : undefined
          "
          :field="property.schema ? property.schema.field : undefined"
          :filter="property.schema ? property.schema.filter : undefined"
          :mirror="property.schema ? property.schema.mirror : undefined"
          :enum-combo="property.schema ? property.schema.enum : undefined"
          :searchQr="property.schema ? property.schema.searchQr : undefined"
          :multiCompany="schema.multi_company"
          @visibleCondition="visibleCondition"
          @readall="readallField"
          @blur="handleAction(property, $event)"
          @readonlyCondition="eventReadonlyCondition"
        ></entity-input-auto-form>
      </div>
    </div>

    <div v-if="tabGeneralComp.groups && tabGeneralComp.groups.length > 0">
      <div
        v-show="schema.form.readyall ? !schema.form.readyall.readonly : true"
        v-for="(group, index) in tabGeneralComp.groups"
        :key="index"
      >
        <div class="mt-5 mb-5">
          <v-subheader>{{ group.name }}</v-subheader>
          <v-divider />
        </div>

        <!-- :readonlyCondition="schema.form.readonlyCondition" --->
        <div
          v-if="group.properties && group.properties.length > 0"
          class="ml-15"
        >
          <div v-for="(property, index) in group.properties" :key="index">
            <entity-input-auto-form
              v-if="
                visible.length > 0
                  ? visible.indexOf(property.name) == -1
                  : conditionBool.indexOf(property.name) == -1
              "
              v-show="
                property.form.visible == true ||
                !property.form.hasOwnProperty('visible')
              "
              :visible="
                property.form.visible ||
                !property.form.hasOwnProperty('visible')
              "
              :readallPerField="
                schema.form.readallPerField
                  ? schema.form.readallPerField
                  : undefined
              "
              :readyall="
                schema.form.readyall ? schema.form.readyall.readonly : undefined
              "
              :readonly="property.form.readonly"
              :relationCondition="
                property.schema ? property.schema.relationCondition : undefined
              "
              :readonlyCondition="schema.form.readonlyCondition"
              v-model="entity"
              :descriptor="schema.descriptor"
              :property="property.name"
              :type="property.schema ? property.schema.type : undefined"
              :mask="property.schema ? property.schema.mask : undefined"
              :real="property.schema ? property.schema.real : undefined"
              :default="property.schema ? property.schema.default : undefined"
              :valorMaximo="
                property.schema ? property.schema.max : undefined
              "
              :valorMinimo="property.schema ? property.schema.min : undefined"
              :sufixo="property.schema ? property.schema.sufixo : undefined"
              :prefixo="property.schema ? property.schema.prefixo : undefined"
              :objFilter="property.schema ? property.schema.objFilter : undefined"
              :booleanCondition="schema.form.booleanCondition"
              :msg="property.schema ? property.schema.msg : undefined"
              :file="property.schema.file ? property.schema.file : undefined"
              :multiple="property.schema ? property.schema.multiple : undefined"
              :key="arrayCap.indexOf(property.name) == -1 ? 0 : componentKey"
              :isOpen="isOpen"
              :visibleCondition="schema.form.visibleCondition"
              :owner="property.schema.owner"
              :color="property.schema ? property.schema.color : undefined"
              :autoInc="property.schema.autoInc"
              :label="property.form.label"
              :relationSchema="property.schema.relationSchema"
              :relationField="
                property.schema ? property.schema.relationField : undefined
              "
              :field="property.schema ? property.schema.field : undefined"
              :filter="property.schema ? property.schema.filter : undefined"
              :mirror="property.schema ? property.schema.mirror : undefined"
              :enum-combo="property.schema ? property.schema.enum : undefined"
              :searchQr="property.schema ? property.schema.searchQr : undefined"
              :multiCompany="schema.multi_company"
              @visibleCondition="visibleCondition"
              @readall="readallField"
              @blur="handleAction(property, $event)"
              @readonlyCondition="eventReadonlyCondition"
            ></entity-input-auto-form>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        (tabsComp && tabsComp.length > 0) ||
        (tabActions && tabActions.length > 0)
      "
      v-show="schema.form.readyall ? !schema.form.readyall.readonly : true"
    >
      <div :key="key">
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel v-for="(tab, index) in tabsComp" :key="index">
            <v-expansion-panel-header class="font-weight-bold">
              {{ tab.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="(property, index) in tab.properties" :key="index">
                <div v-if="property.form.view">
                  <component
                    v-bind:is="property.form.view"
                    :relation="true"
                    :schemaName="property.schema.relationSchema"
                    :ids="entity[property.name] || []"
                    :item="entity"
                    @input-ids="
                      ($event) => {
                        entity[property.name] = $event;
                        setParents(entity)
                        //     $forceUpdate();
                      }
                    "
                  ></component>
                </div>

                <div v-else>
                  <div
                    v-if="
                      property.schema &&
                      property.schema.relationSchema &&
                      property.schema &&
                      property.schema.type == 'array'
                    "
                  >
                    <form-table
                      v-if="
                        property.schema &&
                        property.schema.relationSchema != 'ItemStage'
                      "
                      :schemaName="property.schema.relationSchema"
                      :ids="entity[property.name] || []"
                      :readyall="
                        schema.form.readyall
                          ? schema.form.readyall.readonly || true
                          : undefined
                      "
                      :readonly="property.form.readonly"
                      :item="entity"
                      relation
                      :open="isOpen"
                      @input-ids="
                        ($event) => {
                          entity[property.name] = $event;
                          setParents(entity)
                          saveEntityLocalStorage(entity)
                          $forceUpdate();
                          //   console.log('EVENTY', $event);
                        }
                      "
                      @executeAction="actionCreated"
                    >
                    </form-table>
                  </div>

                  <entity-input-auto-form
                    v-if="
                      visible.length > 0
                        ? visible.indexOf(property.name) == -1
                        : conditionBool.indexOf(property.name) == -1
                    "
                    v-model="entity"
                    :property="property.name"
                    :type="property.schema ? property.schema.type : undefined"
                    :isOpen="isOpen"
                    :mask="property.schema ? property.schema.mask : undefined"
                    :real="property.schema ? property.schema.real : undefined"
                    :valorMaximo="
                      property.schema ? property.schema.max : undefined
                    "
                    :valorMinimo="property.schema ? property.schema.min : undefined"
                    :objFilter="property.schema ? property.schema.objFilter : undefined"
                    :booleanCondition="schema.form.booleanCondition"
                    :default="
                      property.schema ? property.schema.default : undefined
                    "
                    :msg="property.schema ? property.schema.msg : undefined"
                    :color="property.schema ? property.schema.color : undefined"
                    :file="property.schema.file ? property.schema.file : ''"
                    :multiple="
                      property.schema ? property.schema.multiple : undefined
                    "
                    :label="property.form.label"
                    :visibleCondition="schema.form.visibleCondition"
                    :readonlyCondition="schema.form.readonlyCondition"
                    :visibleConditionRelationSchema="
                      property.form.visibleConditionRelationSchema
                    "
                    :readallPerField="
                      schema.form.readallPerField
                        ? schema.form.readallPerField
                        : undefined
                    "
                    :readyall="
                      schema.form.readyall
                        ? schema.form.readyall.readonly || true
                        : undefined
                    "
                    :readonly="
                      readonlyAction != {} &&
                      readonlyAction.campo == property.name
                        ? readonlyAction.valor
                        : property.form.readonly
                    "
                    :sufixo="property.schema ? property.schema.sufixo : undefined"
                    :prefixo="property.schema ? property.schema.prefixo : undefined"
                    :field="property.schema ? property.schema.field : undefined"
                    :filter="
                      property.schema ? property.schema.filter : undefined
                    "
                    :relationSchema="property.schema.relationSchema"
                    :key="
                      arrayCap.indexOf(property.name) == -1 ? 0 : componentKey
                    "
                    :relationCondition="
                      property.schema
                        ? property.schema.relationCondition
                        : undefined
                    "
                    :mirror="
                      property.schema ? property.schema.mirror : undefined
                    "
                    :enum-combo="
                      property.schema ? property.schema.enum : undefined
                    "
                    :searchQr="property.schema ? property.schema.searchQr : undefined"
                    :multiCompany="schema.multi_company"
                    @readall="readallField"
                    @visibleCondition="visibleCondition"
                    @booleanCondition="booleanCondition"
                    @blur="handleAction(property, $event)"
                    @readonlyCondition="eventReadonlyCondition"
                  ></entity-input-auto-form>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-for="action in tabActions" :key="action.name">
            <v-expansion-panel-header>
              {{ action.description }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ActionDialog
                :action="action"
                @close="count++"
                :key="count"
                :entityId="entity.id"
              ></ActionDialog>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>

    <div v-if="schema.grid && entity.id">
       <expansionPanielGrid :grid="schema.grid" :entity="entity" />
    </div>

    <div
      style="
        font-size: 10px;
        font-family: inherit;
        margin-top: 20px;
        width: 100%;
      "
    >
      <label v-if="getDateCreate != 'Invalid Date'"
        ><strong>Criado {{ getDateCreate }} por '{{ createBy }}'</strong></label
      ><br />
      <label v-if="getDateUpdate != 'Invalid Date' && updateBy != null"
        ><strong
          >Atualizado {{ getDateUpdate }} por '{{ updateBy }}'</strong
        ></label
      >
    </div>
  </div>
</template>

<script>
import ActionDialog from "../../components/dialogs/actionDialog.vue";
import EntityService from "../../services/entityService.js";
import entityInputAutoForm from "../../components/entities/entityInputAutoForm.vue";
import expansionPanielGrid from "../../components/expasionPanielGrid/expasionPanielGrid.vue";
import entityFormMixin from "../../mixins/entityFormMixin";
import VRuntimeTemplate from "v-runtime-template";
import * as VuetifyComponents from "vuetify/lib/components";

import FormTable from "../../components/table/FormTable.vue";

import { runAction } from "../../services/actionService";
import emitter from "@/services/emitter";

export default {
  props: [
    "entity",
    "schema",
    "tabActions",
    "propertyActions",
    "isOpen",
    "aprovClick",
    "col",
  ],
  mixins: [entityFormMixin],
  components: {
    expansionPanielGrid,
    VRuntimeTemplate,
    ...VuetifyComponents,
    entityInputAutoForm,
    FormTable,
    entityPage: () => import("../../views/entities/EntityPage.vue"),
    ActionDialog,
  },

  watch: {
    /*    async entity(entity){
      this.createBy = entity.createBy
      this.updateBy = entity.updateBy
      this.createDate = entity.createAt
      this.updateDate = entity.updateAt
      
    },*/

    entity: {
      handler(entity) {
        this.createBy = entity.createBy;
        this.updateBy = entity.updateBy;
        this.createDate = entity.createAt;
        this.updateDate = entity.updateAt;

        this.setParents(entity);

        this.saveEntityLocalStorage(entity);

      },
      deep: true,
      immediate: true,
    },

    async aprovClick(v) {
      if (v > 0) {
        const id = this.$route.query.id;
        const req = await this.$notificationService.verifyNotification(id);
        if (req.data.data !== null) {
          this.aprove = req?.data?.data?.aprov;
          if (this.aprove == null) {
            this.schema.form.readyall = true;
          }
        } else {
          this.schema.form.readyall = false;
        }

        this.$forceUpdate();
      }
    },
  },

  isOpen(v) {
    if (v == true) {
      this.actionCreated();
      this.key += 1;
    } else {
      localStorage.removeItem("parents");
    }
  },

  async mounted() {
    await emitter.on('eventAction', async (p) => {
      this.handleAction(this.propertiesComp.filter(resp => resp.name == p)[0])
    });
    this.tabGeneralCompAux = this.propertiesComp
    this.actionCreated();

  },

  async created() {
    const id = this.$route.query.id || this.entity.id;

    if (
      Object.values(this.schema.properties).filter(
        (resp) => resp.statusF === true
      ).length > 0
    ) {
      await this.aprovNotifications(id);
    }

    if (this.entity.id) {
      this.panel = this.tabsComp.map((k, i) => {
        return i;
      });
    }
  },

  computed: {
    getDateCreate() {
      const date = new Date(this.createDate);
      return date.toLocaleDateString("pt-BR");
    },

    getDateUpdate() {
      const date = new Date(this.updateDate);
      return date.toLocaleDateString("pt-BR");
    },
  },

  data() {
    return {
      aprove: null,
      panel: [],
      eventAction: "",
      visible: [],
      count: 0,
      componentKey: 0,
      entityService: new EntityService(this.schema.title),
      createDate: "",
      updateDate: "",
      createBy: "",
      updateBy: "",
      readonlyAction: {},
      arrayCap: [],
      countAction: 0,
      conditionBool: [],
      key: 0,
      tabGeneralCompAux: []
    };
  },
  /*
  beforeUpdate(){
  this.$forceUpdate()
  },*/

  methods: {

    saveEntityLocalStorage(entity){

    console.log("SAVE ENTITY >>> ", this.$route.path)

        if(this.$route.path !== "/weeklySchedule"){
        console.log("AQUI >>>>>")
      if(entity.id){
        localStorage.setItem(`${this.schema.title}-${entity.id}`, JSON.stringify(entity));
      } else {
        localStorage.setItem(`${this.schema.title}`, JSON.stringify(entity));
      }
                }
    },

    setParents(entity){
      if (this.$route.params.schemaName == this.schema.title) {
        localStorage.setItem(
            "parents",
            JSON.stringify(Object.assign(entity))
          );
        }
    },

    eventReadonlyCondition(readonlyCondition, value, property, id) {

      if(id !== undefined && readonlyCondition.value) {
        if(readonlyCondition.value.indexOf(value) !== -1){
          this.tabGeneralComp.properties.map((item) => {
            if(item.name == property){
              item.form.readonly = true;
            }
          });
        }else{
          this.tabGeneralComp.properties.map((item) => {
            if(item.name == property) {
              item.form.readonly = false;
            }
          }); 
        }
      }else{
          this.tabGeneralComp.properties.map((item) => {
            if(item.name == property){
              item.form.readonly = false;
            }
          });
      }

      this.$forceUpdate();
    },

    readallField(value) {
      if(this.aprov !== undefined){
        this.schema.form.readyall = value;
        this.$forceUpdate()
      }
    },

    async aprovNotifications(id) {
      if (this.entity.id) {
        const req = await this.$notificationService.verifyNotification(id);
        if (req.data.data !== null) {
          this.aprove = req?.data?.data?.aprov;
          if (this.aprove == null) {
            this.schema.form.readyall = true;
          }
        } else {
          this.schema.form.readyall = false;
        }
      }
    },

    visibleCondition(e) {
      this.visible = [];
      for (var i = 0; i < e?.length; i++) {
        this.visible.push(e[i]);
      }
    },
    booleanCondition(campo, property, value, count) {
      if (count == 1 || value == false) {
        for (let i = 0; i < campo[property]?.length; i++) {
          this.conditionBool.push(campo[property][i]);
        }
      } else if (value == true) {
        for (let i = 0; i < campo[property]?.length; i++) {
          this.conditionBool = this.conditionBool.filter(
            (resp) => resp !== campo[property][i]
          );
        }
      }
    },
    async handleAction(property) {
      let childrens = {};
      for (const action of this.propertyActions) {
        if (action?.callConfig?.triggerProperty?.split(',').indexOf(property.name) == -1) continue;

        if (this.entity) {
          let entityData = Object.assign(this.entity);
          let properties = Object.assign(this.schema.properties);
          let childrensObj = Object.entries(properties).filter(
            (item) => item[1].relationSchema && (item[1].type == "string" || item[1].type == 'array')
          );

          for (let i = 0; i < childrensObj.length; i++) {
            if (entityData[childrensObj[i][0]]) {
              let entityService = new EntityService(
                childrensObj[i][1].relationSchema
              );
              let entity = await entityService.getById(
                entityData[childrensObj[i][0]]
              );

              try {
                childrens[childrensObj[i][1].relationSchema] = Object.assign(
                  entity.data.data
                );
              } catch (err) {
                //
                console.log("Error ", err);
              }
            }
          }
        }

        runAction(
          { id: action.id },
          {
            _currentData: this.entity,
            childrens: childrens,
            parents: JSON.parse(localStorage.getItem("parents")),
          },
          this.entity?.id
        ).then(async (result) => {
          try {
            this.arrayCap = Object.keys(result?.data);
          } catch (err) {
            //
          }

          if (result?.data?.readonly) {
            this.readonlyAction = result?.data?.readonly;
          }
          /*
            if(result?.data?.userLogado){
              let userLogado = await this.$auth.getUserParams()
              let f = result?.data.collaborador?.filter(resp => resp?.user === userLogado.user)[0]
              console.log('data ', result?.data)
                for (const key in result?.data) {
                  if(result.data[key].collaboratorId === ''){
                    result.data[key].collaboratorId = f?.id
                    this.entity[key] = result.data[key]

                    this.$forceUpdate()
                    console.log('entity ', this.entity[key])
                    this.componentKey += 1
                  }
                }
            }*/

          if (result?.executeOneTime == true && this.countAction == 0) {
            this.countAction = 1;
            for (const key in result?.data) {
              this.entity[key] = await result?.data[key];
              this.componentKey += 1;
            }
          } else if (!result?.executeOneTime) {
            if (result?.boolean == false)
              this.$notfy.error(`${result.message}`, "", 5000);
            for (const key in result?.data) {
              this.entity[key] = await result?.data[key];
              this.componentKey += 1;
            }
          }
        }).catch(() => {
          this.$notfy.error("Por favor, refaça o procedimento", 5000);
        });
      }
    },

    actionCreated() {
      //if(this.entity.id){
      this.tabGeneralComp.properties.forEach((element) => {
        if (element.schema?.actionExecute == true) {
          this.handleAction(element);
        }
      });
      //}
    },
  },
};
</script>

<style scoped>
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  padding: 0px 12px !important;
}
</style>
