var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-10"},[_c('div',{staticClass:"text-h4"},[_vm._v("Grupos")]),_c('div',{staticClass:"pa-5 pb-0"},[_c('AcessControlDialog',{on:{"close":_vm.onClose}}),_c('v-btn',{staticStyle:{"left":"-20px","bottom":"22px"},attrs:{"color":"primary","icon":"","dark":""},on:{"click":function($event){return _vm.$router.push('/userPage')}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchItems.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"pt-5 pb-5",attrs:{"chips":"","clearable":"","hide-details":"","hide-selected":"","prepend-icon":"mdi-magnify","label":"Search","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.groups,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","color":"black"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-3"},[_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openUpdate(item.groupName, item.id)}}},[_c('v-list-item-title',[_c('span',[_vm._v("Atualizar Grupo")])])],1),_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.deleteItem(item.groupName, item.id)}}},[_c('v-list-item-title',[_c('span',[_vm._v("Deletar Grupo")])])],1),_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openPermissionsDialog(item.id, item.groupName)}}},[_c('v-list-item-title',[_c('span',[_vm._v("Permissões do Grupo")]),_c('v-icon',{staticClass:"icon",attrs:{"small":""}},[_vm._v(" mdi-account-griup")])],1)],1)],1)],1)]}},{key:"item.viewGroup",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"color":"primary","size":"large"},on:{"click":function($event){return _vm.$router.push(("/users/" + (item.groupName) + "/" + (item.id)))}}},[_vm._v(" mdi-account - "+_vm._s(item)+" ")])]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},on:{"input":function($event){return _vm.findGroup()}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('ConfirmDeleteDialog',{attrs:{"value":_vm.dialogDelete,"groupName":_vm.groupName,"idGroup":_vm.idGroup,"onClose":_vm.onClose},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}}),_c('AcessControlUpdate',{attrs:{"groupName":_vm.groupName,"id":_vm.idGroup,"open":_vm.updateGrupDialog},on:{"close":_vm.onClose}}),_c('permissions-dialog',{attrs:{"value":_vm.permissionsDialog,"group_id":_vm.idGroup,"group_name":_vm.groupName},on:{"cancel":function($event){_vm.permissionsDialog = false},"save":function($event){_vm.permissionsDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }