import Vue from "vue";
import moment from "moment"
import crypto from "crypto";

export default {

    async getUserToken() {
        let params = await Vue.prototype.$getItem("simjob_params");
        if (params) return params.accessToken;
        else return null;
    },


    async getUsersTenanty(tenanty){
        let req = await Vue.prototype.$http.get(`user/getUsers/tenanty/`, { params: { value: tenanty, page: 1, limit: 200}});
        return req;
    },

    async getHash(){
        const params = await Vue.prototype.$getItem("simjob_params")
        const hash = crypto.createHash("md5").update(params.accessToken).digest("hex")
        return hash;
    },

    async claimExist(access, claims) {

        let isAdmin = await this.isAdmin();
        if (isAdmin) return true;
        if (!claims) claims = await this.getUserClaims();

        if (Array.isArray(access)) {
            let allow = false;
            access.forEach(a => {
                if (claims.filter(c => c.type == a.type && c.value == a.value).length > 0)
                    allow = true;
            });

            return allow;
        }
        return claims.filter(c => c.type == access.type && c.value == access.value).length > 0
    },

    async getModules() {
        let res = await Vue.prototype.$http.get("user/modules/");
        return res.data.data;
    },

    async getPermissionsByUser(userId){
        let res = await Vue.prototype.$http.get("user/modules/permissions", {params: {userId}});
        return res.data.data;
    },

    async isAdmin(claims) {
        if (!claims) claims = await this.getUserClaims();
        return claims.filter(c => c.type == "Admin").length > 0;
    },
    async getUserClaims() {
        let params = await Vue.prototype.$getItem("simjob_params");

        if (params) return params.claims;
        else return []
    },

    async tokenAllowed() {
        let userParams = await this.$auth.getUserParams();
        if (!userParams) return false;
        let expiration = userParams.expiration;
        if (!expiration) return false;
        let expirationDate = moment(expiration).toDate();
        let now = Date.now();
        return expirationDate > now;

    },
    async isAuthenticatedAPI() {
        let token = await this.getUserToken();
        if (!token) return false;
        let res = await Vue.prototype.$http.get("user/IsAuthenticated");
        if (!res.success) return false;
        return res.data;
    },
    async removeUserParams() {
        await Vue.prototype.$removeItem("simjob_params");
    },
    async getUserParams() {
        return await Vue.prototype.$getItem("simjob_params");
    },


    async saveUserParams(params) {
        await Vue.prototype.$setItem("simjob_params", params);
    },

    async createUser(tenanty, email, name, telefone, groupId, password, confirmPassword, companySiteIds, principalCompany){
        let newUser = {
            tenanty: tenanty,
            email: email,
            name: name,
            telefone: telefone,
            grupoId: groupId,
            password: password,
            confirmPassword: confirmPassword,
            companySiteIds: companySiteIds,
            companySiteIdDefault: principalCompany
        }
        let res = await Vue.prototype.$http.post("user/register", newUser);

        return res;
    },

    async deleteUserById(userId, tenanty, name, telefone, email, groupId){
        let user = {
            userId: userId,
            tenanty: tenanty,
            name: name,
            telefone: telefone,
            email: email,
            groupId: groupId
        }

        let res = await Vue.prototype.$http.put("user/deleteUserById", user);
        return res;
    },

    async login(tenanty,email, password) {
        let model = { tenanty,email, password};
        let res = await Vue.prototype.$http.post("user/token", model);

        if (res.data.success) {
            await this.saveUserParams(res.data.data);
          //  let modules = await this.getModules();
            let permissions = await this.getPermissionsByUser(res.data.data.userId);
            //console.log('permissions', permissions)
            
            permissions = permissions?.filter(item => item != null) || []
            res.data.data.modules = permissions;
          //  res.data.data.modules = modules;
            await this.saveUserParams(res.data.data);
        }
        return res;
    },

    async logout() {
        let res = await Vue.prototype.$http.post("user/logout");
        if (res.success)
            await this.removeUserParams();
        return res;

    },

    async updateUser(newUser){
        let res = await Vue.prototype.$http.put(`user/update`,  newUser )
        return res;
    },

    async updatePassword(userId, password, newPassword){
        let res = await Vue.prototype.$http.put(`user/updateSenha?userId=${userId}&oldPassword=${password}&newPassword=${newPassword}`)
        return res;
    },

    async updatePasswordAdmin(userId, newpassword){
        let res = await Vue.prototype.$http.put(`user/updateSenhaAdmin?userId=${userId}&newPassword=${newpassword}`)
        //let res = await Vue.prototype.$http.put(`user/updateSenhaAdmin`, {params: {userId, newpassword}})
        return res;
    },

    async findById(id){
        let res = await Vue.prototype.$http.get(`user/getUserById?userId=${id}`);
        return res;
    },

    async confirmAuthService(codigo, token){
        const params = await Vue.prototype.$getItem("simjob_params")

        let data = { 
            userId: params.userId,
            tenanty: params.tenanty,
            email: params.username,
            publicIP: await this.getIpClient(),
            hash: token,
            code: codigo,
        }


        var req = await Vue.prototype.$http.post("/TwoFactorAuth/validation2fa", data);
        return req;
    },

    async TwoFactorAuth2fa(token){
        const params = await Vue.prototype.$getItem("simjob_params")
        let data = { 
            userId: params.userId,
            tenanty: params.tenanty,
            email: params.username,
            publicIP: await this.getIpClient(),
            hash: token,
        }

        var req = await Vue.prototype.$http.post("/TwoFactorAuth/2fa", data);
        return req;
    },

    async enableDisable2fa(userId, a2f){
        let data = {
            userId: userId,
            a2f: a2f
        }

        var req = await Vue.prototype.$http.post("/TwoFactorAuth/enable_disable_2fa", data);
        return req;
    },

    async getIpClient() {
        try {
            const reqIP = await fetch('https://api.db-ip.com/v2/free/self')
            const ip = await reqIP.json();
            return ip.ipAddress
            
        } catch(err){
            //
        }
    },

    async externalToken(obj){
        var req = await Vue.prototype.$http.post(`/User/externalToken?userId=${obj.userId}`);
        return req;
    },

    async getExternalToken(id){
        var req = await Vue.prototype.$http.get(`/User/getExternalToken?userId=${id}`);
        return req;
    },

    async generatedApiKey(token){
        let req = await Vue.prototype.$http.post('/User/api-key', {
            params: {
                token: token
            }
        });

        return req;
    },

    async sendTokenForEmail(obj){
        let req = await Vue.prototype.$http.post(`/User/sendtoken?tenanty=${obj.tenanty}&email=${obj.email}`)
        return req;
    },

    async updatePasswordByToken(obj){
        let req = await Vue.prototype.$http.put('/User/updatePasswordByToken', {}, {
            params: {
                token: obj.token,
                tenanty: obj.tenanty,
                email: obj.email,
                newPassword: obj.newPassword
            }
        })

        return req
    }
}