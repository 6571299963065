<template>

    <v-container>
      
      <v-row>
        
        <div
            v-for="(dashboard, index) in dashboards"
            :key="index" 
            style="display: flex;
            align-items: center;
            margin: 15px; width: 200px;">
            
            <input
                style="margin-right: 20px; cursor: pointer;"
                type="checkbox"
                :ripple="false"
                hide-details
                color="blue"
                @input="addPermission(dashboard, index)"
                :checked="getChecked(dashboard)"
            />

                <span>{{ dashboard.name }}</span>

        </div>


        </v-row>

    </v-container>
</template>


<script>
import EntityService from '../../services/entityService';
import getUsersMixin from '@/mixins/getUsersMixin'
export default {
    mixins: [getUsersMixin],
    props: ['userId', 'groupId'],
    data(){
        return {
            entityService: new EntityService('Dashboard'),
            dashboards: [],
            dashboardSelected: [],
            usersPermissions: [],
            userList: [],
            usersGroup: [],
            usersIds: null
        }
    },

    watch: {
    },

    async created(){
        this.getDashboards()

        if(this.groupId){
            this.usersGroup = await this.getUsers('', this.groupId, 1);
            this.usersIds = this.usersGroup.map(user => {
                return user.id;
             }).join(',')
        }
    },

    methods: {
        async getDashboards(){
            let data = await this.entityService.getAll(1, 300)
            this.dashboards = data.data.data.data;
        },

        getChecked(dashboard){

            if(this.userId){
                let usersPermissions = dashboard?.usersPermissions?.split(',');
                if(usersPermissions?.length > 0){
                    if(usersPermissions?.indexOf(this.userId) !== -1){
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else if(this.groupId){

                let groupPermissions = dashboard?.groupPermissions?.split(',');
                if(groupPermissions?.length > 0){
                    if(groupPermissions?.indexOf(this.groupId) !== -1){
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }

            }
        },

        addPermission(dashboard, index){

            if(this.userId){

                let usersPermissions = dashboard?.usersPermissions?.split(',');

                if(usersPermissions?.length > 0){
                    if(usersPermissions?.indexOf(this.userId) == -1){
                        usersPermissions.push(this.userId)
                    } else {
                        usersPermissions = usersPermissions.filter(resp => resp !== this.userId)
                    }
                } else {
                    usersPermissions = []
                    usersPermissions?.push(this.userId);
                }

                this.dashboards[index].usersPermissions = usersPermissions.map(resp => {
                    return resp;
                }).join(',');

            } else if(this.groupId){

                let groupPermissions = dashboard?.groupPermissions?.split(',')
                let usersPermissions = dashboard?.usersPermissions?.split(',');

                if(groupPermissions?.length > 0){
                    if(groupPermissions?.indexOf(this.groupId) == -1){
                        groupPermissions.push(this.groupId)
                        
                        if(usersPermissions == undefined || usersPermissions.length == []){
                            this.dashboards[index].usersPermissions = this.usersIds
                        } else if(usersPermissions.length > 0) {
                            this.dashboards[index].usersPermissions += `,${this.usersIds}`
                        }

                    } else {

                        groupPermissions = groupPermissions.filter(resp => resp !== this.groupId)

                        let usersPermissions = dashboard?.usersPermissions?.split(',');
                        this.usersGroup.map(user => {
                            usersPermissions = usersPermissions.filter(resp => resp !== user.id)
                            this.dashboards[index].usersPermissions = usersPermissions.map(resp => {
                                return resp;
                            }).join(',')
                        });

                    }
                } else {
                    groupPermissions = []
                    groupPermissions?.push(this.groupId);
                }


                this.dashboards[index].groupPermissions = groupPermissions.map(resp => {
                    return resp;
                }).join(',');


            }

            this.$emit('permissionDashboard', this.dashboards[index])

        }

    }
}
</script>

<style scoped>
.v-toolbar {
  flex: none;
}

.v-card > *:last-child:not(.v-btn):not(.v-chip) {
    border-bottom-left-radius: inherit;
    padding: 12px;
    border-bottom-right-radius: inherit;
    background-color: white;
    overflow-x: hidden;
}
</style>