import Vue from "vue";

export default {

    async findAllGroup(name, page, limit){
        if(name == ""){
            const req = await Vue.prototype.$http.get(`/GroupPermission/getAll/groups?page=${page}&limit=${limit}`);
            return req;
        } else {
            const reqName = await Vue.prototype.$http.get(`/GroupPermission/getAll/groups?name=${name}&page=${page}&limit=${limit}`);
            return reqName;
        }
    },

    async permissionGroupById(id){
        let req = await Vue.prototype.$http.get('/GroupPermission/get/groupPermissionsById', { params: {id: id}})
        return req
    },

    async addGroup(groupName){

        let newGroup = { groupName: groupName }
        var req = await Vue.prototype.$http.post("/AccessGroup/insertGroup/", newGroup);

        if(req.errors){
            return console.log("Error")
        }

        return req;
    },

    async updateGroup(groupName, id){

        let updateGroup = { groupName: groupName}
        var req = await Vue.prototype.$http.put(`/AccessGroup/updateGroup?id=${id}`, updateGroup);

        if(req.errors){
            return console.log("Error")
        }

        return req;
    },

    async removeGroup(groupName, id){

        var req = await Vue.prototype.$http.delete(`/AccessGroup/deleteGroupById/${id}`);

        if(req.success == false){
            return console.log("Error")
        }

        return req;

    },

    async searchByUserId(userId){
        var req = await Vue.prototype.$http.get(`/AccessGroup/searchByUserId/${userId}`);

        return req;
    },

    async findAllPermissioon(){
        const req = await Vue.prototype.$http.get("/AccessGroup/getAll/permission")
        return req.data;
    },
    
    async findPermissionsByUserId(user_id) {
        const response = await Vue.prototype.$http.get(`/AccessGroup/searchByUserId/${user_id}`);
        return response.data;
    },

    async getUsersByTenanty(page, limit, name, groupId){
        const response = await Vue.prototype.$http.get(`/User/getUsersByTenanty`, { params: {
            name: name || '',
            groupId: groupId || '',
            page: page || 1,
            limit: limit || 30
        }});
        return response.data;
    },
    
    async findAllPermissiosByGroupId(name, group_id, page, limit) {
        
        if(name == ""){
            const response = await Vue.prototype.$http.get(`/AccessGroup/searchByGroup/${group_id}?page=${page}&limit=${limit}`);
            return response.data;
        }else {
            const responseWhitName = await Vue.prototype.$http.get(`/AccessGroup/searchByGroup/${group_id}?nameUser=${name}&page=${page}&limit=${limit}`);
            return responseWhitName.data;
        } 

  },

  async insertPermissions(permissions) {
    const response = await Vue.prototype.$http.post(
      `/AccessGroup/insertPermission`,
      permissions
    );
    return response.data;
  },

  async insetGroupPermission(permissions, id) {
    const response = await Vue.prototype.$http.put(
        `/GroupPermission/updateGroupPermission?id=${id}`,
        permissions
      );
      
      return response.data;
  },

  async updatePermission(permissions) {
      const resp = await Vue.prototype.$http.put("/AccessGroup/update/permission", permissions);

      return resp.data;
      
    },

    async updatePermissionUser(user){
        const resp = await Vue.prototype.$http.put("/AccessGroup/update/updateUser", user)
        return resp.data;
    },

    async deletePermission(data){
        const req = await Vue.prototype.$http.get("/AccessGroup/deletePermission/", data)
        return req;
    },

    async copyPermissions(idUser, idUserCopy){
        const req = await Vue.prototype.$http.post(`AccessGroup/CopyPermission?idUser=${idUser}&idUserCopy=${idUserCopy}`)
        return req;
    },

    async getActions(schemaName){
        const req = await Vue.prototype.$http.get(`Action/search-fields?value=${schemaName}&searchFields=callConfig.schemaName&page=1&limit=30`);
        return req;
    },

  async insertActions(actions) {
    const response = await Vue.prototype.$http.put(
      `/AccessGroup/update/permission`,
      actions
    );
    return response;
  },
}