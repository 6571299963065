<template>
  <div class="text-center" style="margin: 20px;">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      max-height="300"
      max-width="450"
      @scroll="handler"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
            :content="messages"
            :value="messages"
            color="green"
            overlap
        >
        <i class="bi bi-bell-fill style-i"
          v-bind="attrs"
          v-on="on"
        >
        </i>
        </v-badge>
      </template>

      <v-card style="overflow-x: scroll;">

        <v-list-item>
        <v-list-item-content>
            <v-list-item-title style="font-size: 18px; font-weight: bold;">Notificações</v-list-item-title>
        </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list>
            <v-list-item three-line class="card-item" v-for="(notification, index) in 
                notifications" :key="index">
                <v-list-item-content class="item-content">
                    <div @click="eventClick(notification.schemaRecordId, notification.id, notification.schemaName)">
                    <v-list-item-title style="margin-bottom: 8px;">
                        <i class="bi bi-info-circle"></i>
                        <a style="color: black;">{{ notification.description || 'Notificação' }}</a>
                        <span style="margin: 5px;">{{ new Date(notification.createAt).toLocaleDateString() }}</span>
                    </v-list-item-title>
                    <div class="row" style="margin: -12px -3px;">
                        <div class="col-11">
                            <v-list-item-subtitle style="font-weight: bold;">{{ notification.msg }}</v-list-item-subtitle>
                            <v-list-item-subtitle style="font-size: 12px">{{ notification.obs || notification.value }}</v-list-item-subtitle>
                        </div>
                        <div class="col-1" style="align-self: end;" >
                            <i :class="notification.view  == false ? 'bi bi-dot' : 'bi bi-check-all'" 
                            :style="notification.view == false ? 'color: #1976d2; font-size: 35px; margin-left: -10px;' 
                             : 'color: #1976d2; font-size: 20px;'"></i>
                        </div>
                    </div>
                </div>

                <div>
                    <!--
                        v-if="notification.aprovEmail.filter(resp => resp.email == userName)[0].aprov == null || notification.aprov == null"
                    <div v-if="!showObs">
                        <button @click="aprovarOrReprovar(notification.schemaRecordId, notification.id, true)" class="btn-aprovar">
                            Aprovar <i class="bi bi-check-circle"></i>
                        </button>
                        
                        <button class="btn-reprovar" @click="showObs = true, indexAprov = index">
                            Reprovar <i class="bi bi-x-circle"></i></button>
                    </div> ---->
                </div>

                    <div class="obs-container" v-if="showObs == true && indexAprov == index">
                        <v-textarea height="45" label="Informe o motivo da reprova" outlined v-model="motivo" />
                        <div>
                            <button @click="aprovarOrReprovar(notification.schemaRecordId, notification.id, false, motivo)" class="btn-enviar-obs" style="margin-left: 15px;">
                                Enviar <i class="bi bi-check-circle"></i>
                            </button>
                            <button @click="showObs = false" class="btn-cancelar-obs" style="margin-left: 200px;">
                                Cancelar <i class="bi bi-check-circle"></i>
                            </button>
                        </div>
                    </div>

                    <span class="span-enviado">{{ notification.email }}</span>
                    
                </v-list-item-content>
            </v-list-item>


        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>


<script>
import moment from 'moment';
import schemaService from "../../services/schemaService";
//import io from 'socket.io-client'
//import { notify } from "../../services/nativeNotificationService";
export default {
    name: 'menuStatus',
    data: () => ({
        componentKey: 0,
        moment: moment,
        menu: false,
        messages: 0,
        statusFlow: [],
        objIcon: {
            icon: 'bi bi-dot',
            styleIcon: 'color: #1976d2; font-size: 35px; margin-left: -10px;',
        },
        req: null,
        notifications: [],
        view: null,
        aprov: null,
        page: 1,
        count: 0,
        p: null,

        showObs: false,
        motivo: null,
        userName: null,
        indexAprov: null

        //socket: io('http://localhost:3001')
    }),

    methods: {

        handler (el) {

            if ((el.target.scrollHeight-300) <= el.target.scrollTop) {
                this.page += 1
            }
        },


        async eventClick(schemaRecordId, id, schemaName){
            
            await this.viewNotification(id)
            if(schemaRecordId !== null){
                this.$router.push(`/entity/${schemaName}?id=${schemaRecordId}`, () => {})
                this.menu = false
            }
        },

        async aprovarOrReprovar(recordId, idNotification, aprove, motivo){
            let obj = {
                notificationId: idNotification,
                schemaRecordId: recordId,
                aprov: aprove,
                obs: motivo
            }

            await this.$notificationService.notificationUpdate(obj)

            await this.viewNotification(idNotification)
/*
            this.socket.emit('sendNotification', {
                notification: req.data.data
            })*/
            if(aprove == false){
                this.showObs = true
            }
            
            this.menu = false
            //await this.getNotification()
        },

        async viewNotification(notificationId){
            await this.$notificationService.viewUpdate(notificationId)
        },

        async getNotification(){
            let req = await this.$notificationService.getNotification(this.page)
            const user = await this.$auth.getUserParams()
            this.userName = user.username

            if(req?.data?.data != null){
                this.notifications = this.notifications.concat(req.data.data.data)

                this.messages = 0

                this.notifications.map(async (resp) => {

                    resp.description = await this.getSchema(resp.schemaName)

                    if(resp?.aprovEmail?.length > 0){
                        
                        resp?.aprovEmail?.forEach((el) => {
                            if(el?.email == user.username){
                                if(el?.view == false){
                                    this.messages++
                                }
                            }
                        })
                    } else {
                        resp.aprovEmail = []
                        if(resp?.view == false) this.messages++
                    }
                })
            }

            console.log("notifications >>> ", this.notifications)
        },
        
        async getSchema(schemaName){
            let res = await schemaService.getByNameToJS(schemaName);
            let schemaDescription = res.description
            return schemaDescription
        }
    },

    computed: {
    },

    watch: {

        async page(){
            await this.getNotification()
        }
    },

    async created(){
        await this.getNotification()
/*
        this.socket.on('receiveNotification', async (notification) => {
            console.log('notification ', notification)
            await this.getNotification()
            let notification = this.notifications.filter(resp => resp.id === )
            if(lastNotification){
                console.log(lastNotification)
                notify(lastNotification)

                this.$forceUpdate()
            }
        })*/
    },
}
</script>


<style scoped>
.style-i {
    color: white;
    font-size: 22px;
}
/*
.style-i:hover {
    color: white;
}*/

.item-content {
    border-bottom: 1px solid rgba(172, 168, 168, 0.719);
}

a {
    color: black;
    margin-left: 5px;
    color: black;
}


span {
    float: right;
    font-size: 10px;
}
.card-item{
    position: relative;
}

.card-item:hover {
    cursor: pointer;
    background-color: whitesmoke;
}

.btn-aprovar, .btn-enviar-obs {
    background-color: green;
    border-radius: 15px;
    padding: 6px;
    color: white;
    font-size: 12px;
    margin: 0px 8px;
}

.btn-aprovar:hover, .btn-enviar-obs:hover {
    background-color: white;
    border: 1px solid green;
    color: green;
}

.btn-reprovar, .btn-cancelar-obs {
    background-color: red;
    border-radius: 15px;
    padding: 6px;
    color: white;
    font-size: 12px;
    margin: 0px 8px;
}

.btn-reprovar:hover, .btn-cancelar-obs:hover {
    background-color: white;
    border: 1px solid red;
    color: red;
}

.obs-container {
    margin-top: 3px;
    border: 1px solid #c5c0c0;
    padding: 5px;
}

.span-enviado {
    position: absolute;
    right: 15px;
    bottom: 2px;
}

</style>