<template>
  <div>
    <v-btn @click="newItem" class="mb-2" color="primary" icon dark>
      <v-icon>mdi-plus</v-icon></v-btn
    >

    <v-list>
      <v-list-group
        v-for="(item, i) in items"
        :key="i"
        no-action
        sub-group
        :value="true"
        disabled
        @click.native="rowClick(item)"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ item.description }}</v-list-item-title>
        </template>

        <template v-slot:appendIcon>
          <v-btn icon color="primary" @click="newItemStageItem(i)">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
          <v-btn icon color="warning" @click="editItem(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="deleteItem(item)">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </template>

        <item-stage-item-tree-edit
          :ref="'itemStageItemTreeEditRef'"
          class="ml-10"
          schemaName="ItemStageItem"
          :relation="true"
          :ids="item.itemStageItemIds"
          :reference="i"
          @input-ids="inputIds"
        >
        </item-stage-item-tree-edit>
      </v-list-group>
    </v-list>

    <entity-form-dialog
      v-if="schema"
      v-model="formDialog"
      :schema="schema"
      :form="schema.form"
      :entity="itemSelected"
      :loading="loading"
      @confirm="saveItem"
      @delete="deleteItem"
      @cancel="cancelFormDialog"
      @dialog="($event) => (formDialog = $event)"
    ></entity-form-dialog>
  </div>
</template>

<script>
import EntityService from "../../services/entityService.js";
import entityPageMixin from "../../mixins/entityPageMixin";
import itemStageItemTreeEdit from "./itemStageItemTreeEdit.vue";
export default {
  props: ["schemaName", "ids", "relation","item"],
  mixins: [entityPageMixin],
  components: {
    entityFormDialog: () => import("../dialogs/entityFormDialog.vue"),
    itemStageItemTreeEdit,
  },
  data() {
    return {
      entityService: new EntityService(this.schemaName),
      tree: [],
    };
  },
  watch: {
    async ids() {
      await this.getItems();
    },
  },
  methods: {
    async inputIds(event) {
      let itemStageId = this.itemSelected.id
      let item = this.items.find((i) => i.id == itemStageId);
      item.itemStageItemIds = event.ids;
      await this.saveItem(item);
    },
    async rowClick(row) {
      if (this.itemSelected.id == row.id) return;
      this.itemSelected = Object.assign({}, row);
      await this.openRowMenu();
    },
    newItemStageItem(itemStageIndex) {
      this.$refs.itemStageItemTreeEditRef[itemStageIndex].newItem();
    },

    newItemStage(item) {
      this.itemSelected = Object.assign({}, item);
      this.newItem();
    },

    async getItems() {
      if (this.relation != null && (!this.ids || this.ids.length == 0)) return;

      let res = await this.entityService.getAll(
        null,
        null,
        null,
        null,
        this.ids
      );
      if (res.data.success) {
        this.pagination.pages = res.data.data.pages;
        this.items = res.data.data.data;
        await this.$forceUpdate();
      }
    },
  },
};
</script>

<style>
</style>