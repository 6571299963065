<template>
    <div>
        <canvas ref="myChart" id="myChart" width="400" height="200"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js'
export default {
    name: 'ActionCharJs',
    props: {
        result: Object
    },
    components: {
    },

    data(){
        return {
        }
    },

    methods: {

        chartConfig(ctx){
            let chart = new Chart(ctx, this.result.data)
            console.log('chart ', chart)
        }
    },

    created(){
        console.log('result ', this.result)
        this.$nextTick(() => {
            const canvas = this.$refs.myChart
            const ctx = canvas
            this.chartConfig(ctx)
        })
    }
}
</script>