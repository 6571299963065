<template>
  <div>
    <div class="text-h4 mb-10">Perfil de Usuário</div>

    <CardUserPerfil />
<!---
    <div class="text-h4 mb-10">
      <div class="container">
      Alterar senha
      </div>
    <UpdatePasswordForm />
    </div> --->
    
  </div>
</template>

<script>
import CardUserPerfil from "../../components/card/CardUserPerfil.vue";
//import UpdatePasswordForm from "../../components/form/updatePasswordForm.vue";
export default {
  components: { CardUserPerfil },
  name: "PerfilUserPage",
};
</script>

<style scoped>
.h1 {
  margin-top: 15px;
  padding-bottom: 30px;
}

.container {
  margin-top: 30px;
}
</style>
