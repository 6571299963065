<template>
    <div>
        <CardFirstAccess />
    </div>
</template>


<script>
import CardFirstAccess from '../../components/card/CardFirstAccess.vue';
export default {
    components: { CardFirstAccess }
}
</script>