<template>
    <v-container>
        <div class="d-flex justify-space-between">
            <h2>
                Programação Semanal
            </h2>

            <div class="d-flex align-center">

                <v-menu v-model="menu" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" large icon color="primary">
                            <v-icon>mdi-filter</v-icon>
                        </v-btn>
                    </template>

                    <v-card min-width="400" height="140">

                        <div>

                            <v-text-field type="date" v-model="dataAtual" label="Insira uma Data"
                                outlined></v-text-field>

                            <v-card-actions class="justify-end">

                                <v-btn variant="text" @click="menu = false">
                                    Cancel

                                </v-btn>

                                <v-btn color="primary" variant="text" @click="onInit()">
                                    Confirmar
                                </v-btn>
                            </v-card-actions>
                        </div>

                    </v-card>
                </v-menu>

                <v-btn @click="open = true" color="primary" icon dark class="mr-2">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </div>

        </div>

        <div class="d-none d-md-flex mt-10 ml-10 mr-5 w-100 text-left justify-space-around"
            style="margin: 0 auto; padding: 45px;">

            <div v-for="(dia, index) in datas" :key="index" class="text-xs-h1 text-center"
                style="margin-left: 55px; width: 150px;">
                <span class="text-left">
                    {{ new Date(dia).toLocaleDateString() }}
                </span>
            </div>
        </div>

        <div v-on:mousemove="updateMouseCoordinates" class="w-100">
            <v-data-table style="cursor: pointer;" :headers="header" :items="items" class="elevation-1 text-wrap"
                hide-default-footer @click:row="rowClick">
                <!--
                <template v-slot:item="{ item }">
                    <span>
                        {{  item }}
                    </span>
                </template>-->

                <template v-slot:item="item">
                    <tr>
                        <td>{{ item.item.usuarioNome }}</td>

                        <td style="width: 150px;" v-for="(dia, index) in datas" :key="index"
                            class="text-start custom-td" @click="editItem(item, new Date(dia).toLocaleDateString())">
                            <span class="font-weight-bold">
                                {{ item.item[new Date(dia).toLocaleDateString()] }}
                            </span>
                            <br />
                            <span>
                                {{ item.item[`${new Date(dia).toLocaleDateString()}-city`] }}
                            </span>
                        </td>
                    </tr>
                </template>

            </v-data-table>
        </div>

        <div class="text-center pt-2 pagination">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7" @input="inputPagination"></v-pagination>
        </div>
        <!--
        <v-menu v-model="rowMenu.showMenu" :position-x="rowMenu.menuX" :position-y="rowMenu.menuY">
            <v-list dense rounded>

                <v-list-item v-for="(date, dateIndex) in datas" :key="dateIndex" link>
                    <v-list-item-icon>
                        <v-icon color="warning">mdi-pencil</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content @click="editItem(formatDate(new Date(date)))">
                        <v-list-item-title>{{ formatDate(new Date(date)) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>-->

        <entityFormDialog v-model="open" :schema="schema" :entity="entity" :form="schema.form" :value="open"
            :loading="loading" @dialog="($event) => (open = $event)" @confirm="saveItem" @cancel="open = !open"
            :options="['Realizar Apontamentos']" :apontamentos="apontamentos" @eventOption="handleOptions">
        </entityFormDialog>

        <entityFormDialog v-model="openNote" :schema="schemaNote" :entity="entityNote" :form="schemaNote.form"
            :value="openNote" :loading="loading" @dialog="($event) => (openNote = $event)" @confirm="saveItemNote"
            @cancel="openNote = !openNote">
        </entityFormDialog>

        <relation-link-dialog :searchFields="schemaNote.searchFields" :schemaName="schemaNote.title"
            :value="dialogEntity" :property="schemaNote.property" v-model="dialogEntity" @OK="dialogEntity = false" />

    </v-container>

</template>

<script>
import EntityService from "@/services/entityService.js";
import schemaService from "@/services/schemaService";
import RelationLinkDialog from '../../components/entities/relationLinkDialog.vue';
//import DatePickerField from '../../components/inputs/datePickerField.vue';

export default {
    mixins: [],
    components: {
        EntityFormDialog: () => import("@/components/dialogs/entityFormDialog.vue"),
        RelationLinkDialog,
        //DatePickerField,
    },
    data() {
        return {
            diasSemana: [],
            dialogEntity: false,
            openNote: false,
            open: false,
            header: [],
            semanaComDatas: [],
            userList: [],
            panel: [],
            entityService: new EntityService('Weeklyschedule'),
            entityNoteService: new EntityService('NoteWeeklySchedule'),
            schema: null,
            schemaNote: null,
            entity: {},
            entityNote: {},
            loading: true,
            items: [],
            datas: [],
            page: 1,
            pageCount: 0,
            itemSelected: {},
            rowMenu: {
                showMenu: false,
                closeOnContentClick: false,
                x: 0,
                y: 0,
                menuX: 0,
                menuY: 0,
            },
            rowMenuOpen: false,
            actions: [
                {
                    icon: "mdi-pencil",
                    color: "warning",
                    title: "Visualizar/Editar",
                    handle: () => {
                        this.editItem();
                    },
                },
                {
                    icon: "mdi-pencil",
                    color: "warning",
                    title: "Realizar Apontamento",
                    handle: () => {
                        //this.editItem();
                    },
                },
                {
                    icon: "mdi-delete",
                    color: "error",
                    title: "Deletar",
                    handle: () => {
                        this.deleteItem()
                    },
                },
            ],
            dataUser: null,
            fav: true,
            menu: false,
            message: false,
            hints: true,
            apontamentos: [],

            dataAtual: new Date(),
            groupBy: [
                {
                    key: 'gluten',
                    order: 'asc',
                },
            ],

        }
    },

    watch: {
        open(value) {
            if (value == false) {
                this.entity = {}
            }
        },

        dataAtual(value) {
            console.log("NEW DATE >>> ", value)
        },

        "entity.usuarioId": {
            async handler(value, oldValue) {

                if (oldValue != undefined) {
                    this.entity.owners = this.entity?.owners?.filter((owner) => owner.userId != oldValue)
                }

                console.log("VALUE AQUI >>> ", value)

                if (value) {

                    let dataUser = await this.$auth.findById(value.userId !== undefined ? value.userId : value)
                    let user = dataUser?.data?.data

                    if (this.entity.owners.findIndex((owner) => owner.userId == user.id) == -1) {
                        this.entity.owners.push({ userId: user.id, userName: user.name })
                    }
                }


            },
            deep: true,
        }
    },

    methods: {

        async openProgramacao(search) {
            let data = await this.entityNoteService.getAll(1, 30, search, "weeklyscheduleId")
            this.apontamentos = data.data.data.data

            console.log("Apontamentos >>> ", this.apontamentos)
        },

        formatDate(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },

        async saveItemNote() {
            await this.entityNoteService.insertOrUpdate(this.entityNote)
            this.openNote = false
            this.$notfy.success("Cadastrado com sucesso.");

            if (this.entity.id) {
                await this.openProgramacao(this.entity.id)
                return
            }

            this.entityNote.note = ''
        },

        async saveItem() {

            if (!this.entity.usuarioId) {
                this.$notfy.error("Usuário não informado.");
                return
            } else if (!this.entity.fornecedorId) {
                this.$notfy.error("Fornecedor não informado.");
                return
            } else if (!this.entity.data) {
                this.$notfy.error("Data não informada.");
                return
            }
            /*
                        let dataUser = await this.$auth.findById(this.entity.usuarioId)
                        let user = dataUser.data.data
            
                        if(this.entity.owners.findIndex((owner) => owner.userId == user.id) == -1){
                            this.entity.owners.push({  userId: user.id, userName: user.name })
                        }*/


            this.loading = false
            await this.entityService.insertOrUpdate(this.entity)
            this.loading = true
            this.open = false

            this.$notfy.success("Cadastrado com sucesso.");
            await this.getWeeklyschedule()
            this.entity = {}
        },

        async inputPagination(page) {
            this.page = page
            await this.getWeeklyschedule()
        },

        async getWeeklyschedule() {
            let dataInit = new Date(this.datas[0])
            let data = await this.$weeklyScheduleService.getWeeklySchedule(this.page,
                `${dataInit.getFullYear()}-${dataInit.getMonth()}-${dataInit.getDay()}`, this.datas[5].toISOString().split('T')[0], '')

            this.pageCount = data.data.data.pages
            this.items = data.data.data.data

            this.items = this.items.map((item) => {

                let obj = {}

                item.schedules.map((schedule) => {
                    //obj[this.formatDate(new Date(schedule.data))] = this.formatDate(new Date(schedule.data))
                    obj[this.formatDate(new Date(schedule.data))] = schedule.fornecedorNome != "" ? schedule.fornecedorNome?.split("/")[0] : "Não Informado",
                    
                    obj[`${this.formatDate(new Date(schedule.data))}-city`] =
                        schedule.fornecedorNome != "" ? schedule.fornecedorNome?.split("/")[1] : "Não Informado",

                        obj.usuarioId = schedule.usuarioId
                    obj.usuarioNome = schedule.usuarioNome,
                        obj[`id-${this.formatDate(new Date(schedule.data))}`] = schedule.id
                })

                return obj
            })

            console.log("ITEMS >>> ", this.items)
        },

        async editItem(item, value) {
            let data = await this.entityService.getById(item.item[`id-${value}`])
            console.log("DATA >>> ", item.item)
            if (data.data.data.id) {
                this.entity = data?.data?.data
            } else {

                let dataUser = await this.$auth.findById(item.item.usuarioId)
                let user = dataUser.data.data
                console.log("USER >>> ", user)

                this.entity = {
                    usuarioId: item.item.usuarioId,
                    data: value,
                    owners: []
                }

                this.entity.owners[0] = { userId: this.dataUser.userId, userName: this.dataUser.user }
                this.entity.owners[1] = { userId: user.id, userName: user.name }
            }

            this.open = true

            if (this.entity.id) {
                await this.openProgramacao(this.entity.id)
                return
            }

            this.apontamentos = []
        },

        async deleteItem() {
            if (!(await this.$isConfirm())) return;

            await this.entityService.delete(this.itemSelected.id)
            await this.getWeeklyschedule()
        },

        timeout(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },

        async openRowMenu() {
            await this.timeout(100);
            this.rowMenu.menuX = this.rowMenu.x;
            this.rowMenu.menuY = this.rowMenu.y;

            this.$nextTick(() => {
                this.rowMenu.showMenu = true;
            });
        },

        updateMouseCoordinates(event) {
            this.rowMenu.x = event.clientX;
            this.rowMenu.y = event.clientY;
        },
        async rowClick(row) {
            console.log("ROW >>> ", row)
            this.itemSelected = Object.assign({}, row);
            await this.openRowMenu();
        },

        openApontamento() {
            console.log("VALUE >>> ", this.entity)
            this.openNote = true
            this.entityNote.note = ''
            this.entityNote.weeklyscheduleId = this.entity.id
        },

        handleOptions(e) {
            console.log("E >>> ", e)

            if (e == "Realizar Apontamentos") {
                this.openApontamento()
            }
        },

        onInit() {
            console.log("DATA ATUAL >>> ", this.dataAtual)
            /*
                        if(this.dataAtual?.split('-')?.length > 0){
                            this.dataAtual = this.dataAtual[3] + 1
                        }*/

            const diasDaSemana = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
            this.diasSemana = diasDaSemana

            this.datas = []
            const dataAtual = new Date(this.dataAtual); // Tue Jun 10 2024 11:58:39 GMT-0300 (Hora padrão de Brasília)
            this.semanaComDatas = diasDaSemana.map((dia, index) => {
                const data = new Date(dataAtual);
                data.setDate(dataAtual.getDate() + ((index + 1) - dataAtual.getDay()));

                this.datas.push(new Date(data))
                return {
                    text: `${dia}`,
                    value: `${this.formatDate(data)}`,
                    class: "sticky-header primary lighten-2 font-weight-bold text-sm-h5 text-no-wrap text-start custom-style-weeklyTable",
                    sortable: false,
                    icon: "mdi-plus"
                };
            });

            console.log("semanaComDatas >>> ", this.semanaComDatas)

            this.header = [
                {
                    text: 'Usuário',
                    value: 'usuarioNome',
                    class: "sticky-header primary lighten-2 font-weight-bold text-start text-sm-h5 custom-style-weeklyTable",
                    sortable: false,
                }, ...this.semanaComDatas]

            this.getWeeklyschedule()
        }
    },

    mounted() {
        // this.getWeeklyschedule()
    },

    async created() {
        this.onInit()

        this.schema = await schemaService.getByNameToJS('Weeklyschedule')
        this.schemaNote = await schemaService.getByNameToJS('NoteWeeklySchedule')

        this.dataUser = await this.$auth.getUserParams()

        console.log("dataUser >>> ", this.dataUser)

        console.log("schemaNote >>> ", this.schemaNote)
    }
}
</script>

<style>
.custom-style-weeklyTable {
    width: 80px;
}

.custom-td {
    cursor: pointer;
    transition: 5s;
}

.custom-td:hover {
    color: blue;
}
</style>