<template>
  <div>
    <Message msg="Troca de Senha" color="primary" type="info" />

    <v-app id="inspire">
      <v-main >
        <v-container fluid justifyCenter align-center class="d-flex">
          <v-layout align-center justify-center >
            <v-flex class="d-flex justify-center align-center mt-16"
            
            >
              <v-card class="elevation-8" style="width: 500px;">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>Nova Senha</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <div class="d-flex justify-center align-center">
                    <v-img  
                      :src="logo"
                      max-width="180"
                      max-height="200"
                    />
                  </div>
                  <form>
                    <v-text-field
                      v-model="password"
                      id="current-password"
                      prepend-icon="mdi-lock"
                      name="current-password"
                      label="Nova Senha"
                      type="password"
                      :rules="requiredRule"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="confimrPassword"
                      id="new-password"
                      prepend-icon="mdi-lock"
                      name="new-password"
                      label="Confirmar Senha"
                      type="password"
                      required
                    ></v-text-field>
                  </form>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn color="primary" width="50%" @click="changePasswordRequest()">
                    Trocar Senha
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import Message from '../../components/message/Message.vue';
export default {
  name: 'ChangePassword',
  data(){
    return {
      password: null,
      confimrPassword: null,
      email: null,
      tenanty: null,
      logo: null,
      token: null,
      requiredRule: [v => !!v || "Campo obrigatório!", v => v?.length >= 6 || 
          'Precisa ter no minimo 6 caracteries', 
          v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'A senha deve conter pelo menos uma letra minúscula e um número, um caractere especial @%$ e uma letra maiúscula',
          v => /(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#;$%*(){}_+^&]/.test(v) || "Faltou algum caraterie especial"]
    }
  },
  components: {
    Message
  },
  created(){
    this.logo = this.$route.params.logo
    this.token =  this.$route.params.token
    this.email = this.$route.params.email
    this.tenanty = this.$route.params.tenanty

    if(!this.token){
      this.$router.push("Home")
    }
  },
  methods: {
    async changePasswordRequest(){

      if(this.password !== this.confimrPassword){
        this.$notfy.error("Senhas precisam ser iguais.");
        return
      }

      let data = await this.$auth.updatePasswordByToken({
        token: this.token,
        email: this.email,
        tenanty: this.tenanty,
        newPassword: this.password
      })
      
      if(data.data.data.id){
        this.$notfy.success("Senhas atualizada com sucesso.");
        this.$router.push("Login")
      }
    }
  }
}
</script>
