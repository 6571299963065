<template :key="componentKey">
  <div style="margin-top: -50px; margin-left: -50px" v-if="showPage">
    <div
      :class="relation != null ? 'text-h6 mb-10' : 'text-h4 mb-10'"
      v-if="schema"
    >
      {{ schema.description }}
    </div>

    <div class="d-flex align-center">
      <v-btn
        @click="newItem"
        v-if="getPermissionCrud(schema, 'Create') > 0"
        color="primary"
        icon
        dark
        class="mr-2"
      >
        <v-icon>mdi-plus</v-icon></v-btn
      ><!--
      <v-tooltip right v-if="!advancedSearchMode || (advancedSearchMode && saved)">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" @click="exportAsExcelFile">
            mdi-microsoft-excel
          </v-icon>
        </template>
        Exportar como XLSX
      </v-tooltip>--->

      <v-spacer></v-spacer>
    </div>

    {{ ids }}

    <v-row style="align-items: center; justify-content: center">
      <v-col cols="10" md="9">
        <form @submit.prevent="searchItems"  @input.prevent="searchTimeOut">
          <v-text-field
            class="py-5"
            v-model="search"
            chips
            clearable
            hide-details
            hide-selected
            prepend-icon="mdi-magnify"
            label="Search"
            solo
            @click:prepend="searchItems"
            @click:append="searchItems"
          />
        </form>
      </v-col>
      <!--
      <v-switch v-model="advancedSearchMode" :persistent-hint="true">
          </v-switch>--->

      <v-col cols="5" md="2" style="margin: 5px">
        <v-combobox
          outlined
          style="padding: 10px; height: 55px"
          v-model="selectedSearchDef"
          item-text="description"
          :items="searchDefs"
          label="Preset de pequisa"
          @input="advancedSearchMode = true"
          @click:append="
            (advancedSearchMode = false),
              (selectedSearchDef = {
                schemaName: schemaName,
                description: null,
                id: null,
                userId: null,
                defs: {
                  filters: [],
                  orderBy: [],
                  userColumns: [],
                },
              })
          "
          :append-icon="selectedSearchDef !== '' ? 'mdi-close' : ''"
        ></v-combobox>
      </v-col>

      <div>
        <v-btn color="primary" icon dark class="mr-2">
          <i
            class="bi bi-funnel"
            style="font-size: 25px"
            @click="dialogFilterAvenced = true"
          ></i>
        </v-btn>
        <!--
          <v-icon v-if="advancedSearchMode" @click="advancedSearchMode = false">mdi-delete</v-icon> -->
      </div>

      <v-btn
        @click="openDialogStatusConfig = true"
        color="primary"
        icon
        dark
        class="mr-2"
        v-if="statusF > 0 && params.root == true"
      >
        <i class="bi bi-gear-fill" style="font-size: 25px"></i
      ></v-btn>
    </v-row>

    <v-dialog v-model="dialogFilterAvenced">
      <v-card style="padding: 15px">
        <v-card-title>Pesquisa Avançada</v-card-title>
        <v-autocomplete
          v-model="selectedSearchDef"
          item-text="description"
          return-object
          :items="searchDefs"
          label="Selecione um preset de pesquisa"
          append-outer-icon="mdi-plus"
          :append-icon="
            selectedSearchDef
              ? 'mdi-delete'
              : (selectedSearchDef = {
                  schemaName: this.schemaName,
                  description: null,
                  id: null,
                  userId: null,
                  defs: {
                    filters: [],
                    orderBy: [],
                    userColumns: [],
                  },
                })
          "
          @click:append-outer="createSearchDefDialog = true"
          @click:append="requestDeleteSearchDef"
          clearable
        >
        </v-autocomplete>

        <template v-if="selectedSearchDef">
          <v-form ref="editSearchDefForm">
            <v-autocomplete
              v-if="selectedSearchDef.defs"
              v-model="selectedSearchDef.defs.userColumns"
              item-text="text"
              item-value="value"
              :items="headersComp"
              label="Colunas"
              clearable
              chips
              deletable-chips
              multiple
              small-chips
              :rules="[rules.atLeastOne]"
            >
            </v-autocomplete>

            <v-autocomplete
              v-if="selectedSearchDef.defs"
              v-model="selectedSearchDef.defs.orderBy"
              return-object
              item-text="text"
              :items="orderByOptions"
              label="Ordenação"
              clearable
              multiple
              chips
              small-chips
              :rules="[rules.atMostOne]"
            >
            </v-autocomplete>
          </v-form>

          <v-select
            v-if="selectedSearchDef.defs && schema && schema.filterFields"
            label="Filtros"
            multiple
            :items="selectedSearchDef.defs.filters || []"
            :value="selectedSearchDef.defs.filters || []"
            readonly
          >
            <template v-slot:selection="{ item, index }">
              <v-chip small close @click:close="deleteFilter(index)">
                {{ getFieldLabel(item.field) }}
                {{ getOperatorSymbol(item.operator) }} {{ item.value }}
              </v-chip>
            </template>

            <template v-slot:append-outer>
              <v-dialog
                v-if="selectedSearchDef"
                width="600"
                v-model="newFilterDialog"
                persistent
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-plus </v-icon>
                </template>

                <v-card>
                  <v-card-title>Novo Filtro</v-card-title>

                  <v-card-text>
                    <v-form ref="filterCreationForm">
                      <v-autocomplete
                        v-model="newFilter.field"
                        :items="filterableFields"
                        item-text="label"
                        item-value="name"
                        label="Campo"
                        hide-details="auto"
                        :rules="[rules.required]"
                        class="mb-2"
                      >
                      </v-autocomplete>

                      <v-autocomplete
                        v-if="newFilter.field"
                        v-model="newFilter.operator"
                        :items="newFilterOptions.operators"
                        item-text="label"
                        item-value="id"
                        label="Comparação"
                        hide-details="auto"
                        :rules="[rules.required]"
                        class="mb-2"
                      >
                      </v-autocomplete>

                      <component
                        v-if="newFilter.field"
                        :is="newFilterOptions.component"
                        v-bind="newFilterOptions.props"
                        v-model="newFilter.value"
                      >
                      </component>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="red" dark @click.stop="cancelFilterCreation"
                      >Cancelar</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn color="blue" dark @click.stop="createFilter"
                      >Adicionar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-select>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              dark
              @click="saveSearchDef"
              v-if="selectedSearchDef.description"
            >
              Aplicar
            </v-btn>
            <v-btn color="blue" dark @click="searchFirstOne" v-else>
              Pesquisar
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>

    <v-dialog width="600" v-model="createSearchDefDialog">
      <v-card>
        <v-card-title>Novo Preset de Pesquisa</v-card-title>

        <v-card-text>
          <v-form ref="searchDefCreationForm">
            <v-text-field
              v-model="newSearchDefDescription"
              label="Descrição"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" dark @click.stop="cancelSearchDefCreation">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn dar color="blue" dark @click.stop="confirmCreateSearchDef">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      v-on:mousemove="updateMouseCoordinates"
      v-if="getPermissionCrud(schema, 'Read')"
    >
      <v-data-table
        :style="{ 'max-height': `${width * 0.55}px`, overflowX: 'auto' }"
        :headers="propertiesVisibleTable.length == 0 ? userHeaders 
          : userHeaders.filter( resp => propertiesVisibleTable.indexOf(resp.value) !== -1)"
        :items="itemsTable"
        :items-per-page="pagination.limit"
        class="elevation-1 element header-table text-no-wrap"
        disable-sort
        hide-default-footer
        :single-expand="false"
        @click:row="rowClick"
      >
      
      <template v-slot:item.color="{ value }">
        <div :style="`background-color: ${value};`" class="color-table">
          {{ value }}
        </div>
      </template>
      </v-data-table>
    </div>
    <div class="text-center">
      <v-pagination
        @input="changePage"
        v-model="pagination.page"
        :length="pagination.pages"
      ></v-pagination>

      <v-menu
        v-model="rowMenu.showMenu"
        :position-x="rowMenu.menuX"
        :position-y="rowMenu.menuY"
      >
        <v-list dense rounded v-if="aprove == true">
          <v-list-item
            v-for="(action, index) in actions.filter(
              (a) =>
                (a.title == 'Visualizar/Editar' &&
                  getPermissionCrud(schema, 'Update') > 0) ||
                (a.title == 'Deletar' &&
                  getPermissionCrud(schema, 'Delete') > 0)
            )"
            :key="index"
            link
            @click="action.handle"
          >
            <v-list-item-icon>
              <v-icon :color="action.color">{{ action.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ action.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="openSharedItems">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Compartilhar item
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="schemaActions && schemaActions.length"></v-divider>

          <v-list-item
            v-for="action in schemaActions"
            :key="action.id"
            link
            @click="requestRunSchemaAction(action)"
          >
            <v-list-item-icon>
              <v-icon>{{ action.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ action.description }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <entity-form-dialog
        v-if="schema"
        v-model="formDialog"
        :isSave="isSave"
        :schema="schema"
        :form="schema.form"
        :entity="itemSelected"
        :actions="customActions"
        :loading="loading"
        @confirm="saveItem(itemSelected, position), componentKey++"
        @delete="deleteItem"
        @cancel="cancelFormDialog"
        @dialog="($event) => (formDialog = $event)"
      ></entity-form-dialog>

      <DialogStatusConfig
        :value="openDialogStatusConfig"
        v-model="openDialogStatusConfig"
        :schema="schema"
        @cancel="openDialogStatusConfig = false"
      />
    </div>

    <v-dialog v-model="actionParameterDialog" width="75vw" persistent>
      <ActionDialog
        v-if="actionParameterDialog"
        :schema="schema"
        :entity="itemSelected"
        :action="requestedAction"
        :entityId="itemSelected.id || ''"
        @close="actionParameterDialog = false"
      >
      </ActionDialog>
    </v-dialog>

    <div class="text-center w-33" >
      <v-dialog
        v-model="autoCompleteUsers"
        width="500"
        class="pa-10"
        persistent
        style="margin: 0 auto;"
      >
        <v-card class="pa-1">
          <v-card-title>Compartilhamento </v-card-title>
          <auto-complete-users
            :isOwner="false"
            class="pa-4"
            :userList="userList"
            :userCad="usersShared"
            @input="usersSharedItem"
            @search="searchUser"
            :value="autoCompleteUsers"
          >
          </auto-complete-users>

          <div v-if="usersShared.length > 0" >
            <h3 class="ma-5">Pessoas com acesso</h3>
            <v-list lines="one" style="height: 176px; overflow-y: auto;">
              <v-list-item
              v-for="(item, index) in usersShared" :key='index'
              title="item"
              subtitle="SubItem..."
              class="ma-3 item-list"
              style="border-bottom: 1px solid rgba(172, 168, 168, 0.719); padding-bottom: 5px;">

              <div class="list-container">
                <v-avatar color="primary" height="45">
                  <span class="text-h5">{{ item.name.slice(0,1) }}</span>
                </v-avatar>

                <div class="content-list">
                  <div class="info-container">
                    <p>{{ item.name }}</p>
                    <span>{{ item.userName }}</span>
                  </div>
                  <v-icon color="error" style="cursor: pointer;" @click="removeAccess(item.id)">mdi-delete</v-icon>
                </div>

              </div>
              </v-list-item>
            </v-list>
          </div>

        <v-card-actions style="justify-content: space-between">
          <v-btn variant="text" color="error " @click="autoCompleteUsers = false">Cancelar</v-btn>
          <v-btn variant="text" color="primary" @click="confirmSharedItem">Compartilhar</v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import schemaService from "../../services/schemaService";
import ActionDialog from "../../components/dialogs/actionDialog.vue";
import entityFormDialog from "../../components/dialogs/entityFormDialog.vue";
import EntityService from "../../services/entityService.js";
import entityPageMixin from "../../mixins/entityPageMixin";
import { VCombobox } from "vuetify/lib";
import NumberField from "../../components/inputs/numberField.vue";
import { FILTER_OPERATORS } from "../../utils/filterOperators.js";
import xlsx from "json-as-xlsx";
import actionPermissionsMixins from "@/mixins/actionPermissionsMixins";
import DialogStatusConfig from "../../components/statusFlow/dialogStatusConfig.vue";
import autoCompleteUsers from "@/components/inputs/autoCompleteUsers";
import AutoCompleteUsers from "../../components/inputs/autoCompleteUsers.vue";
import getUsersMixin from '@/mixins/getUsersMixin';
import emitter from "@/services/emitter";

export default {
  props: ["schemaName", "ids", "relation", "item"],
  components: {
    entityFormDialog,
    ActionDialog,
    VCombobox,
    NumberField,
    DialogStatusConfig,
    autoCompleteUsers,
    AutoCompleteUsers,
  },
  mixins: [entityPageMixin, actionPermissionsMixins, getUsersMixin],
  data() {
    return {
      typeSchema: null,
      showPage: false,
      propertiesVisibleTable: [],
      delayTimer: null,
      doneTypingInterval: 1500,
      pivoTable: false,
      schemaId: '',
      usersShared: [],
      sharedItems: [],
      userList: [],
      idUsers: '',
      autoCompleteUsers: false,
      aprove: true,
      componentKey: 0,
      //actionsList: [],
      position: null,
      openDialogStatusConfig: false,
      entityService: new EntityService(this.schemaName),
      isOwner: true,
      newFilterDialog: false,
      dialogFilterAvenced: false,
      newFilter: {
        field: null,
        operator: null,
        value: null,
      },
      statusF: null,
      createSearchDefDialog: false,
      newSearchDefDescription: "",
      params: null,

      actionParameterDialog: false,
      requestedAction: null,

      rules: {
        required: (value) => !!value || "Este campo é obrigatório",
        atLeastOne: (value) =>
          value.length > 0 || "Selecione pelo menos uma opção",
        atMostOne: (value) =>
          value.length <= 1 || "Selecione no máximo uma opção",
      },

      width: screen.height,
    };
  },

  mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    }

    
  },

  computed: {
    userHeaders() {
      if (this.advancedSearchMode) {
        if (this.selectedSearchDef?.defs?.userColumns) {
          let userColumns = this.selectedSearchDef?.defs?.userColumns;

          this.selectedSearchDef?.defs?.userColumns.map((resp, i) => {
            if (this.headersComp.find((h) => h.value === resp) === undefined) {
              userColumns.splice(i, 2);
            }
          });

          return this.selectedSearchDef?.defs?.userColumns.map((header) => {
            return this.headersComp.find((h) => h.value === header);
          });
        } else {
          return [
            this.headersComp.find((h) => h.value === this.schema.descriptor),
          ];
        }
      } else {
        return this.headersComp;
      }
    },

    itemsTable() {
      this.headersComp.map((resp, i) => {
          if (resp.value == "file") {
            this.headersComp.pop(i);
          }
        
      });

/*
      this.filteredItems.map((resp) => {
        if (resp.owners)
          resp.owners = resp.owners.filter((item) => item !== "");
      });*/
      return this.filteredItems;
    },

    filterableFields() {
      if (!this.schema) return [];
      else {
        const filterFields = this.schema.filterFields.split(",");
        const filterableFields = [];
        for (const fieldName in this.schema.properties) {
          if (filterFields.includes(fieldName)) {
            filterableFields.push({
              name: fieldName,
              ...this.schema.properties[fieldName],
              label: this.getFieldLabel(fieldName),
            });
          }
        }
        return filterableFields;
      }
    },

    newFilterOptions() {
      if (!this.newFilter.field) return {};
      const filterField = this.filterableFields.find(
        (field) => field.name === this.newFilter.field
      );

      if (filterField.type === "string") {
        if (filterField.enum) {
          return {
            component: "v-autocomplete",
            props: {
              label: "Valor",
              hideDetails: "auto",
              items: filterField.enum,
              rules: [this.rules.required],
            },
            operators: [
              FILTER_OPERATORS.equalsTo,
              FILTER_OPERATORS.notEqualsTo,
            ],
          };
        }
      } else if (filterField.type === "array") {
        return {
          component: "v-combobox",
          props: {
            label: "Valor",
            hideDetails: "auto",
            chips: true,
            deletableChips: true,
            multiple: true,
            rules: [this.rules.required],
          },
          operators: [
            FILTER_OPERATORS.contains,
            FILTER_OPERATORS.notContains,
            FILTER_OPERATORS.equalsTo,
            FILTER_OPERATORS.notEqualsTo,
          ],
        };
      } else if (filterField.type === "number") {
        return {
          component: "NumberField",
          props: {
            label: "Valor",
            clearable: true,
            hideDetails: "auto",
            rules: [this.rules.required],
          },
          operators: [
            FILTER_OPERATORS.equalsTo,
            FILTER_OPERATORS.notEqualsTo,
            FILTER_OPERATORS.greaterThan,
            FILTER_OPERATORS.greaterThanOrEqualsTo,
            FILTER_OPERATORS.lessThan,
            FILTER_OPERATORS.lessThanOrEqualsTo,
          ],
        };
      }

      const ordenado = Object.values(FILTER_OPERATORS).sort(function (a, b) {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });

      return {
        component: "v-text-field",
        props: {
          clearable: true,
          hideDetails: "auto",
          label: "Valor",
          rules: [this.rules.required],
        },
        operators: ordenado,
      };
    },

    orderByOptions() {
      if (!this.schema) return [];
      else {
        const orderByOptions = [];

        for (const header of this.headersComp) {
          orderByOptions.push({
            field: header.value,
            direction: "asc",
            text: header.text + " (asc)",
          });
          orderByOptions.push({
            field: header.value,
            direction: "desc",
            text: header.text + " (desc)",
          });
        }

        return orderByOptions;
      }
    },

    schemaActions() {
      var actionId = [];
      for (var i = 0; i < this.actionsList.length; i++) {
        actionId.push(this.actionsList[i].actionId);
      }

      if (!this?.customActions?.length) return [];
      else
        return this.customActions.filter(
          (action) =>
            action.callType === "Schema" && actionId.indexOf(action.id) !== -1
        );
    },
  },

  watch: {

    selectedSearchDef: {
      deep: true,
      handler(saerchDef) {
        this.saved = false;

        if (
          !saerchDef?.defs?.filters.length > 0 ||
          !saerchDef?.defs?.userColumns.length > 0
        ) {
          //console.log("Aqui");
          this.advancedSearchMode = false;
        }
      },
    },

    async schemaName() {
      this.showPage = false
      this.search = "";
      this.pagination.page = 1;
      this.pagination.pages = 1;
      await this.getPermission();
      this.existStatusF();

      let data = await schemaService.getSchemaByName(this.schemaName);
      this.schemaId = data.id

      let jsonValue = JSON.parse(data.jsonValue)
      this.typeSchema = jsonValue.type
      if(jsonValue.type == "custom"){
        this.$router.push(jsonValue.route)
        return
      }

      this.showPage = true

      if(this.schema.propertiesVisibleTable){
          this.propertiesVisibleTable = this.schema.propertiesVisibleTable?.split(',');
      } else {
        this.propertiesVisibleTable = []
      }

      emitter.emit('evenWatchSchema', this.schema)
    },
  },

  async created() {
    this.showPage = false
    let data = await schemaService.getSchemaByName(this.schemaName);
    this.schemaId = data.id

    let jsonValue = JSON.parse(data.jsonValue)
    this.typeSchema = jsonValue.type
    if (jsonValue.type == "custom") {
      this.$router.push(jsonValue.route)
      return
    }

    this.showPage = true

    this.params = await this.$auth.getUserParams();
    const userId = this.params.userId;

    await this.getPermission();
    await this.getActionsPermission(userId);

    if(this.schema.propertiesVisibleTable){
          this.propertiesVisibleTable = this.schema.propertiesVisibleTable?.split(',');
      } else {
        this.propertiesVisibleTable = []
      }

    this.existStatusF();
    emitter.emit('evenWatchSchema', this.schema)
    //this.showPage = true
  },

  methods: {

    searchTimeOut(){

      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.searchItems()
      }, this.doneTypingInterval)
    },

    usersSharedItem(users){
      this.idUsers = users.map(resp => {
        return resp
      }).join(',')

    },

    async openSharedItems(){
      this.autoCompleteUsers = true
      this.userList = await this.getUsers();
      await this.getSharedSchemaRecord();
    },

    async searchUser(value){
      this.userList = await this.searchInput(value)
    },

    async getSharedSchemaRecord() {
      this.sharedItems = []
      this.usersShared = []

      let userId = this.params.userId;
      let recordId = this.itemSelected.id
      const req = await this.$sheredSchemaRecord.getAllBySchemaRecordId(recordId, userId);
      console.log('Req ', req.data.data.data)

      let items = req.data.data.data
      let ids = []

      if(items.length > 0){
        items.map(async (resp) => {

          if(resp.userIdReceive){
            console.log(resp.userIdReceive.split(","))

            this.sharedItems.push(resp)
            ids = resp.userIdReceive.split(",")
            ids = ids.filter(item => item !== '')
          }
        })
        
        ids.map(async item => {
              let req = await this.$auth.findById(item);
              if(req.data.data){
                this.usersShared.push(req.data.data)
              } 
          })

      }
    },

    async removeAccess(id){
      this.usersShared = this.usersShared.filter(resp => resp.id !== id)
    },

    async confirmSharedItem(){
      let req

      let userId = this.params.userId;
      let userName = this.params.user;

      /*if(this.idUsers == '' && this.usersShared.length == 0) {
        this.$notfy.error('Error')
        return
      }*/

      if(this.usersShared.length > 0){
        this.idUsers += ',' + this.usersShared.map(resp => {
          return resp.id
        }).join(',')
      }

      const body = {
        schemaRecordId: this.itemSelected.id,
        schemaName: this.schemaName,
        userIdSender: userId,
        userIdReceive: this.idUsers,
        userNameSender: userName,
        //userNameReceive: "string"
      }

      if(this.sharedItems.length > 0){
        req = await this.$sheredSchemaRecord.updateSchemaRecord(body, this.sharedItems[0].id);
      } else {
        req = await this.$sheredSchemaRecord.insertSchemaRecord(body);
      }


      if(req.data.success){
        this.$notfy.success('Item compartilhado com sucesso')
        this.autoCompleteUsers = false
        this.idUsers = '';
      }
    },

    existStatusF() {
      let obj = Object.entries(this.schema.properties);
      obj = obj.filter((resp) => resp[1]?.statusF === true);
      this.statusF = obj.length;
    },

    showPosition(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      this.position = {
        latitude,
        longitude,
      };

      return this.position;
    },

    async getPermission() {
      const data = await this.$auth.getUserParams();
      const permissions = await this.$acessGroup.searchByUserId(data.userId);
      permissions.data.data.schemas.map((p) => {
        if (p.schemaName == this.schemaName) {
          if (!p.permissions[4]) {
            this.isOwner = false;
          } else {
            this.isOwner = true;
          }
        }
      });
    },
    requestRunSchemaAction(action) {
      this.requestedAction = action;
      this.actionParameterDialog = true;
    },

    cancelFilterCreation() {
      this.newFilterDialog = false;
      this.$set(this.newFilter, "field", null);
      this.$set(this.newFilter, "operator", null);
      this.$set(this.newFilter, "value", null);
    },

    createFilter() {
      if (!this.$refs.filterCreationForm.validate()) return;

      if (!this.selectedSearchDef.defs.filters)
        this.$set(this.selectedSearchDef.defs, "filters", []);

      this.selectedSearchDef.defs.filters.push(
        JSON.parse(JSON.stringify(this.newFilter))
      );
      this.cancelFilterCreation();
    },

    cancelSearchDefCreation() {
      this.createSearchDefDialog = false;
      this.newSearchDefDescription = "";
    },

    deleteFilter(index) {
      this.selectedSearchDef.defs.filters.splice(index, 1);
    },

    async confirmCreateSearchDef() {
      if (!this.$refs.searchDefCreationForm.validate()) return;
      const userData = await this.$auth.getUserParams();
      const searchDef = {
        schemaName: this.schemaName,
        userId: userData.userId,
        description: this.newSearchDefDescription,
        def: {
          userColumns: [this.schema.descriptor],
          orderBy: [],
          filters: [],
        },
      };
      this.createSearchDef(searchDef);
      // this.selectedSearchDef = this.searchDefs[this.searchDefs.length - 1];
      this.cancelSearchDefCreation();
    },

    requestDeleteSearchDef() {
      this.deleteSearchDef(this.selectedSearchDef?.id);
      this.$set(this, "selectedSearchDef", null);
    },

    async saveSearchDef() {
      if (!this.$refs.editSearchDefForm?.validate()) return;

      const searchDef = JSON.parse(JSON.stringify(this.selectedSearchDef));
      searchDef?.defs?.orderBy?.forEach((orderBy) => {
        delete orderBy?.text;
      });
      await this.updateSearchDef(searchDef);
      this.saved = true;
      this.dialogFilterAvenced = false;
      this.getItems();
    },

    async searchFirstOne() {
      const userDate = await this.$auth.getUserParams();
      this.selectedSearchDef.userId = userDate.userId;
      this.saved = true;
      this.dialogFilterAvenced = false;
      this.advancedSearchMode = true;
      await this._getItemsSearchDef();
    },

    getOperatorSymbol(id) {
      return FILTER_OPERATORS[id]?.symbol;
    },
    getOperatorLabel(id) {
      return FILTER_OPERATORS[id]?.label;
    },
    exportAsExcelFile() {
      const columns = this.userHeaders.map((header) => {
        return {
          label: header.text,
          value: header.value,
        };
      });
      const content = this.filteredItems;
      console.log("content", content);

      const data = [
        {
          sheet: "Planilha",
          columns,
          content,
        },
      ];

      const options = {
        fileName: "SimjobExport",
      };

      xlsx(data, options);
    },
  },
};
</script>

<style>
td.text-start {
  max-width: 70%;
  padding: 1em;
  margin-bottom: 0.4em;
  /*white-space: nowrap;*/

  margin: 0 auto;
  height: 400px;
  overflow-y: auto;
  overflow-x: auto;
}

.header-table thead tr {
  position: sticky;
  top: 0;
  right: 0;
  background-color: #fff;
}

.element .v-data-table__wrapper::-webkit-scrollbar {
  width: 24px;
  height: 8px;
  background-color: #e3ebf5;
}

.element .v-data-table__wrapper {
  overflow-x: visible;
  overflow-y: visible;
}

.list-container {
  display: flex;
}

.info-container {
  display: flex;
  margin: 5px;
  flex-direction: column;
}

.info-container p {
  margin: 0;
}

.info-container span {
  font-size: small;
  color: cornflowerblue;
}

.list-item {
  border-bottom: 1px solid rgba(172, 168, 168, 0.719);
}

.content-list {
  display: flex;
  justify-content: space-between;
  width: 390px;
  align-items: center;
}

@media (max-width: 524px) {
  .content-list {
    display: flex;
    justify-content: space-between;
    width: 200px;
    align-items: center;
  }
}

.color-table {
    width: 80px;
    height: 29px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 25px;
}

</style>