<template>
  <v-dialog
    @click:outside="$emit('cancel')"
    v-model="dialog"
    persistent
    scrollable
    content-class="my-custom-dialog"
  >
    <v-card>
      <v-app-bar flat color="transparent">
        <v-btn large icon @click="$emit('cancel')">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Permissões de acesso</v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- <v-btn large icon color="error" @click="$emit('delete')">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->

        <v-btn large icon color="success" @click="submitForm">
          <v-icon>mdi-check</v-icon>
        </v-btn>

        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" large icon color="primary">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list v-if="user">
            <MenuUser :userId="user.id" />
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-form ref="permissionForm" class="card">
        <ExpansionSchema
          @change="handleChange"
          @handleDashboard="handleDashboard"
          :group_id="group_id"
          :group_name="group_name"
          :all_schemas="schemas"
          :user_id="user_id"
          :checkbox="checkbox"
        />
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import ExpansionSchema from "../expansionPanel/expansionSchema.vue";
import schemaService from "../../services/schemaService";
import MenuUser from "../expansionPanel/menuUser.vue";
import EntityService from '../../services/entityService';
export default {
  components: { ExpansionSchema, MenuUser },
  props: ["value", "user", "group_id", "group_name"],
  methods: {
    async getAllSchemas() {
      let res = await schemaService.getAll();
      if (res.data.success) {
        this.schemas = res.data.data.data;
      }
    },
    async submitForm() {
      if (Object.keys(this.all_permissions).length > 0) {
        if(this.user) {
          await this.$acessGroup.insertPermissions(this.all_permissions);
        } else {
          await this.$acessGroup.insetGroupPermission(this.all_permissions, this.group_id);
        }
        //window.location.reload();
      }

      if(this.all_dashboard.length > 0){

        for(let i = 0; i < this.all_dashboard.length; i++){
          await this.entityService.insertOrUpdate(this.all_dashboard[i]);
        }
        
      }

      window.location.reload();

      this.$emit("save");
      this.$refs.permissionForm.$el.submit();
    },
    handleChange(e) {
      this.all_permissions = e;
    },

    handleDashboard(e){

      if(this.all_dashboard.length > 0){

        let index = this.all_dashboard.findIndex(resp => resp.id == e.id);

        if(index >= 0){
          this.all_dashboard[index] = e
        } else {
          this.all_dashboard.push(e)
        }
      } else {
        this.all_dashboard.push(e)
      }

      
    }
  },
  created() {
    this.getAllSchemas();
  },
  watch: {
    value(v) {
      this.dialog = v;
      if (v) {
        this.user_id = this.user?.id;
      }
    },
/*
    user(user){
      console.log(user)
    }*/
  },
  data() {
    return {
      userIds: '',
      permissions: ["Ler", "Escrever", "Editar", "Excluir"],
      user_id: [],
      schemas: [],
      dialog: false,
      checkbox: [],
      all_permissions: {},
      all_dashboard: [],
      entityService: new EntityService('Dashboard'),
    };
  },

  mounted(){
  }
};
</script>
<style scoped>
.v-toolbar {
  flex: none;
}

.v-card > *:last-child:not(.v-btn):not(.v-chip) {
    border-bottom-left-radius: inherit;
    padding: 12px;
    border-bottom-right-radius: inherit;
    background-color: white;
    overflow-x: hidden;
}

</style>