import axios from "axios";
import Vue from "vue";
//import https from "https";

export default {
  async getAuthorizationHeaders() {
    let token = await Vue.prototype.$auth.getUserToken();
    return { Authorization: `bearer ${token}` };
  },

  getErrorsToStringInResponseApi(errorResponse) {
    if (
      errorResponse.response &&
      errorResponse.response.data &&
      errorResponse.response.data.success == false
    ) {
      let errors = errorResponse.response.data.data.map((e) => {
        return e.value;
      });

      let errorString = "";
      errors.forEach((error) => {
        errorString = errorString + error + "\n";
      });

      return errorString;
    }
  },

  createHttp() {
    let http = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: Number.MAX_SAFE_INTEGER,
      validateStatus: (status) => {
        if (status === 401 || status === 403) {
            Vue.prototype.$auth.removeUserParams();
            var url = location.href.split('/#/')
            location.href = url[0] + '/#/'
        }
        return status >= 200 && status < 300;
      }
    });

    http.interceptors.request.use(async (request) => {
      Vue.prototype.$loading(true);
      let token = await Vue.prototype.$auth.getUserToken();
      request.headers.Authorization = `Bearer ${token}`;

      return request;
    });

    http.interceptors.response.use(
      (response) => {
        Vue.prototype.$loading(false);
        return response;
      },
      (error) => {
//        console.error(error);
        let errorString = this.getErrorsToStringInResponseApi(error);
        if (errorString) Vue.prototype.$notfy.error(errorString);
        else Vue.prototype.$notfy.error(error);
        Vue.prototype.$loading(false);
        return error.response.data;
      }
    );

    return http;
  },
};
