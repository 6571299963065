<template>
	<div :key="key">
		<v-runtime-template :template="result.view" style="overflow-x: scroll !important;">
		</v-runtime-template>
		<template v-if="result.model === 'chart'">
			<ActionChartJs @close="close" :result="result"></ActionChartJs>
		</template>
		<template v-else-if="result.model === 'pivoTable'">
			<ActionPivoTable :result="result" />
		</template>

		<!-- <template v-if="openCamera">
			<p v-if="error !== ''">{{  error  }}</p>
			<qrcode-stream @init="onInit"  @decode="onDecode" ref="qrcodeStream">
			</qrcode-stream>
			<div v-if="decodedContent">Conteúdo decodificado: {{ decodedContent }}</div>
		</template> -->

		<dialog-qr-code-render
			:open="openCamera"
			@decode="onDecode"
			@cancel="calcelQrCode"
		></dialog-qr-code-render>
	</div>
</template>

<script>
	import EntityService from "../../services/entityService"
	import ActionChartJs from './actionChartJs.vue'
	import ActionPivoTable from './actionPivoTable.vue'
	import xlsx from "json-as-xlsx";
	import VRuntimeTemplate from "v-runtime-template";
	import * as VuetifyComponents from "vuetify/lib/components";
	import dialogQrCodeRender from './dialogQrCodeRender.vue'
	//import { QrcodeStream } from 'vue-qrcode-reader';

	export default {
		name: "ActionResultCustom",

		props: {
			result: Object,
		},

		components: {
			//QrcodeStream,
			dialogQrCodeRender,
			ActionPivoTable,
			ActionChartJs,
			VRuntimeTemplate,
			...VuetifyComponents,
		},

		data() {
			return {
				...this.result?.data,
				headers: [],
				itemsData: [],
				count: 0,
				decodedContent: '',
				componentMoutend: false,
				openCamera: false,
				videoElement: null,
				error: '',
				field: '',
				key: 0
			};
		},

		computed: {
			items() {
				if (!this.result?.data) return [];
				else return this.result?.data;
			},
		},

		methods: {
			ExportAsExcelFile() {
				const columns = this.result.data.headers.map((header) => {
					return {
						label: header.text,
						value: header.value,
					};
				});
				const content = this.items.results;
				const data = [
					{
						sheet: "Planilha",
						columns,
						content,
					},
				];

				const options = {
					fileName: "SimjobExport",
				};

				try {
					xlsx(data, options);
				} catch (err) {
					this.result.view = `
					<v-alert type="error">
            ${err}
          </v-alert>`;
				}
			},

			onDetect (detectedCodes) {
				console.log("detectedCodes >>> ", detectedCodes)
			},
			
			onDecode(decodedString) {
				this.payload[this.field] = decodedString
				this.decodedContent = decodedString;
				this.openCamera = false
			},

			async onInit(promise) {
				try {
					await promise
				} catch (error) {
					if (error.name === 'NotAllowedError') {
						this.error = "ERROR: you need to grant camera access permisson"
					} else if (error.name === 'NotFoundError') {
						this.error = "ERROR: no camera on this device"
					} else if (error.name === 'NotSupportedError') {
						this.error = "ERROR: secure context required (HTTPS, localhost)"
					} else if (error.name === 'NotReadableError') {
						this.error = "ERROR: is the camera already in use?"
					} else if (error.name === 'OverconstrainedError') {
						this.error = "ERROR: installed cameras are not suitable"
					} else if (error.name === 'StreamApiNotSupportedError') {
						this.error = "ERROR: Stream API is not supported in this browser"
					}
				}
			},

			openQrCodeModal(field){
				console.log("FIELD >>> ", field)
				this.field = field
				this.openCamera = true
			},

			calcelQrCode(){
				this.openCamera = false
				this.key += 1
			},

			close() {
				this.$emit("close");
			},
			runAction(identifier, params) {
				this.$emit("run-action", identifier, params);
			},
/*
			async searchEntity(search, schemaName){

				if(search !== null){
					console.log('search ', search, schemaName)
					
					console.log('typeOperators ', this.typeOperators)
					/*
					let entityService = new EntityService(schemaName)
					let res = await entityService.searchAutoComplete('description', search);
					console.log(res)
					this.typeOperators = res.data.data.data
					this.search = search
					console.log('search ', this.typeOperators)
					this.count += 1
				}

			},*/

			async createEntity(schemaName, obj){
				if(schemaName && obj){
					let entityService = new EntityService(schemaName)
					let data = await entityService.autoInc(schemaName, "Code", "######")
					obj.code = data.data
					console.log('obj ', obj)
					await entityService.insertOrUpdate(obj, null)

					this.$emit("close");
				}
			},

			async deleteEntity(schemaName, id){
				let entityService = new EntityService(schemaName)
				if(id){
					await entityService.delete(id)
					this.$emit("close");
				} 
			},

			async editEntity(schemaName, obj){
				if(schemaName && obj.id){
					let entityService = new EntityService(schemaName)
					await entityService.insertOrUpdate(obj, null)

					this.$emit("close");
				}
			},

			async getAllEntity(schemaName, obj){
				this.headers = []
				this.itemsData = []

				let entityService = new EntityService(schemaName)
				let data = await entityService.getAll(1, 30, '', '', [], null)

				Object.entries(obj).map(item => {
					this.headers.push({ text: item[0], value: item[0] })
				})

				this.itemsData = data.data.data.data
				console.log('items ', this.itemsData)

				this.result.view =
				`<div>
					<v-data-table
					    :headers="headers"
						:items="itemsData"
						class="elevation-1"
						disable-sort
						hide-default-footer
						:single-expand="false"
					>
					</v-data-table>
				</div>`
			}
		},

		mounted() {
			this.$nextTick(() => {
				const qrcodeStream = this.$refs.qrcodeStream;
				if (qrcodeStream && qrcodeStream.videoElement) {
					console.log('Vídeo está disponível.');
				} else {
					console.error('Vídeo não disponível.');
				}
			});
		},
	};
</script>

<style></style>
