<template>

<div class="text-h4 mb-10">
      <div>Alterar senha</div>
      <UpdatePasswordForm />
</div>

</template>

<script>
import UpdatePasswordForm from "../../components/form/updatePasswordForm.vue";
export default {
  components: { UpdatePasswordForm },
  name: "UpdatePasswordPage",
};
</script>
