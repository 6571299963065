<template>
	<v-runtime-template :template="result.view"></v-runtime-template>
</template>

<script>
	//import xlsx from "json-as-xlsx";
	import VRuntimeTemplate from "v-runtime-template";
	import * as VuetifyComponents from "vuetify/lib/components";

	export default {
		name: "ActionExcel",

		props: {
			result: Object,
		},

		components: {
			VRuntimeTemplate,
			...VuetifyComponents,
		},

		computed: {
			items() {
				if (!this.result?.data) return [];
				else return this.result?.data;
			},
		},

		methods: {
			async downloadCSV(data) {
				const blob = new Blob(["\ufeff", data], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.setAttribute("hidden", "");
				a.setAttribute("href", url);
				a.setAttribute("download", "download.csv");
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			},

			async objectToCsv(data) {
				console.log(data.data[0]);
				const csvRows = [];
				const headers = Object.keys(data.data[0]);

				console.log(headers);
				csvRows.push(headers.join(","));

				for (const row of data.data) {
					const values = headers.map((header) => {
						const escaped = ("" + row[header]).replace(/"/g, '\\"');
						return `"${escaped}"`;
					});

					csvRows.push(values.join(","));
				}

				return csvRows.join("\n");
			},

			async exportCSV(data) {
				const csv = await this.objectToCsv(data);
				this.downloadCSV(csv);
			},
			/*ExportAsExcelFile() {
                const columns = this.result.data.headers.map((header) => {
                    return {
                        label: header.text,
                        value: header.value,
                    };
                });
                const content = this.items.results;
                const data = [
                    {
                        sheet: "Planilha",
                        columns,
                        content,
                    },
                ];

                const options = {
                    fileName: "SimjobExport",
                };

                try {
                    xlsx(data, options);
                    this.$emit("close");
                } catch (err) {
                    this.result.view = 
                    <v-alert type="error">
            ${err}
          </v-alert>;
                }
            },*/

			close() {
				this.$emit("close");
			},
		},

		created: function () {
			this.exportCSV(this.result.data);
			//this.ExportAsExcelFile();
		},

		mounted() {},
	};
</script>
