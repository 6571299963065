<template>
    <div v-if="auth == false">
        <CardConfirmAuth />
    </div>

    <div v-else>
        <CardUserAuth />
    </div>

</template>


<script>
import CardConfirmAuth from '../../components/card/CardConfirmAuth.vue';
import CardUserAuth from '../../components/card/CardUserAuth.vue';

export default {
    name: "ConfirmAuthPage",

    data() {
        return {
            auth: false
        }
    },

    methods: {

        async verifyAuth(){
            const token = this.$cookies.get("hashToken");
            const data = await this.$auth.getUserParams()
            const a2f = data.a2f
            console.log(a2f)
            if(a2f == 0 && token != null){
                this.auth = true
            }
        },
    },

    mounted() {
       this.verifyAuth();
    },

    components: {
        CardConfirmAuth,
        CardUserAuth,
    }
}

</script>
