<template>

    <v-dialog v-model="dialog" width="500" :key="key">
        <v-card>
            <qrcode-stream @init="onInit" @decode="onDecode" ref="qrcodeStream">
            </qrcode-stream>
            <div v-if="decodedContent">
                <h3>Código: {{ decodedContent }}</h3>
            </div>

            <v-card-actions @click="$emit('decode', decodedContent)">
                <v-btn color="primary">
                    Confirmar
                </v-btn>

                <v-btn color="cancel" @click="$emit('cancel')">
                    Cancelar
                </v-btn>
            </v-card-actions>

        </v-card>

    </v-dialog>

</template>

<script>

import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    components: {
        QrcodeStream
    },
    props: ['open'],
    data(){
        return {
            dialog: false,
            decodedContent: '',
            error: '',
            key: 0
        }
    },

    watch: {
        open(value){
            this.key += 1
            this.decodedContent = ''
            this.dialog = value
        }
    },

    methods: {
        onDetect (detectedCodes) {
				console.log("detectedCodes >>> ", detectedCodes)
			},
			
			onDecode(decodedString) {
                console.log("decodedString >>> ", decodedString)
                this.decodedContent = decodedString
				this.$emit('decode', decodedString);
			},

			async onInit(promise) {
				try {
					await promise
				} catch (error) {
					if (error.name === 'NotAllowedError') {
						this.error = "ERROR: you need to grant camera access permisson"
					} else if (error.name === 'NotFoundError') {
						this.error = "ERROR: no camera on this device"
					} else if (error.name === 'NotSupportedError') {
						this.error = "ERROR: secure context required (HTTPS, localhost)"
					} else if (error.name === 'NotReadableError') {
						this.error = "ERROR: is the camera already in use?"
					} else if (error.name === 'OverconstrainedError') {
						this.error = "ERROR: installed cameras are not suitable"
					} else if (error.name === 'StreamApiNotSupportedError') {
						this.error = "ERROR: Stream API is not supported in this browser"
					}
				}
			},
    }
}
</script>

<style>

</style>