<template>
  <div>
    <v-autocomplete
      :loading="loading"
      class="auto-complete"
      v-model="select"
      :items="userList"
      chips
      outlined
      :label="isOwner == true ? 'Owner' : 'Usuários'"
      item-text="name"
      return-object
      multiple
      :readonly="readonly"
      @input="inputOwner"
      @update:search-input="search"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
        >
          <v-avatar
            class="accent white--text"
            left
            v-text="data.item.name.slice(0, 1).toUpperCase()"
          ></v-avatar>
          {{ data.item.name }}
        </v-chip>
      </template>

      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>

        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import getUsersMixin from "@/mixins/getUsersMixin";
export default {
  props: ["value", "userCad", "isOwner", "userList", "readonly"],
  mixins: [getUsersMixin],
  data() {
    return {
      select: [],
      //userList: [],
      dialog: false,
      key: 0,
      loading: true
    };
  },

  watch: {
    value() {
      this.select = []

    },
  },

  async created() {
    //await this.getUsers();
    this.select = []
    if (this.isOwner) {
      if (this.userCad != undefined) {

        this.select = await this.userCad.map(user => {

          let objUser = {
            id: user.userId,
            name: user.userName
          }

          return objUser
        })
      }
      
      let resp = await this.$acessGroup.getUsersByTenanty(1, 1, 'admin', null)

      let userAdmin = {
        id: resp.data.data[0].id,
        name: resp.data.data[0].name
      }

      if (this.select.filter(user => user.id == userAdmin.id).length === 0) {
          this.select.push(userAdmin);
        }

      const data = await this.$auth.getUserParams();

      let objUser = {
        id: data.userId,
        name: data.user
      }

      if (this.select.filter(user => user.id == objUser.id).length === 0) {
        this.select.push(objUser);
        //this.$emit("input", this.select);
      }

      this.$emit("input", this.select);

      this.loading = false

    }
  },

  methods: {
    search(value) {
      setTimeout(() => {
        this.$emit("search", value);
      }, 2000);
    },

    inputOwner(){

      if(this.isOwner){

        for(let i = 0; i < this.select.length; i++){
          delete this.select[i].email
        }
      }

      this.$emit('input', this.select)
    }

  },
};
</script>

<style scoped>
.v-dialog {
  margin: 20% !important;
}

.auto-complete {
  min-height: 50px;
}
</style>