<template>
<div>
  <v-data-table
    style="cursor: pointer;"
    :headers="headers"
    :items="userAccess"
    :page.sync="page"
    :items-per-page="itemsPerPage"
    class="elevation-1"
    hide-default-footer
    @page-count="pageCount = $event"
    @click:row="rowClick"
  ></v-data-table>

    <div class="text-center pt-2 pagination">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        @input="getUserAccess"
      ></v-pagination>
    </div>
</div>
</template>

<script>
  export default {
    data () {
      return {
        page: 1,
        pageCount: 0,
        itemsPerPage: 0,
        userAccess: [],
        headers: [
         /* {
            text: 'Nome',
            align: 'start',
            sortable: false,
            value: 'userId',
          },*/
          { text: 'E-mail', value: 'userName' },
          { text: 'Schem Alterado', value: 'schemaName' },
        ],
      }
    },

    async created(){
      await this.getUserAccess()
    },


    methods: {

      async rowClick(item){
        const getRecord = await this.$access.getRecordId(item.schemaRecordId)
        console.log('getRecord ', getRecord)
      },

      async getUser(userId){
        const users = await this.$auth.findById(userId)
        return users.data.data
      },

      async getUserAccess(){
        var getAccess = await this.$access.getAllGroupAccess(this.page);
        this.userAccess = getAccess.data.data.data
        //console.log('userAccess ', getAccess.data.data)
        
        this.itemsPerPage = getAccess.data.data.limit;
        this.pageCount = getAccess.data.data.pages;
/*
        this.userAccess.map(async (resp) => {
          const user = await this.getUser(resp.userId)
          console.log('tenanty ', user.tenanty)
          if(user.tenanty == 'deltaducon'){

          }
          //resp.userId = user
        })*/
      }
    }
  }
</script>

<style scoped>
.pagination {

}
</style>