import Vue from "vue";  

export default {

    async viewRun(viewId, page, params){
        return await Vue.prototype.$http.post('/View/run', { params: params, viewId: viewId, page: page, limit: 3000000 });
    },

    async viewSearch(field, value){
        return await Vue.prototype.$http.get('/View/search-fields', { 
            params: {
                fields: field, 
                search: value
            }
        })
    }

}