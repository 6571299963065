<template>
  <v-expansion-panels focusable class="expansion">
    <v-col md="5">
        <v-text-field v-if="group_name"  label="Nome do Grupo" outlined v-model="groupName"></v-text-field>
        <PesquisaSchema @search="search" :userId="user_id" />
     <!--   <MenuUser :userId="user_id" /> -->
      </v-col>
    <v-expansion-panel
      v-for="(schema, schema_index) in all_schemas_permissions"
      :key="schema.id"
    >
    <v-expansion-panel-header v-if="schemaPesquisa.indexOf(schema) != -1">
        {{ schema.description }}
      <v-col md="5" sm="2">
        <label>
        <v-simple-checkbox
        :ripple="false"
        :v-model="checkedTrue(schema)"
          @input="
          onChangeCheckboxAll({
            value: checkedTrue(schema),
            schema_id: schema.id,
            schema_index,
            })
            "
            color="blue"
            :value="checkedTrue(schema)"
            hide-details
        >
        </v-simple-checkbox>
        </label> 
      </v-col>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card flat>
          <v-card-text>
            <v-sheet elevation="6">
              <v-tabs
                v-model="tab"
                background-color="cyan"
                dark
                next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline"
                show-arrows
              >
              <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab
                  v-for="(i, index) in items"
                  :key="index"
                >
                  {{ i }}
                 </v-tab>
                </v-tabs>
              </v-sheet>
              <v-tabs-items v-model="tab"
                 
                  >
            <v-tab-item>
            <h3>Permissões</h3>
            <v-container fluid>
              <v-row>
                <v-col
                  v-for="(permission, index) in schema.permissions"
                  :key="index"
                  cols="12"
                  sm="4"
                  md="4"
                >
                <div>
                  <v-label>
                    <v-simple-checkbox
                    v-if="permission.readyonly == false"
                    :ripple="false"
                      @input="
                        onChangeCheckbox({
                          label: permission.label,
                          value: !permission.value,
                          schema_id: schema.id,
                          schema_index,
                          index,
                        })
                      "
                      :disabled="permission.readyonly"
                      color="blue"
                      :value="permission.value"
                      hide-details
                    ></v-simple-checkbox>
                  
                  <p v-if="permission.readyonly == false">{{ permission.label }}</p>
                  </v-label>
                  </div>
                </v-col>

                <h3 id="action-text">Action de Menu</h3>
                <v-row style="margin-bottom: 10px; border-bottom: 1px solid;">
                <v-col cols="6" sm="4" md="4"
                  v-for="(action, index) in actionList[schema.name]" :key="index"><!--
                  <v-tooltip left color="success">
                    <template v-slot:activator="{ on }">--->
                  <v-label v-if="action.callType === 'Separate'">
                    <input
                      @input="addAction({ actionName: action.name, permissions: true, actionId: action.id }, schema.name)"
                      type="checkbox"
                      id="checkbox"
                      color="primary"
                      :key="count"
                      :checked="checkAction(action.id)"
                     
                    >
                <p>{{ action.name }}</p>

                </v-label><!--
                  </template>
                    <h3 v-if="action.callType == 'Separate'" style="font-size: 15px;">Action de menu</h3>
                    <h3 v-else style="font-size: 15px; font-weight: bold;">Action de schema</h3>
                </v-tooltip>--->
                </v-col>
              </v-row>
              </v-row>

              <v-row style="margin-bottom: 10px; border-bottom: 1px solid;">
                <div>
                  <span id="action-text">Action de Schema</span>
                </div>
                <v-col style="margin-left: -10px;" cols="6" sm="4" md="4" v-for="(action, index) in actionList2[schema.name]" :key="index">
                  <label v-if="action.callType === 'Schema'">
                    <input
                      @input="addAction({ actionName: action.name, permissions: true, actionId: action.id }, schema.name)"
                      type="checkbox"
                      id="checkbox"
                      color="primary"
                      :key="count"
                      :checked="checkAction(action.id)"
                    >
                <p>{{ action.name }}</p>
                  </label>
                </v-col>
              </v-row>

              <v-row v-if="schema.name == 'Dashboard'">
                <div>
                  <span id="action-text">Dashboards</span>
                </div>
                <dashboard-check
                :userId="user_id"
                :groupId="group_id"
                @permissionDashboard="emitPermissionDashboard"
                >
                </dashboard-check>
              </v-row>

            </v-container>
           </v-tab-item>

           <v-tab-item :key="componentKey" v-if="schema.name !== 'Dashboard'">
              <SegmentationCheck 
             :all_schemas="all_schemas" :schemaName="schema.name" :active_permissions="active_permissions" @segmentations="objSegmentation" />
           </v-tab-item>
            
           </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import DashboardCheck from './dashboardCheck.vue';
//import MenuUser from "./menuUser.vue";
import PesquisaSchema from "./PesquisaSchema.vue"
import SegmentationCheck from "./segmentationCheck.vue";
export default {
  props: ["user_id", "all_schemas", "group_id", "group_name"],
  components: { PesquisaSchema, SegmentationCheck, DashboardCheck },
  data() {
    return {
      groupName: this.group_name,
      componentKey: 0,
      active_permissions: [],
      all_schemas_permissions: [],
      tab: null,
      schemaId: '',

      checked: false,

      actionsData:[],
      actionList: [],
      actionList2: [],

      actionSelect: [],
      actionChecked: false,

      actionsUser: [],
      schemaPesquisa: [],
      count: 0,
      items: ["Permissões/Actions", "Segmentações"],
      segmentation: [],
      schemaNameSeg: '',

    };
  },
  async mounted() {
    this.$nextTick(async function () {
      this.onCreate();
    });
  },

  methods: {


    emitPermissionDashboard(dashboard){
      this.$emit('handleDashboard', dashboard)
    },


      objSegmentation(seg, schemaName, campo, qtdCampo, campoIni){
        this.schemaNameSeg = schemaName
        
        if(this.segmentation[schemaName] === undefined){
          this.segmentation[schemaName] = []
        }

          if(seg){
            if(campoIni.indexOf(seg[0].field) == -1 && this.segmentation[schemaName]?.length < qtdCampo){
              this.segmentation[schemaName].push(seg[0])
            }
          }
          if( this.segmentation[schemaName].length == 0) { 
            this.segmentation[schemaName].push(seg[0])
          } 

          else {
            for(let i = 0; i < this.segmentation[schemaName].length; i++){
              if(this.segmentation[schemaName][i].field == campo && seg){
                this.segmentation[schemaName][i] = seg[0]
              }

              if(this.segmentation[schemaName][i].field == campo && seg === undefined){
                 this.segmentation[schemaName].splice(i,1)
                 break;
              }
            }
          }
        const newPermissions = {
          userID: this.user_id,
          groupId: this.group_id,
          groupName: this.groupName,
          schema: this.all_schemas_permissions.map((permission) => {
            return {
              schemaID: permission.id,
              schemaName: permission.name,
              permissions: permission.permissions
                .map((permissions) =>
                  permissions.value ? permissions.label : null
                )
                .filter((permissions) => permissions !== null),
                actions: this.actionSelect[permission.name],
                segmentations: this.segmentation[permission.name]
            };
          }),
        };
        this.$emit("change", newPermissions);
      },

        search(e){
          if(e.length > 0){
            this.schemaPesquisa = this.all_schemas_permissions.filter((item) => {
              return (
                item?.description.toLowerCase().indexOf(e.toLowerCase()) > -1
              );
            });
          } else {
            this.schemaPesquisa = this.all_schemas_permissions
            this.schemaPesquisa.sort(function (a, b) {
              if (a.description > b.description) {
                return 1;
              }
              if (a.description < b.description) {
                return -1;
              }
              return 0;
            });
          }
        },

        addAction(action, schemaName){
          if(this.actionSelect[schemaName] === undefined){
            this.actionSelect[schemaName] = []
          }

          const index = this.actionSelect[schemaName].filter(x => x.actionId == action.actionId)
          if(index.length > 0){
            this.actionSelect[schemaName] = this.actionSelect[schemaName].filter(x => x.actionId != action.actionId)
          } else {
            this.actionSelect[schemaName].push(action)
          }

          this.onChangeCheckboxAction()


        },

    async getActions(schemaName){

        const data = await this.$acessGroup.getActions(schemaName);
        data?.data?.data?.data.map(element => {
          if(element.callConfig.schemaName == schemaName){
            if(element.callType === "Separate"){
              this.actionList[schemaName].push(element)
              this.$forceUpdate()
            } else if(element.callType === "Schema"){
              this.actionList2[schemaName].push(element)
              this.$forceUpdate()
            }
            //this.$forceUpdate()
          }
        });
    },


    async onCreate() {

      if(this.user_id) {
        let data = await this.$acessGroup.findPermissionsByUserId(this.user_id);
        this.actionsData = data.data?.schemas
        this.active_permissions = await this.get_active_permissions(this.user_id);
      } else {
        let data = await this.$acessGroup.permissionGroupById(this.group_id);
        this.active_permissions = await this.get_active_group_permission(this.group_id);
        this.actionsData = data.data.data?.schema
      }

      this.all_schemas_permissions = this.get_all_schemas_permissions();      

      const segmentationsCad = this.actionsData?.filter(item => item.segmentations?.length > 0)
      segmentationsCad?.map((x) => {
        this.segmentation[x.schemaName] = []
        x.segmentations?.map((y) => {
           this.segmentation[x.schemaName].push(y)
        })
      })

      this.actionsUser = this.actionsData?.filter(item => item.actions?.length > 0)
      this.actionsUser?.map((x) => {
        this.actionSelect[x.schemaName] = []
        x.actions?.map((y) => {
          this.actionSelect[x.schemaName].push(y)
        })
        
      })


      this.all_schemas_permissions.map(async (schema) => {
        this.actionList[schema.name] = []
        this.actionList2[schema.name] = []
        this.getActions(schema.name)
      });
     
      this.all_schemas_permissions.sort(function (a, b) {
        if (a.description > b.description) {
          return 1;
        }
        if (a.description < b.description) {
          return -1;
        }
        return 0;
      });
      this.schemaPesquisa = this.all_schemas_permissions
      this.handleDefaultPermissions();
    },

    async onChangeCheckbox(permissionData) {
      let edit_schema =
        this.all_schemas_permissions[permissionData.schema_index];

      let edit_permission = edit_schema.permissions[permissionData.index];

      edit_permission.value = permissionData.value;

      edit_schema.permissions[permissionData.index] = edit_permission;

      this.all_schemas_permissions[permissionData.schema_index] = edit_schema;


      const newPermissions = {
        userID: this.user_id,
        groupId: this.group_id,
        groupName: this.groupName,
        schema: this.all_schemas_permissions.map((permission) => {
          return {
            schemaID: permission.id,
            schemaName: permission.name,
            permissions: permission.permissions
              .map((permissions) =>
                permissions.value ? permissions.label : null
              )
              .filter((permissions) => permissions !== null),
              actions: this.actionSelect[permission.name] || [],
              segmentations: this.segmentation[permission.name]
          };
        }),
        
      };

      //console.log(this.actionSelect)
      //console.log("newPermissions", newPermissions)
      this.$emit("change", newPermissions);
    },

    checkAction(actionId){
        if(this.actionsUser?.length > 0){
          const action = (this.actionsData.filter(item => item?.actions?.length > 0))//[0]?.actions.filter(item => item.actionId == actionId))?.length > 0 ? true : false
          for(var i = 0; i < action.length; i++){
            if(action[i].actions.filter(item => item.actionId == actionId)[0] != undefined){
              return true
            } else {
              //
            }
          }
          //return action

        } else {
          return false
        }
      },

    onChangeCheckboxAction(){

      const newPermissions = {
        userID: this.user_id,
        groupId: this.group_id,
        groupName: this.groupName,
        schema: this.all_schemas_permissions.map((permission) => {
          return {
            schemaID: permission.id,
            schemaName: permission.name,
            permissions: permission.permissions
              .map((permissions) =>
                permissions.value ? permissions.label : null
              )
              .filter((permissions) => permissions !== null),
              actions: this.actionSelect[permission.name] || [],
              segmentations: this.segmentation[permission.name]
          };
        }),
        
      };

      this.$emit("change", newPermissions);
    },

    handleDefaultPermissions(schema_id, label) {
      const filter = this.active_permissions.filter(
        (active) => active.id === schema_id
      );

      if (!filter[0]) return false;

      return filter[0].permissions?.some((p) => p === label);
    },
    async get_active_permissions(id) {
      const response = await this.$acessGroup.findPermissionsByUserId(id);
      if (response.success && response.data != null) {
        return response.data.schemas.map((schema) => ({
          name: schema.schemaName,
          permissions: schema.permissions,
          id: schema.id || schema.schemaID,
          actions: schema.actions,
          segmentations: schema?.segmentations
        }));
      } else {
        return [];
      } 
    },
    async get_active_group_permission(groupId){
      const response = await this.$acessGroup.permissionGroupById(groupId);
      if (response.data.success && response?.data?.data?.schema !== null) {
        return response.data.data.schema.map((schema) => ({
          name: schema.schemaName,
          permissions: schema.permissions,
          id: schema.id || schema.schemaID,
          actions: schema.actions,
          segmentations: schema?.segmentations
        }));
      } else {
        return [];
      } 
    },
    get_all_schemas_permissions() {
      return this.all_schemas.map((schema) => ({
        name: schema.name,
        description: JSON.parse(schema.jsonValue || {})?.description,
    //    description: schema.name,
        permissions: [
          {
            label: "Create",
            value: this.handleDefaultPermissions(schema.id, "Create"),
            readyonly: false
          },
          {
            label: "Read",
            value: this.handleDefaultPermissions(schema.id, "Read"),
            readyonly: false
          },
          {
            label: "Update",
            value: this.handleDefaultPermissions(schema.id, "Update"),
            readyonly: false
          },
          {
            label: "Delete",
            value: this.handleDefaultPermissions(schema.id, "Delete"),
            readyonly: false
          },
          {
            label: "Menu",
            value: this.handleDefaultPermissions(schema.id, "Menu"),
            readyonly: false
          },
          /*{ 
            label: "Owner", 
            value: this.handleDefaultPermissions(schema.id, "Owner")
          }*/
          JSON.parse(schema.jsonValue || {})?.owner_schema === true ? { label: "Owner", value: this.handleDefaultPermissions(schema.id, "Owner"), readyonly: false} 
          : { label: null, value: this.handleDefaultPermissions(schema.id, null), readyonly: true}
        
        ],
        id: schema.id,
        //owner_schema: JSON.parse(schema.jsonValue || {})?.owner_schema,
      }));
      
    },

    onChangeCheckboxAll(permissionData){
     let edit_schema =
        this.all_schemas_permissions[permissionData.schema_index];

    edit_schema.permissions.forEach(element => {
        element.value = !permissionData.value;
      });

      this.all_schemas_permissions[permissionData.schema_index] = edit_schema;

      const newPermissions = {
        userID: this.user_id,
        groupId: this.group_id,
        groupName: this.groupName,
        schema: this.all_schemas_permissions.map((permission) => {
          return {
            schemaID: permission.id,
            schemaName: permission.name,
            permissions: permission.permissions
              .map((permissions) =>
                permissions.value ? permissions.label : null
              )
              .filter((permissions) => permissions !== null),
              actions: this.actionSelect[permission.name],
              segmentations: this.segmentation[permission.name]
          };
        }),
      };

      //console.log("newPermissions", newPermissions)
      this.$emit("change", newPermissions);

    },

    checkedTrue(schema) {
      const checked = schema.permissions.filter(resp => resp.value == true && resp.readyonly != true).length;
      if(checked == 5){
        return true;
      } else if(checked == 6) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    user_id() {
      this.onCreate();
      this.componentKey += 1
      this.$forceUpdate();
    },

    group_id(){
      this.groupName = this.group_name
      this.onCreate();
      this.componentKey += 1
      this.$forceUpdate();
    }
  },
};
</script>

<style scoped>
.expansion {
  margin-top: 45px;
}

#action-text {
    margin-right: 50px;
    padding-right: 30cm;
    padding-bottom: 15px;
    font-size: 13px;
    font-weight: bold;
    white-space: nowrap;
}

#checkbox {
  padding: 15px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 100%;
    flex-grow: 1;
}

</style>
