<template>
  <v-dialog
    @click:outside="$emit('close')"
    v-model="dialog"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-app-bar flat color="transparent">
        <v-btn large icon @click="$emit('cancel')">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Adicionar o usuário '{{ name }}' em outro grupo ?</v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-btn large icon color="success" @click="updateGroup()">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container>
        <v-form ref="form">
        <!---
        <v-select
            v-model="select"
            :items="group"
            item-text="name"
            item-value="id"
            label="Grupos"
            dense
            outlined
          ></v-select> --->

        <v-autocomplete
            v-model="select"
            :items="group"
            :loading="isLoading"
            :search-input.sync="search"
            item-text="name"
            item-value="id"
            prepend-icon="mdi-database-search"
            label="Grupos"
            dense
            outlined
          ></v-autocomplete>
          
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
props: ["value", "user"],
data() {
    return {
        select: { name: this.$route.params.groupName, id: this.$route.params.id },
        dialog: false,
        groups: [],
        group: [],
        groupId: this.$route.params.id,

        name: this.user.name,
        email: this.user.email,
        telefone: this.user.telefone,

        isLoading: false,
        search: ''
    }
},
  watch: {
    value(v) {
      this.dialog = v;
      this.id = this.user.id;
      this.name = this.user.name;
      this.email = this.user.email;
      this.telefone = this.user.telefone;
      this.groupId = this.select;
    },

    search(){
        this.findGroups()
    }
  },

  methods: {
    async updateGroup() {
      const model = {
        userId: this.id,
        tenanty: this.getTenanty(),
        name: this.name,
        email: this.email,
        telefone: this.telefone,
        groupId: this.select.id || this.select,
      };

      const req = await this.$auth.updateUser(model);

      if (req.data.success == true) {
        this.$notfy.success("Usuário foi adicionado a outro grupo");
        this.dialog = false;
        //this.$emit("updated");
      }
      window.location.reload()
    },

    async findGroups() {
      let data = await this.$acessGroup.findAllGroup(this.search, 1, 3);
      this.groups = data.data.data.data;
      if (this.groups) {
        for (var i = 0; i < this.groups.length; i++) {
          this.group.push({
            name: this.groups[i].groupName,
            id: this.groups[i].id,
          });
        }
      }
    },

    getTenanty() {
      const lc = window.localStorage.getItem("user-login");
      const user = JSON.parse(lc);
      return user[0];
    }

  },

  mounted() {
      this.findGroups();
  }
}
</script>