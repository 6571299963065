<template>
<!--
<a href="#" @click="SignIn()" style="text-decoration: none; margin-bottom: 10px;">Sign In with a microsoft azure</a>--->

  
  <v-btn 
    @click="SignIn()"
    outline
    height="40px"
  >
    <v-icon color="primary" style="font-size: 30px">mdi-microsoft-azure</v-icon>
    
  </v-btn>

</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';

export default {
  data() {
    return {
      clientId: '914ae50f-6a4c-49fe-b8ab-22264cd9abff',
      redirectUri: null
    }
  },
  props: ['tenantyUser'],
  methods: {
    async SignIn() {
      if(this.tenantyUser != null){
        const config = {
            auth: {
                clientId: this.clientId,
                //authority: 'https://login.microsoftonline.com/organizations/',
                redirectUri: this.redirectUri
            }
        };

        try {
          var client = new PublicClientApplication(config);

          var loginRequest = {
              scopes: [ 'user.read' ]
          };

          let loginResponse = await client.loginPopup(loginRequest);
          console.log('Login Response', loginResponse);

          var tokenRequest = {
              scopes: [ 'user.read' ],
              account: loginResponse.account
          };
          let tokenResponse = await client.acquireTokenSilent(tokenRequest);
          console.log('Token Response', tokenResponse);


          let payload = await fetch("https://graph.microsoft.com/v1.0/me", {
              headers: {
                  'Authorization': 'Bearer ' + tokenResponse.accessToken
              }
          });

          let json = await payload.json();
          console.log('Graph Response', json);

          let data = {
            tenanty: this.tenantyUser.tenanty,
            token: loginResponse.accessToken,
            email: loginResponse.account.username
          }

          const req = await this.$authAzure.updateTokenAzure(data);

          if(req.status === 200){
            const reqToken = await this.$authAzure.tokenAzure(data);
            if(reqToken.data.success){
              let user_login = [];
              user_login = [this.tenantyUser.tenanty, ''];
              window.localStorage.setItem("user-login", JSON.stringify(user_login));

              sessionStorage.setItem('homeAccountId', tokenResponse.account.homeAccountId);
              await this.$auth.saveUserParams(reqToken.data.data);
              
              const data = await this.$auth.getUserParams();
              const flag = data.a2f;
              const firstLogin = data.firstLogin
              const token = this.$cookies.get("hashToken");

              const profile = await this.$profile.getProfile();
              if(profile.data.data != undefined){
                const nomeEmpresa = profile.data.data.nomeEmpresa;
                const color = profile.data.data.color;
                let imgLogo = profile.data.data.logo
                const logo = `${process.env.VUE_APP_IMG_URL}/${imgLogo}`;
                let dominio = profile.data.data.dominio;

                window.localStorage.setItem("color", color)
                window.localStorage.setItem("nomeEmpresa", nomeEmpresa)
                window.localStorage.setItem("dominio", dominio)
                window.localStorage.setItem("logo", logo)
              }

              if(firstLogin == true){
                  this.$router.push('/firstAccess')
                } else {
                  if (flag == 0) {
                    this.$router.push("home");
                  } else if (flag == 1 || token != null) {
                    let data = await this.$auth.TwoFactorAuth2fa(token);
                    console.log(data);
                    console.log(data.data.data.authenticated);
                    var auth = data.data.data.authenticated;
                    
                    if (auth == false) {
                      this.$router.push("confirmAuth");
                    } else {
                      this.$router.push("home");
                    }
                  } else {
                    this.$router.push('home')
                  }
              } 
            }
            //console.log(reqToken)
          } else {
            this.$notfy.error("Esse dominio de email não está autorizado")
            setTimeout(() => {
              this.logout(tokenResponse.account.homeAccountId)
            }, 2000)
            sessionStorage.clear();
          }

        } catch(err){
          console.log(err)
        }
      } else {
        this.$notfy.error("Tenanty não informado ou invalido")
      }
    },

    logout(homeAccountId){
      const config = {
          auth: {
              clientId: this.clientId,
              redirectUri: this.redirectUri, //defaults to application start page
              postLogoutRedirectUri: this.redirectUri
          }
      }

      const myMsal = new PublicClientApplication(config);

      console.log(homeAccountId)
      const logoutRequest = {
          account: myMsal.getAccountByHomeId(homeAccountId),
      }

      myMsal.logoutRedirect(logoutRequest);
    }
  },

  created() {
    const url = window.location.href.split("/#/")
    this.redirectUri = url[0]

  }
}

</script>