<template>
    <div class="form-container">
    <v-container>
      <v-form ref="form" @submit.prevent="UpdatePassword()">
        <v-row>
          <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="oldPassword"
                label="Senha antiga"
                type="password"
              ></v-text-field>

              <v-text-field
                v-model="password"
                label="Nova senha"
                type="password"
                :rules="requiredRule"
              ></v-text-field>
              <v-text-field
                v-model="confirmPassword"
                label="Confirmar nova senha"
                type="password"
              ></v-text-field>
              <v-btn class="mr-4" color="blue" type="submit"> Atualizar senha </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    </div>
</template>

<script>
export default {
    name: 'updatePasswordForm',

    data(){
        return {
            password: "",
            confirmPassword: "",
            oldPassword: "",
            valid: true,
            requiredRule: [v => !!v || "Campo obrigatório!", v => v.length >= 6 || 
          'Precisa ter no minimo 6 caracteries', v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'A senha deve conter pelo menos uma letra minúscula, um número, um caractere especial @%$ e uma letra maiúscula']
        }
    },

    methods: {
        validate() {
            return this.$refs.form.validate();
        },

        async UpdatePassword(){
            const user = await this.$auth.getUserParams();
            const userId = user.userId;
            if(this.validate()){
                if(this.password == this.confirmPassword){
                  const req = await this.$auth.updatePassword(userId, this.oldPassword, this.password);
                  this.$notfy.success("Senha alterada com sucesso");
                  location.reload();
                  console.log(req);
                } else {
                    this.$notfy.error("Senhas não conferem");
                }
            } else {
                this.$notfy.error("Algum campo não foi preenchido ou não foi preechido corretamente");
            }
        }
    }
}
</script>

<style scoped>
.form-container {
    margin-bottom: 25px;
}
</style>