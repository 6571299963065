<template>
  <div>
    <div v-show="msg != null">
      <Messsage :msg="msg" :color="color" />
    </div>
    <v-form ref="form" @submit.prevent="Update()">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="name"
              label="Name"
              :rules="codeRules"
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="E-mail"
              :readonly="true"
              :rules="emailRules"
            ></v-text-field>
            <v-text-field
              v-model="telefone"
              label="Telefone"
              :rules="codeRules"
              v-mask="'(##) #####-####'"
            ></v-text-field>
            <v-btn class="mr-4" color="blue" type="submit"> Atualizar </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import Messsage from "../message/Message.vue";

export default {
  name: "UpdateForm",
  components: {
    Messsage,
  },
  data() {
    return {
      name: "",
      email: "",
      telefone: "",
      tenanty: "",
      userId: "",
      componentKey: 0,
      msg: null,
      color: "blue",

      codeRules: [(v) => !!v || "Campo obrigatório!"],
      emailRules: [
        (v) => !!v || "Campo obrigatório!",
        (v) => /.+@.+\..+/.test(v) || "E-mail invalido",
      ],
    };
  },

  methods: {
    async dataUser() {
      const data = await this.$auth.getUserParams();

      this.userId = data.userId;
      this.name = data.user;
      this.telefone = data.telefone;
      this.email = data.username;
      this.tenanty = data.tenanty;
    },

    validate() {
      return this.$refs.form.validate();
    },

    async Update() {
      if (this.validate()) {
        const model = {
          userId: this.userId,
          tenanty: this.tenanty,
          name: this.name,
          telefone: this.telefone,
          email: this.email,
        };

        const req = await this.$auth.updateUser(model);

        if (req.status == 200) {
          this.$notfy.success("Atualização realizada com sucesso.");
          this.updateIndexDb();
        }
        this.$forceUpdate();
      }
    },

    async updateIndexDb() {
      const data = await this.$auth.getUserParams();
      data.userId = this.userId;
      data.user = this.name;
      data.telefone = this.telefone;
      data.username = this.email;
      data.tenanty = this.tenanty;
      await this.$auth.saveUserParams(data);
    },
  },

  mounted() {
    this.dataUser();
  },
};
</script>
