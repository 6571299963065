<template>
  <div :key="componentKey">
      <vue-pivottable-ui
        v-model="pivoTable"
        :data="getItems"
        :rows="rows"
        :cols="cols"
        :row-total="false"
        :column-total="false"
        :locales="locales"
        :locale="locale"
      >
      </vue-pivottable-ui>
  </div>
</template>

<script>
import { VuePivottableUi, PivotUtilities } from 'vue-pivottable';
export default {
    name: 'ActionPivoTable',
    props: ['result', 'rows', 'cols'],
    watch: {

        pivoTable: {

            handler(value){
                this.$emit("rows", value)
            },
            deep: true
        },

        rows(value){
            this.$emit("newsRows", value)
            this.componentKey += 1
        },

        cols(value){
            this.$emit("newsCols", value)
            this.componentKey += 1   
        }

    },
    data(){
        return {
            componentKey: 0,
            pivoTable: null,
            tableHeader: ['createAt', 'updateAt', 'id', 'createBy', 'updateBy', 'deleteAt', 'deleteBy', 'isDeleted', 'owners'],
            locale: "ptBr",
            locales: {
                //en: PivotUtilities.locales.en,
                ptBr: {
                aggregators: PivotUtilities.aggregators,
                localeStrings: {
                        renderError:
                        "Ocorreu um erro ao renderizar os resultados da tabela dinâmica.",
                        computeError:
                        "Ocorreu um erro ao calcular os resultados da tabela dinâmica.",
                        uiRenderError:
                        "Ocorreu um erro ao renderizar a IU da tabela dinâmica",
                        selectAll: "Selecionar Tudo",
                        selectNone: "Selecione nenhum",
                        tooMany: "(muitos para listar)",
                        filterResults: "Filtrar valores",
                        totals: "Totais",
                        only: "",
                        vs: "vs",
                        by: "by",
                    },
                }
            }
        }
    },
    components: {
        VuePivottableUi
    },

    computed: {
        getItems(){
           /* let data = this.result.data.map((resp) => {
                for(let i = 0; i < this.tableHeader.length; i++){
                    delete resp[this.tableHeader[i]];
                }

                return resp
            });

            return data;*/

            console.log('RESULT ', this.result)

            return  this.result.data
        }
    },

    methods: {
    },

    created(){
    }
}
</script>

<style>
table.pvtUi {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-weight: 400;
  vertical-align: top;
  border: 1px solid #6e6b7b;
  color: #6e6b7b;
}
table.pvtUi td {
  padding: 0.75rem;
  overflow-x: auto;
}

.pvtAxisContainer li {
    margin-bottom: 5px;
    padding: 1px 3px;
    list-style-type: none;
    cursor: move;
}
.pvtUi .pvtDropdown {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    background-color: inherit;
    background-position: right;
    background-size: 18px 14px,18px 14px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    height: 2rem;
    vertical-align: middle;
    color: #6e6b7b;
    width: 100%;
    padding: 0.438rem 2rem 0.438rem 1rem;
}
table.pvtUi>tbody td:first-child {
    width: 20%;
}
table.pvtTable tbody tr td, table.pvtTable tbody tr th, table.pvtTable thead tr th {
    border: 1px solid #dcdcdc;
    padding: 1.3rem 0.5rem;
    background-color: #fff;
    font-size: 14px;
    text-wrap: nowrap;
    text-align: center;
}
table.pvtTable thead tr th {
    vertical-align: top;
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: .5px;
    background-color: #ffffff;
}
th.pvtRowLabel {
    font-weight: 100;
    color: #000;
}
.pvtAxisContainer li span.pvtAttr {
    box-shadow: none;
    font-weight: 100;
    padding: 0.3rem 1rem;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    background-color: transparent;
    border: 1px solid #d8d6de;
    border-radius: 0.25rem;
    color: #373737;
    font-size: 13px;
}
.pvtTriangle {
    cursor: pointer;
    font-size: 18px;
    color: #d8d6de;
}


</style>