import RepoService from "./repoService";

class SchemaService extends RepoService {
  constructor() {
    super("schema");
  }

  async getByName(schemaName) {
    return await this.http.get(`${this.routeApi}/${schemaName}`);
  }
  async getSchemaByName(schemaName){
    try {
      let res = await this?.getByName(schemaName);
      if (res.data?.success) {
        return res.data.data;
      }
    } catch(err){
      //console.log(err)
    }
  }
  async getByNameToJS(schemaName) {
    try {
      let res = await this?.getByName(schemaName);
      if (res.data?.success) {
        return JSON.parse(res.data.data?.jsonValue);
      }
    } catch(err){
      //console.log(err)
    }

  }
  
  async getAll(){
    return await this.http.get('/Schema');
  }
}

export default new SchemaService();
