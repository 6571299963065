import Vue from "vue";

async function getSearchDefsForSchema (schemaName) {
  const response = await Vue.prototype.$http.get('/Searchdefs/getAll')
  if (!response?.data?.success) {
    throw new Error("Unable to get search defs for schema " + schemaName)
  }

  if (!response?.data?.data?.length) return []
  else return response.data.data.filter(searchDef => searchDef.schemaName === schemaName)
}

async function createSearchDef (searchDef) {
  const response = await Vue.prototype.$http.post('/Searchdefs/insert', searchDef)
  if (!response?.data?.success) {
    throw new Error("Unable to create search def for schema " + searchDef.schemaName)
  }
}

async function updateSearchDef (searchDef) {
  const response = await Vue.prototype.$http.put('/Searchdefs/update', {
    schemaName: searchDef.schemaName,
    userId: searchDef.userId,
    def: searchDef.defs,
    description: searchDef.description
  }, {
    params: {
      id: searchDef.id
    }
  })
  if (!response?.data?.success) {
    throw new Error("Unable to update search def for schema " + searchDef.schemaName)
  }
}

async function deleteSearchDef (searchDefId) {
  const response = await Vue.prototype.$http.delete('/Searchdefs/deleteById', {
    params: {
      id: searchDefId
    }
  })
  if (!response?.data?.success) {
    throw new Error("Unable to delete search def " + searchDefId)
  }
}

export {
  getSearchDefsForSchema,
  createSearchDef,
  updateSearchDef,
  deleteSearchDef
}