<template>
<div>
    <v-container fluid><!-- v-if="objSeg.schemaName == schemaName" getActiveSegments(seg, obj.field, i)
    segmentsActives[i].values.indexOf(seg) != -1 ? true : false -->
    <div v-for="(obj, i) in objSeg" :key="i"> <h3>{{ obj.field }}</h3><br>
        <v-row>
            <v-col
            v-for="(seg, index) in obj.enum" :key="index"
            cols="12"
            sm="4"
            md="4" >
            <v-label style="display: inline-grid;">
                <input
                style="cursor: pointer;"
                type="checkbox"
                id="checkbox"
                color="primary"
                @input="addSegmentation(obj.field, seg)"
                :checked="getActiveSegments(seg, obj.field, i)"
                >
                <p>{{ seg }}</p>
            </v-label>
            </v-col>
        </v-row>
    </div>
    </v-container>
</div>
</template>

<script>
export default {
    props: ["all_schemas", "schemaName", "active_permissions"],
    data(){
        return {
            objSeg: [],
            segmentation: [],
            objSegmentation: [],
            segmentsActives: [],
            campoList: [],
            campoIni: [],
            count: 0,
            componentKey: 0
        }
    },


    created(){
        this.segmentacoes()
        
        const segActives = this.active_permissions.filter(resp => 
                resp.name === this.schemaName)

        if(segActives[0]?.segmentations?.length > 0){
            this.segmentsActives = segActives[0].segmentations
            for(var i = 0; i < this.segmentsActives.length; i++){
                console.log(this.segmentsActives[i])
                this.campoIni.push(this.segmentsActives[i].field)
                this.segmentsActives[i].values.forEach(el => {
                    this.addSegmentation(this.segmentsActives[i].field, el)
                })
            }
        }
    },


    methods: {
        segmentacoes(){
        this.all_schemas.map(resp => {
            var jsonValue = JSON.parse(resp.jsonValue)
            var properties = Object.keys(jsonValue.properties)

            if(jsonValue.title == this.schemaName){
                for(var i = 0; i < properties.length; i++){
                    if(jsonValue.properties[properties[i]].seg == true){
                        this.objSeg.push({ schemaName: resp.name, 
                                enum: jsonValue.properties[properties[i]].enum, 
                                field: properties[i] })
                        this.campoList.push(properties[i])
                    } 
                }
            }
        })

        this.$forceUpdate()
        },

        getActiveSegments(segName, campo, index){
            /*const segActives = this.active_permissions.filter(resp => 
                resp.name === this.schemaName)
            
            this.segmentsActives = segActives[0].segmentations*/

            if(this.segmentsActives === null){
                return false
            } 
            
            else if(this.segmentsActives[index]?.values.includes(segName)){
                return true
            } 

            else { 
                return false
            }

        },

        addSegmentation(campo, valor){
            this.count = this.count + 1
            if(this.objSegmentation[campo]){
                if(this.objSegmentation[campo][0].values.includes(valor)){
                    let index = this.objSegmentation[campo][0].values.indexOf(valor)
                    this.objSegmentation[campo][0].values.splice(index,1)
                    console.log(this.objSegmentation[campo][0].values.length)
                    if(this.objSegmentation[campo][0].values?.length == 0){
                        this.campoIni.pop(campo)
                        delete this.objSegmentation[campo]
                    }
                } else {
                    this.objSegmentation[campo][0].values.push(valor)
                }
            
                
            } else {
                this.objSegmentation[campo] = []
                this.objSegmentation[campo].push({ field: campo, values: [valor] })
            }

            console.log('objSegmentation ', this.objSegmentation)


            this.$emit('segmentations', this.objSegmentation[campo], this.schemaName, campo, this.campoList.length,  this.campoIni)
        },

    }
}
</script>