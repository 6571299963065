<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    style="height: 90%;"
  >
    <v-card>
      <v-app-bar flat color="transparent">
        <v-btn large icon @click="$emit('cancel')">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Configuração de Status</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn large icon color="success" @click="addStatus(), $emit('cancel')">
          <v-icon>mdi-check</v-icon>
        </v-btn>

      </v-app-bar>

      <FormStatusConfig v-if="!loading" :key="count" :data="object" @form="eventForm" :statusConfigurados="configCad" :schemaName="schemaName" />
      
      <div class="text-center" style="padding: 19%;" v-else>
        <v-progress-circular
          color="primary"
          indeterminate
          size="128"
        ></v-progress-circular>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import FormStatusConfig from './formStatusConfig.vue'
import statusFlowService from '@/services/statusFlowService'
export default {
    name: 'dialogStatusConfig',
    props: ['value', 'schema'],
    components: { FormStatusConfig },
    data (){
        return {
            dialog: false,
            object: null,
            objStatusFlow: [],
            formStatus: null,
            schemaName: '',
            field: '',

            StatusFlow: statusFlowService,

            configCad: [],
            count: 0,
            loading: false
        }
    },

    methods: {
        eventForm(obj){
          this.formStatus = obj
        },

        async addStatus(){

          if(this.configCad?.id) await this.StatusFlow.deletebySchema(this.configCad?.id)

          // Rota para add status
          let obj = {}
          obj.properties = this.formStatus.filter(item => item.aprovEmail?.length > 0 || item.action)

          
          let userParams = await this.$auth.getUserParams()

            obj.tenanty = userParams.tenanty
            obj.schemaName = this.schemaName
            obj.field = this.field

            await this.StatusFlow.insertStatus(obj)
        },

        async getBySchema(){
          this.loading = true
          const req = await this.StatusFlow.getBySchema(this.schemaName, this.field)
          if(req){
            this.configCad = req?.data?.data;
          }

          this.loading = false
        }
    },

    watch: {
        async value(v){
          this.dialog = v
          
          if(this.dialog) {
            await this.getBySchema()
            this.count += 1;
          }
          
        },

        async schema(schema){
          this.schemaName = schema.title
          this.object = Object.entries(schema.properties)
          this.object = this.object.filter(resp => resp[1]?.statusF === true)

          if(this.object?.filter(resp => resp[1]?.statusF == true).length > 0){
             this.field = this.object?.filter(resp => resp[1]?.statusF === true)[0][0]

             await this.getBySchema()
          }

        },
    }
}
</script>