<template>
  <div :key="count">
    <!-- MIRROR -->
    <div v-if="mirror"></div>

    <!-- COLOR --->
    <div v-if="type == 'string' && color == true" class="color-container">
      <span>{{ label }}</span>
      <v-color-picker
      class="ma-auto"
      v-model="value[property]"
      elevation="0"
      hide-inputs
      ></v-color-picker>
    </div>

    <!-- ENUM -->
    <div v-if="enumCombo">
      <v-combobox
        v-model="value[property]"
        :label="label"
        :items="segEnumComboList.length > 0 ? segEnumComboList : enumCombo"
        outlined
        clearable
        :disabled="handleReadyOnly()"
        @input="returnVisibleCondition"
        @blur="$emit('blur', $event), verifyCondition()"
      ></v-combobox>
    </div>

    <!-- RELATION -->
    <div v-if="(type == 'string' && relationSchema != null) || (type == 'array' && relationSchema != null && multiple == true)">
      <auto-complete-schema
        v-model="value[property]"
        :multiCompany="multiCompany"
        :fieldValues="value"
        :schemaName="relationSchema"
        :relationCondition="relationCondition"
        :relationField="relationFields"
        :field="fieldSchema"
        :filter="filter"
        :label="label"
        :readyonly="handleReadyOnly()"
        :opened="isOpen"
        :property="property"
        :type="type"
        :filterSchema="filterSchema"
        :readonlyCondition="readonlyCondition"
        :visibleCondition="visibleConditionRelationSchema"
        :objFilter="objFilter"
        :searchQr="searchQr"
        @input="$emit('blur', $event), verifyCondition()"
        @itemReadyonlyCondition="$emit('readonlyCondition', readonlyCondition, $event)"
        @visibleCondition="$emit('visibleCondition', $event)"
      >
      </auto-complete-schema>
    </div>

    <div v-if="file && type == 'array'">
      <InputFile v-model="value[property]" :label="label"
      :readonly="handleReadyOnly()"
      :filesCad="filesCad" :accept="file.accept || '.*'" :multiple="file.multiple" @file="files" @fileCad="fileCad" 
      @runActionInputFile="runActionInputFile"
    />
    </div>
    <!-- TEXT -->
    <div
      v-if="
        type == 'string' &&
        relationSchema == null &&
        mirror == null &&
        !enumCombo &&
        !color
      "
    >
      <v-text-field
        type="text"
        v-model="value[property]"
        v-mask="mask"
        :label="label"
        :disabled="handleReadyOnly()"
        outlined
        @blur="$emit('blur', $event), verifyCondition()"
        :append-icon="searchQr ? 'mdi-qrcode' : undefined"
      ></v-text-field>
<!---
      <vuetify-money
        v-else
        v-money="money"
        type="text"
        v-model="value[property]"
        :label="label" 
        outlined
        :readonly="handleReadyOnly()"
        @blur="$emit('blur', $event)"
      ></vuetify-money>--->
    </div>

    <div v-if="type == 'link'">
      <v-text-field
        v-model="value[property]"
        :append-outer-icon="'mdi-link'"
        
        :disabled="handleReadyOnly()"
        outlined
        @click:append-outer="clique(value[property])"
        :label="label"
        type="text"
      ></v-text-field>
    </div>

    <!-- TEXT AREA -->
    <div v-if="type == 'textarea'">
      <v-textarea
        type="text"
        v-model="value[property]"
        :label="label"
        :disabled="handleReadyOnly()"
        outlined
        @blur="$emit('blur', $event), verifyCondition()"
      ></v-textarea>
    </div>

    <!-- NUMERIC -->
    <div v-if="type == 'integer' || type == 'number'">
      <v-text-field
      v-if="real == null || real == undefined"
        type="number"
        v-model="value[property]"
        :label="label"
        :max="valorMaximo !== undefined ? valorMaximo : ''"
        :min="valorMinimo !== undefined ? valorMinimo : ''"
        :disabled="handleReadyOnly()"
        :prepend-icon="sufixo"
        outlined
        @input="numberReturn"
        @blur="$emit('blur', $event), verifyNumber(value[property]), verifyCondition()"
      >
        <template v-slot:append-outer v-if="prefixo !== undefined">
          <v-icon
            color="blue"
          >{{ prefixo }}</v-icon>
        </template>
      </v-text-field>

      <vuetify-money
        v-else
        type="number"
        v-model="value[property]"
        :label="label" 
        outlined
        :disabled="handleReadyOnly()"
        @input="moneyReturn"
      ></vuetify-money>
    </div>

    <!-- TIME -->
    <div v-if="type == 'time'">
      <v-text-field
        type="time"
        v-model="value[property]"
        :label="label"
        :disabled="handleReadyOnly()"
        outlined
        @blur="$emit('blur', $event)"
      ></v-text-field>
    </div>

    <div v-if="type == 'month'">
      <v-text-field
        type="month"
        v-model="value[property]"
        :label="label"
        :disabled="handleReadyOnly()"
        outlined
        @blur="$emit('blur', $event), verifyCondition()"
      ></v-text-field>
    </div>

    <!-- DATE -->
    <div v-if="type == 'date'">
      <v-text-field
        type="date"
        v-model="value[property]"
        :label="label"
        :disabled="handleReadyOnly()"
        outlined
        @input="dataReturn"
        @blur="$emit('blur', $event), verifyCondition()"
      ></v-text-field> 
    </div>

    <!-- DATE TIME LOCAL -->
    <div v-if="type == 'datetime-local'">
      <v-text-field
        type="datetime-local"
        v-model="value[property]"
        :label="label"
        :disabled="handleReadyOnly()"
        outlined
        @blur="$emit('blur', $event), verifyCondition()"
      ></v-text-field>
    </div>

    <!-- BOOLEAN -->
    <div v-if="type == 'boolean'" style="display: flex; margin-bottom: 20px;">
      <v-simple-checkbox
        v-model="value[property]"
        :label="label"
        :disabled="handleReadyOnly()"
        @input="inputBoll"
        :ripple="false"
        color="blue"
        @blur="$emit('blur', $event), verifyCondition()"
      ></v-simple-checkbox>
      <span style="fontSize: 13px">{{ label }}</span>
    </div>

    <div v-if="type == 'user'">
      <v-autocomplete
        v-model="value[property]"
        :items="userList"
        item-text="name"
        item-value="id"
        label="Usuários"
        dense
        outlined
        :disabled="handleReadyOnly()"
        @update:search-input="searchUser"
      ></v-autocomplete>
    </div>

    <div v-if="type == 'array' && relationSchema == null && !file">
      <auto-complete-users
        v-if="userList.length > 0"
        :userCad="value[property]"
        :isOwner="owner"
        :readonly="handleReadyOnly()"
        :userList="userList"
        @input="Owners"
        @blur="$emit('blur', $event), verifyUser(value[property]), verifyCondition()"
      
      > 
      </auto-complete-users>
    </div>
<!--
    <div v-if="type == 'array' && relationSchema == null && !file">
      <v-autocomplete
        v-model="value[property]"
        :items="userList"
        chips
        outlined
        :label="label"
        item-text="name"
        item-value="id"
        multiple
        :disabled="owner == true && ownerPermission == true"
        @input="Owners"
        @blur="$emit('blur', $event), verifyUser(value[property]), verifyCondition()"
      >
      <template v-slot:selection="data">
        <v-chip v-bind="data.attrs" :input-value="data.selected"
                  @click="data.select"
                >
                <v-avatar
                  class="accent white--text"
                  left
                  v-text="data.item.name.slice(0, 1).toUpperCase()"
                ></v-avatar>
                  {{ data.item.name }}
                </v-chip>
              </template>
              
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>

                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
        </template>
      </v-autocomplete>
    </div>--->
  </div>
</template>

<script>
import getUsersMixin from '@/mixins/getUsersMixin'
import autoCompleteSchema from "../../components/inputs/autoCompleteSchema.vue";
import InputFile from "../../components/inputs/inputFile.vue"
import EntityService from "../../services/entityService.js";
import autoCompleteUsers from '@/components/inputs/autoCompleteUsers'
import {VMoney} from 'v-money'
import moment from 'moment';


export default {
  mixins: [getUsersMixin],
  props: [
    "multiCompany",
    "color",
    "value",
    "property",
    "type",
    "format",
    "label",
    "relationSchema",
    "relationContition",
    "readonly",
    "mirror",
    "enumCombo",
    "default",
    "msg",
    "visible",
    "readyall",
    "readallPerField",
    "readonlyCondition",
    "descriptor",
    "relationCondition",
    "isOpen",
    "autoInc",
    "schemaName",
    "mask",
    "real",
    "owner",
    "visibleCondition",
    "visibleConditionRelationSchema",
    "relationField",
    "field",
    "filter",
    "file",
    "filterSchema",
    "valorMaximo",
    "valorMinimo",
    "prefixo",
    "sufixo",
    "booleanCondition",
    "multiple",
    "objFilter",
    "searchQr"
  ],

  data() {
    return {
      timeOut: null,
      filesInsert: [],
      filesBase64: [],
      filesCad: [],
      segEnumComboList: [],
      segmentationsList: [],
      moment: {},
      userList: [],
      //users: [],
      data: [],
      entityService: new EntityService(this.schemaName),
      readonlyy: false,
      valueAutoInc: '',
      condtition: [],
      count: 0,
      relationFields: '',
      fieldSchema: '',
      ownerPermission: false,
      money: {
        locale: "pt-br",
//        prefix: "R$",
//        suffix: "",
        length: 11,
        precision: 2,
        masked: false
      },
      //userLogado: ''
    };
  },

  directives: { money: VMoney }, 

  components: { autoCompleteSchema, InputFile, autoCompleteUsers },
  watch: {
    async value(value){
      if(value[this.property] == null) delete value[this.property]

      //this.returnVisibleCondition(value[this.property])
    },

    async isOpen(v){
      if(v == true){
        this.returnVisibleCondition(this.value[this.property])
      }
      if(v == true && this.autoInc == true && this.value.id == undefined){
        await this.getAutoInc(this.schemaName);
      }

      if(v == false){
        this.value[this.property] = ''
        this.$cookies.set("filterSchema", '');
      }

      if(this.type == 'date' && v == true){
        try {
          this.value[this.property] = moment(this.value[this.property].split('/').reverse().join('-')).format("YYYY-MM-DD")
        } catch(err){
          //
        }
      }
      
      if(this.valorMinimo > 0 && this.value.id == undefined){
        this.value[this.property] = this.valorMinimo
      }

      if(this.type == 'datetime-local' && v == true){
        /*
        var split = this.value[this.property].split('T')
        this.value[this.property] = split[0].split('/').reverse().join('-') + 'T' + split[1]*/

        if(this.value.id && this.value[this.property] !== ''){
          let newDate = this.value[this.property].split(':')
          this.value[this.property] = `${newDate[0]}:${newDate[1]}`
        }
      }

      if(this.type == 'number' && this.real == true && v == true){
          var s = this.value[this.property].split('R$')
          var valor = s[1].replace('.', '')
          this.value[this.property] = Number(valor.replace(',', '.'))
      }

      if(this.value.id == undefined && this.type == 'array' && this.isOpen == true && this.owner == true){
        const data = await this.$auth.getUserParams()
        this.value[this.property] = [ data.userId ]
      }

      if(this.type == 'user' && v == true){
        if(this.default == "userLogado" && this.value.id == undefined){
          this.value[this.property] = this.userLogado
          this.$forceUpdate()
        } else {
          this.users.map(user => {
            if(user.name ==  this.value[this.property]) this.value[this.property] = user.id
          })
        }
      }
    },

    async schemaName(name){
      if(this.autoInc == true && this.value.id == undefined){
        await this.getAutoInc(name)
      }
    },
  },
  methods: {
    
    runActionInputFile(event){
      this.$emit('blur', event)
    },
    returnVisibleCondition(value){

      try {
        if(this.visibleCondition[value]){
          for(var i = 0; i < this.visibleCondition[value].length; i++){
            if(this.value[this.visibleCondition[value][i]]){
              this.value[this.visibleCondition[value][i]] = undefined;
            } 
          }

          this.$emit('visibleCondition', this.visibleCondition[value])
        }
      } catch(err){
        //console.log(err)
      }
    },


    inputBoll(value){
      if(this.booleanCondition){
        for(let i = 0; i < this.booleanCondition[this.property]?.length; i++){
            this.value[this.booleanCondition[this.property][i]] = ''
        }
        this.$emit('booleanCondition', this.booleanCondition, this.property, value)
      }
    },

    files(files){
      this.filesInsert = files
      if(this.filesCad != undefined){
        this.value[this.property] = files.concat(this.filesCad)
      } else {
        this.value[this.property] = files
      }
      
      this.$emit('blur')
    },

    fileCad(cad){
      this.filesCad = cad
      this.value[this.property] = this.filesCad.concat(this.filesInsert)
      //console.log('fileCad ', this.value[this.property])
    },

    Owners(owners){
      this.value[this.property] = owners.map(owner => {
        let newOwner = {
          userName: owner.name,
          userId: owner.id
        }

        return newOwner
      })

    },

    async searchUser(value){
      this.userList = await this.searchInput(value)
    },

    async verifyUser(value){

      const data = await this.$auth.getUserParams()
      if(value.indexOf(data.userId) === -1){
        this.value[this.property].push(data.userId)
      } 
    },

    async getAutoInc(schemaName){
      if(this.autoInc){
        const req = await this.entityService.autoInc(schemaName, this.property[0].toUpperCase() + this.property.slice(1), this.mask)
        this.value[this.property] = req.data
        this.$forceUpdate()
      }
    },

    numberReturn(value) {
      this.value[this.property] = Number(value);
    },

    verifyNumber(value){
      if(Number(value) < this.valorMinimo || Number(value) > this.valorMaximo){
        this.value[this.property] = this.valorMinimo
        this.$notfy.error(`Erro no campo ${this.property}`);

        this.count += 1
      }

    },

    dataReturn(value){
      if(this.type == 'date'){
        this.value[this.property] = moment(value).format("YYYY-MM-DD")
      }
    },

    moneyReturn(value, event){
      if(this.type == "number" && this.real == true){
        //atual.toLocaleString('pt-br', {minimumFractionDigits: 2});
        this.value[this.property] = Number(value)

        if(this.timeOut){
          clearTimeout(this.timeOut)
        }

        this.timeOut = setTimeout(() => {
          this.$emit('blur', event)
        }, 1000)
        
      }
    },

    clique(value) {
      window.open(value, "_blank").focus();
    },


    async getPermission(){
      const data = await this.$auth.getUserParams()
      const permissions = await this.$acessGroup.searchByUserId(data.userId)
      permissions.data.data.schemas.map(p => {
        if(p.schemaName == this.schemaName){
          if(p.permissions[4] == 'Owner'){
            this.ownerPermission = true
          }
          if(p.segmentations !== undefined && this.enumCombo){
            this.segmentationsList = p.segmentations
          }
        }
      })
    },

    segEnumCombo(){
      this.segmentationsList?.forEach(element => {
        if(element.field == this.property){
          this.segEnumComboList = this.enumCombo.filter(resp => element.values.indexOf(resp) != -1)
        }
      })
    },

    handleReadyOnly() {
      if (this.readyall !== undefined) {
        return this.readyall;
      }

      if (this.verifyCondition() !== undefined) {

     //   return this.verifyCondition();
      }
      if (this.readonly !== undefined) {
        return this.readonly;
      }
      return false;
    },

    verifyCondition() {
      if (!this.readonlyCondition) {
        return undefined;
      }

      
      this.$emit('readonlyCondition', this.readonlyCondition, this.value[this.readonlyCondition.field], this.property,this.value.id)
      
    

      return undefined;
    },
  },

  async created() {
    this.moment = moment
    this.returnVisibleCondition(this.value[this.property])
    
    this.segEnumCombo()

    if(this.owner == true && this.type == 'array' && this.relationSchema == null){
      await this.getPermission();
    }

    if(this.type == 'user' || this.owner){
      this.userList = await this.getUsers(this.value[this.property]?.name);
    }


    if(this.relationField != undefined){ 
      this.relationFields = this.relationField;
    }
    if(this.field != null) this.fieldSchema = this.field

    if(this.type == 'user' && this.isOpen == true){
      if(this.default == "userLogado" && this.value.id == undefined){
        this.value[this.property] = this.userLogado
        this.$forceUpdate()
      } else {
        this.users.map(user => {
          if(user.name == this.value[this.property]?.name){
            this.value[this.property] = user.id
          }
        })
      }
    }
    
    if(this.type == 'date' && this.isOpen == true){
      try {
        this.value[this.property] = moment(this.value[this.property].split('/').reverse().join('-')).format("YYYY-MM-DD")
        //this.value[this.property].split('/').reverse().join('-')
        } catch(err){
          //
        }
      }

      if(this.type == 'datetime-local' && this.isOpen == true){
        
        /*var split = this.value[this.property].split('T')
        this.value[this.property] = split[0].split('/').reverse().join('-') + 'T' + split[1]*/


        if(this.value.id &&  this.value[this.property]){
          let newDate = this.value[this.property].split(':')
          this.value[this.property] = `${newDate[0]}:${newDate[1]}`
        }

        
      }

      if(this.type == 'number' && this.real == true && this.isOpen == true){
        try {
          var s = this.value[this.property].split('R$')
          var valor = s[1].replace('.', '')
          this.value[this.property] = Number(valor.replace(',', '.'))
        } catch(err){
          //
        }
      }

      if(this.type == 'boolean' && this.value.id !== undefined && this.msg){
        if(this.msg?.true == this.value[this.property]){
          this.value[this.property] = true
        } else {
          this.value[this.property] = false
        }
      }

      if(this.type == 'array' && this.files != null){
        this.filesCad = this.value[this.property]
      }

  },

  async mounted() {

    if(this.valorMinimo > 0 && this.value.id == undefined){
      this.value[this.property] = this.valorMinimo
    }
    if(this.autoInc == true && this.value.id == undefined){
      await this.getAutoInc(this.schemaName);
    }

    if(this.default && this.value.id == undefined){
      if(this.default == '0000-00-00'){
        let date = new Date()
        this.value[this.property] = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      } else {
        this.value[this.property] = this.default
        this.returnVisibleCondition(this.value[this.property])
      }
      this.$forceUpdate()
    }

    if(this.type == 'boolean' && this.value.id == undefined && this.booleanCondition){
     this.$emit('booleanCondition', this.booleanCondition, this.property, false, 1)
    }

    if(this.readallPerField !== undefined && this.value.id != undefined){
      if(this.readallPerField?.value.indexOf(this.value[this.readallPerField?.field]) !== -1){
         this.$emit('readall', true)
      } else {
        this.$emit('readall', false)
      }
    }

    if(this.color == true && this.value.id == undefined){
      this.value[this.property] = "#ffff"
    }
    
    this.verifyCondition();
    this.$forceUpdate()
  },
};
</script>

<style>

.color-container {
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.v-color-picker__canvas {
    position: relative;
    overflow: hidden;
    contain: strict;
    height: 100px !important;
}

</style>
