<template>
  <div class="mb-10">
    <div class="text-h4">Grupos</div>

    <div class="pa-5 pb-0">
      <AcessControlDialog @close="onClose" />
      <v-btn style="left: -20px; bottom: 22px;" color="primary" @click="$router.push('/userPage')" icon dark>
        <v-icon>mdi-arrow-left</v-icon></v-btn
      >
    </div>

    <form @submit.prevent="searchItems">
      <v-text-field
        class="pt-5 pb-5"
        chips
        v-model="search"
        clearable
        hide-details
        hide-selected
        prepend-icon="mdi-magnify"
        label="Search"
        solo
      />
    </form>

    <v-data-table
      :headers="headers"
      :items="groups"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      class="elevation-1"
      hide-default-footer
      @page-count="pageCount = $event"
    >
      <template v-slot:item.actions="{ item }">

          <v-menu
            bottom
            left
            
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
                color="black"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list class="pa-3">

              <v-list-item style="cursor: pointer" @click="openUpdate(item.groupName, item.id)">
                <v-list-item-title>
                  <span>Atualizar Grupo</span>
                </v-list-item-title>

              </v-list-item>

              <v-list-item @click="deleteItem(item.groupName, item.id)" style="cursor: pointer">
                <v-list-item-title>
                  <span>Deletar Grupo</span>
                  <!--
                  <v-icon small class="icon">
                    mdi-delete</v-icon>--->
                </v-list-item-title>
              </v-list-item>

              <v-list-item style="cursor: pointer" 
              @click="openPermissionsDialog(item.id, item.groupName)">
                <v-list-item-title>
                  <span>Permissões do Grupo</span>
                  <v-icon small class="icon">
                    mdi-account-griup</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>

          </v-menu>



<!---
        <v-icon
          small
          class="icon"
          @click="goToUsersByGroupPage(item.groupName, item.id)"
        >
          mdi-account
        </v-icon>--->
      </template>

    <template v-slot:item.viewGroup="{ item }">
      <v-icon
        style="cursor: pointer;"
        color="primary"
        size="large"
        @click="$router.push(`/users/${item.groupName}/${item.id}`)"
      >
        mdi-account - {{ item }}
      </v-icon>
    </template>


    </v-data-table>
    <!-- <h1>Controle de acesso</h1> -->

    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="findGroup()"
      ></v-pagination>
    </div>

    <ConfirmDeleteDialog
      v-model="dialogDelete"
      :value="dialogDelete"
      :groupName="groupName"
      :idGroup="idGroup"
      :onClose="onClose"
    />

    <AcessControlUpdate
      :groupName="groupName"
      :id="idGroup"
      :open="updateGrupDialog"
      @close="onClose"
    />

    <permissions-dialog
      :value="permissionsDialog"
      :group_id="idGroup"
      :group_name="groupName"
      @cancel="permissionsDialog = false"
      @save="permissionsDialog = false"
    />
  </div>
</template>

<script>
import AcessControlDialog from "../../components/dialogs/acessControlDialog.vue";
import AcessControlUpdate from "../../components/dialogs/acessControlUpdate.vue";
import ConfirmDeleteDialog from "../../components/dialogs/confirmDeleteDialog.vue";
import PermissionsDialog from '../../components/dialogs/permissionsDialog.vue';

export default {
  components: {
    AcessControlDialog,
    AcessControlUpdate,
    ConfirmDeleteDialog,
    PermissionsDialog
  },
  methods: {
    openUpdate(groupName, id){
      this.idGroup = id
      this.groupName = groupName
      this.updateGrupDialog = true

    },
    openPermissionsDialog(id, group_name){
      this.idGroup = id
      this.groupName = group_name;
      this.permissionsDialog = true
    },
    goToUsersByGroupPage(groupName, id) {
      this.$router.push(`/users/${groupName}/${id}`);
    },

    async onClose() {
      this.findGroup();
    },

    async findGroup() {
      let data = await this.$acessGroup.findAllGroup("", this.page, 10);
      this.groups = data.data.data.data;
      this.itemsPerPage = data.data.data.limit
      this.pageCount = data.data.data.pages


      return this.groups;
    },

    async deleteItem(groupName, id) {
      this.idGroup = id;
      this.groupName = groupName;
      if (await this.$isConfirmGroupDelete("Tem certeza que deseja deletar o grupo", this.groupName))
        try {
          await this.$acessGroup.removeGroup(this.groupName, this.idGroup);
          this.findGroup();
        } catch (error) {
          //
        }
    },

    async searchItems() {
      console.log("Buscar por", this.search);
      let data = await this.$acessGroup.findAllGroup(this.search.toString(), this.page, 10);

      this.groups = data.data.data.data;
      
      this.itemsPerPage = data.data.data.limit
      this.pageCount = data.data.data.pages

      return this.groups;
    },
  },

  async created() {
    this.findGroup();
  },

  cancelDialog() {
    this.dialogDelete = false;
  },

  data: () => ({
    updateGrupDialog: false,
    permissionsDialog: false,
    idGroup: null,
    groupName: null,
    componentKey: 0,
    groups: [],
    dialogDelete: false,
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 0,

    peq: "",

    headers: [
      {
        text: "Nome",
        value: "groupName",
      },

      {
        text: 'Total de usuários no grupo',
        value: 'totalUsersInGroup'
      },

      {
        text: "Actions",
        value: "actions",
      },

      {
        text: "Usuários do Grupo",
        value: "viewGroup"
      }
      
    ],
  }),
};
</script>

<style scoped>
.icon {
  margin-right: 10px;
  margin-left: 10px;
}
</style>
