<template>

    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >

        <template v-slot:activator="{ on, attrs }">
            <v-icon
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            size="30"
            >mdi-fullscreen</v-icon>
        </template>

        <v-card>
            <v-app-bar class="mb-5" flat color="transparent">
                <v-btn large icon @click="dialog = false">
                    <v-icon color="primary">mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title class="title">{{ schema.description }}</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn
                    large
                    icon
                    color="primary"
                    @click="dialog = false"
                    :disabled="loading ? false : true"
                    >
                    <v-icon size="30">mdi-fullscreen</v-icon>
                </v-btn>
            </v-app-bar>
            
            <v-row>
                <v-col cols="9">
                    <entity-form-page
                        v-if="schema && entity"
                        :schema="schema"
                        :entity="entity"
                        :propertyActions="propertyActions"
                        :col="'col-6'"
                    ></entity-form-page>
                </v-col>

                <v-col cols="3" class="actions-container">
                    <div>
                        <v-list>
                            <v-list-item
                            style="display: flex;
                                flex-direction: row;
                                margin-top: 5px;"
                            :disabled="loading ? false : true"
                                @click="$emit('save')"
                            >
                                <v-list-item-icon>
                                    <v-icon color="primary" size="30">mdi-check</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Gravar</v-list-item-title>
                            </v-list-item>

                            <v-list-item
                            v-if="entity.id"
                            style="display: flex;
                                flex-direction: row;
                                margin-top: 5px;"
                                @click="$emit('delete')"
                            >
                                <v-list-item-icon>
                                    <v-icon color="error" size="30">mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Deletar</v-list-item-title>
                            </v-list-item>
                        </v-list>

                        <v-list>
                            <v-list-item
                            style="display: flex;
                                flex-direction: row;
                                margin-top: 5px;"
                            v-for="(item, index) in actions"
                            :key="index"
                            link
                            @click="$emit('runAction', item)"
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ item.description }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </div>

                </v-col>
            </v-row>

        </v-card>

    </v-dialog>
</template>

<script>
import EntityFormPage from '../../views/entities/EntityFormPage.vue'
export default {
    props: ["schema", "entity", "propertyActions", "actions", "loading"],
    components: { EntityFormPage },
    data(){
        return {
            dialog: false
        }
    },
    
    watch: {
        dialog(){
            this.$emit("dialog")
        }
    },

    methods: {
    }
}
</script>


<style scoped>
.actions-container {
    display: flex;
    justify-content: center;
}

.actions-container div {
    display: flex;
    flex-direction: column;
}

</style>