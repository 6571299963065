<template>
    <div>
    <v-container class="justify-center">
      <form ref="form" enctype="multipart/form-data" @submit="inserProfile($event)">
      <!--
        <h1>
          Personalização
        </h1>--->
        <v-row class="justify-center">
          <v-col cols="12" sm="6" md="8">
<!--
            <div class="file-container">
              <div class="add-container">
                    <v-avatar style="width: 200px; height: 200px;">
                      <img
                        class="img-style"
                        :src="`${logo}`" id="logoId"
                      >
                    </v-avatar>
                    <label class="label-img" for="logo">Alterar logo</label>
                    <input type="file" id="logo" name="logo" ref="file" @change="onChange">
                    <input type="hidden" name="logoId" id="logoId" />
              </div>

            </div>-->

              <v-text-field
                label="Nome da empressa"
                type="text"
                id="nomeEmpresa"
                name="nomeEmpresa"
                v-model="name"
                placeholder="Ex. outlook.com"
              ></v-text-field>

                <v-combobox
                    v-model="select"
                    name="dominio"
                    id="dominio"
                    label="I use a scoped slot"
                    multiple
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        <v-avatar
                          class="accent white--text"
                          left
                          v-text="data.item.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
<!---
              <div class="cor-container">
                <label for="cor">Cor: </label>
                <input type="color" name="cor" id="cor" class="cor" v-model="color">
              </div>--->

              <div class="btn">
                <v-btn class="mr-4" color="blue" type="submit">Confirmar</v-btn>
              </div>
          </v-col>
        </v-row>
      </form>
    </v-container>
    </div>
</template>

<script>
    export default {
      data(){
        return {
          name: '',
          logo: '',
          baner: '',
          backGround: '',
          backGroundLogon: '',
          color: "",
          profile: {},
          select: [],

          switch1: false,
          "bucketConfig": {
            "bucketName": "",
            "accessKey": "",
            "secretAccessKey": "",
            "user": "",
            "password": "",
            "typeBucket": ""
          },
        items: ['blobStorage']
        }
      },

      created(){        
        this.name = window.localStorage.getItem("nomeEmpresa");
        this.color = window.localStorage.getItem("color");
        this.logo = window.localStorage.getItem("logo");
        this.backGround = window.localStorage.getItem("backGround");
        this.backGroundLogon = window.localStorage.getItem("backGroundLogon");
        this.logo = window.localStorage.getItem("logo");
        this.baner = window.localStorage.getItem("banner");

        let itens = window.localStorage.getItem("dominio")
        this.select = itens.split(",")
      },


        methods: {

          async inserProfile(e){
            e.preventDefault();

            const formData = new FormData();
            formData.append('nomeEmpresa', this.name);
            formData.append('color', this.color);
            formData.append('banner', this.baner);

            for(var i = 0; i < this.select.length; i++){
              formData.append('dominio', this.select[i]);
            }
            

            if(this.name != ''){
              const data = await this.$profile.insertProfile(formData);
              console.log('data', data);
              if(data.status == 200){
                this.$notfy.success("Sucesso");
                await this.updateAccountInfo();
                window.location.reload()
              } else {
                this.$notfy.error('error');
              }
            } else {
              this.$notfy.error('Informe o nome da empresa');
            }
          },

          async updateAccountInfo(){

            this.profile = await this.$profile.getProfile();
            console.log(this.profile)

            this.name = this.profile.data.data.nomeEmpresa;
            //this.nameDomain = this.profile.data.data.dominio;
            this.select = this.profile.data.data.dominio
            this.color = this.profile.data.data.color;
            
            this.logo = this.profile.data.data.logo;
            this.baner = this.profile.data.data.banner;

            window.localStorage.setItem("color", this.color)
            window.localStorage.setItem("nomeEmpresa", this.name)
            window.localStorage.setItem("dominio", this.select)
            window.localStorage.setItem("banner", this.baner)
          },
/*
          async getBase64FromUrl(url) {
            const data = await fetch(url);
            const blob = await data.blob();
            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob); 
              reader.onloadend = () => {
                const base64data = reader.result;   
                resolve(base64data);
              }
            });
          },*/

/*
          onChangeBaner(e){
            var input = e.target;
            if (input.files) {
              var reader = new FileReader();
              reader.onload = (e) => {
                this.baner = e.target.result;
              }
              reader.readAsDataURL(input.files[0]);
              const banerFormData = new FormData();
              banerFormData.append('baner', input.files[0]);
              window.localStorage.setItem("banner", this.logo)
            }
          },
          
          async onChange(e) {
            var input = e.target;
            if (input.files) {
              var reader = new FileReader();
              reader.onload = (e) => {
                this.logo = e.target.result;
              }
              reader.readAsDataURL(input.files[0]);
            }
            const logoFormData = new FormData();
            logoFormData.append('logo', input.files[0]);
            await this.$profile.insertLogo(logoFormData);
            window.localStorage.setItem("logo", this.logo)
            
            //window.location.reload();
          },*/

          updateLogon(){
            console.log(this.switch1);
          }
        }
    }
</script>

<style lang="scss" scoped>

.file-container {
  display: flex;
  align-items: center;
}

.config-container {
  background-color: #00a1f9;
}

.mini-images img {
  height: 50px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding-right: 50px;
  margin-left: 10px;
}

.add-container {
  display: flex;
  margin-top: 30px;
  margin-bottom: 40px;
}

.cor-container {
  display: inline-flex;
}

#cor {
      height: 35px;
      border: none;
      padding: 0;
      background-color: #fff;
      cursor: pointer;
}
#cor:focus{
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
}

input[type="file"] {
    display: none;
}

.label-img {
  text-align: center;
  display: block;
  cursor: pointer;
  margin-left: 10px;
  object-fit: cover;
  align-self: center;
  color: #00a1f9;
  text-decoration: underline;
  text-wrap: nowrap;
}

.btn {
  margin-top: 30px;
}

.img-style {
  border: 1px solid;
  max-width: 200px;
  max-height: 200px;
  border: 1px solid;
  object-fit: contain;
}

@media(max-width: 450px){
  .img-style {
    max-width: 100px;
    max-height: 100px;
  }
}

@media(max-width: 850px){
  .file-container {
    flex-direction: column;
  }
}
</style>