<template>
  <v-main>
    <ActionResultCustom @close="close" :result="$root.lastFullscreenActionResult" @run-action="requestNewAction"></ActionResultCustom>
    <v-dialog v-model="actionParameterDialog"  width="75vw" persistent>
      <ActionDialog
        v-if="actionParameterDialog"
        :action="requestedAction"
        @close="actionParameterDialog = false"
        :predefined-params="paramsToSend"
      >
      </ActionDialog>
    </v-dialog>
  </v-main>
</template>

<script>
import { getActionById, getActionByName } from "../services/actionService"

import ActionResultCustom from '../components/dialogs/actionResultCustom.vue'
import ActionDialog from '../components/dialogs/actionDialog.vue'

export default {

  components: {
    ActionResultCustom,
    ActionDialog
  },

  data () {
    return {
      actionParameterDialog: false,
      requestedAction: null,
      paramsToSend: {},
    }
  },

  methods: {
    async requestNewAction (identifier, params) {
      let action
      if (identifier.id) action = await getActionById(identifier.id)
      else if (identifier.name) action = await getActionByName(identifier.name)
      else throw new Error('Unable to request action without id or name')
      this.requestedAction = action
      if (params && Object.keys(params).length) {
        this.paramsToSend = params
      }
      this.actionParameterDialog = true
    },

    close () {
      this.$router.push({ name: "Home" })
    },

    created() {
      console.log('Action result full custom')
    },
  }

}
</script>

<style>

</style>