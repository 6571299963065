export default {

    data(){
        return {
            actionsData: [],
            actionsList: [],
            actionsUser: [],
            schemaPermissions: []
        }
    },

    methods: {
        async getActionsPermission(userId){
            this.actionsData = await this.$acessGroup.findPermissionsByUserId(userId);
            this.actionsUser = this.actionsData.data?.schemas//.filter(item => item.actions?.length > 0)
            this.actionsUser?.map((x) => {
              this.schemaPermissions.push({ permissions: x.permissions, schemaName: x.schemaName })

              if(x.actions){
                x.actions?.map((y) => {
                  this.actionsList.push(y)
                })
              }    
            })
            this.$forceUpdate()
          },

          getPermissionCrud(schemaName, operationCrud){
            if(schemaName){
              return this.schemaPermissions
              .filter(resp => resp.schemaName == schemaName?.title && resp.permissions.indexOf(operationCrud) !== -1 ).length > 0
            }
          }
    },
}