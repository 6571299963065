<template>
  <div>
    <div class="text-h4 mb-5">
      Usuários do Grupo {{ $route.params.groupName }}
    </div>
    <div class="pa-5 pb-0 mb-5">
        <v-icon @click="$router.back()" color="primary">
          mdi-arrow-left
        </v-icon>
    </div>

    <form @submit.prevent="searchUser(search)">
      <v-text-field
        class="pt-5 pb-5"
        chips
        v-model="search"
        clearable
        hide-details
        hide-selected
        prepend-icon="mdi-magnify"
        label="Search"
        solo
      />
    </form>

    <v-data-table
      :page="page"
      :headers="headers"
      :items="users"
      :items-per-page="users.length"
      class="elevation-1"
      hide-default-footer
    >

    <template v-slot:item.viewGroup="{ item }">
      <v-icon
        style="cursor: pointer;"
        color="primary"
        size="large"
        @click="openPermissionsDialog(item)"
      >
        mdi-account - {{ item }}
      </v-icon>
    </template>
    
    </v-data-table>

      <div class="text-center pt-2">
        <v-pagination
          @input="pagination"
          v-model="page"
          :length="pages"
        ></v-pagination>
      </div>

      <permissions-dialog
        :value="permissionsDialog"
        :user="selected_user"
        :group_id="$route.params.id"
        @cancel="permissionsDialog = false"
        @save="permissionsDialog = false"
      />
  </div>
</template>

<script>
import permissionsDialog from '../../components/dialogs/permissionsDialog.vue'
import getUsersMixin from '@/mixins/getUsersMixin'
export default {
    name: 'UserGroupPage',
    mixins: [getUsersMixin],
    components: {
      permissionsDialog
    },
    data(){
      return {
        page: 1,
        search: '',
        //users: [],
        headers: [
          {
            text: "Nome",
            value: "name",
          },
          
          {
            text: 'E-mail',
            value: 'userName'
          },
          {
            text: "Permissões de usuário",
            value: "viewGroup"
          }
        ],
        selected_user: null,
        permissionsDialog: false,
        groupId: null
      }
    },

    computed: {
      /*
      pageCount () {
        return Math.ceil(this.users.length / 10)
      }*/
    },

    methods: {
      openPermissionsDialog(user) {
        this.selected_user = user;
        this.permissionsDialog = true;
      },

      pagination(page){
        this.getUsers('', this.groupId, page)
        this.page = page
      },

      async searchUser(value){
        this.getUsers(value, this.groupId, 1)
      },

    },

    async created(){
      this.groupId = this.$route.params.id
      this.getUsers('', this.groupId, 1)

    }
  }
</script>

<style>

</style>