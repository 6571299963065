<template>
  <v-form>
    <template v-for="parameter in parameters">
      <v-text-field
        v-if="parameter.dataType === 'String'"
        :key="parameter.name"
        :label="parameter.name"
        v-model="localValue[parameter.name]"
      >
      </v-text-field>
      <DatePickerField
        v-if="parameter.dataType === 'Date'"
        v-model="localValue[parameter.name]"
        displayFormat="dd/LL/yyyy"
        :key="parameter.name"
        :textFieldProps="{
          label: parameter.name,
          'prepend-inner-icon': 'mdi-calendar',
          'hide-details': 'auto',
          readonly: true,
          disabled: loading
        }"
      />
      <v-text-field
        v-if="parameter.dataType === 'Number'"
        :key="parameter.name"
        :label="parameter.name"
        v-model.number="localValue[parameter.name]"
      >
      </v-text-field>
      <v-checkbox
        v-if="parameter.dataType === 'Boolean'"
        :key="parameter.name"
        :label="parameter.name"
        v-model.number="localValue[parameter.name]"
      >
      </v-checkbox>
    </template>
  </v-form>
</template>

<script>
import DatePickerField from '../inputs/datePickerField.vue'

export default {

  name: 'ActionParamsForm',

  components: {
    DatePickerField
  },

  props: {
    value: {
      type: Object,
    },

    parameters: {
      type: Array,
    }
  },

  data () {
    return {
      localValue: {}
    }
  },

  watch: {
    localValue: {
      handler () {
        this.$emit('input', this.localValue)
      },
      deep: true
    }
  }

}
</script>

<style>

</style>