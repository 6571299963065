<template>
  <div>
    <v-app id="inspire">
      <v-main>
        <v-container fluid fill-height>
          <v-layout align-center justify-center >
            <v-flex xs5 sm8 md4>
              <v-card class="elevation-8">
                <v-toolbar dark :color="colorCard == null ? 'white' : colorCard">
                  <v-toolbar-title>Você já está autenticado</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form>
                    <v-col cols="12" align="center" >
                      <v-img  
                        :src="logo"
                         max-width="180"
                         max-height="200"
                      />

                      <h2>Você já está autenticado</h2>
                      <router-link to="/Home">Voltar para home</router-link>
                    </v-col>
                  <v-card-actions>
                  <v-spacer></v-spacer>
                  
                </v-card-actions>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
    name: "CardUserAuth",

    data(){
        return{
          logo: null,
          colorCard: null
        }
    },

    methods: {
        async getTenanty(){
          const lc = window.localStorage.getItem("user-login");
          const user = JSON.parse(lc);
          let tenanty = user[0];

          var req = await this.$profile.getByTenanty(tenanty);
          if(req.data.data != undefined){
            this.logo = `${process.env.VUE_APP_IMG_URL}/${req.data.data.logo}`;
            this.colorCard = req.data.data.color;
          }
        },
    },

    mounted(){
      this.getTenanty();
    }
}
</script>

<style scoped>

</style>