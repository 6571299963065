
<template>
  <div class="mt-3">
    <h4>Grids</h4>
    
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel v-for="(grid, i) in grid.properties" :key="i">
        <v-expansion-panel-header class="font-weight-bold">
          {{ grid.title }}
        </v-expansion-panel-header>
        
        <v-expansion-panel-content v-if="arrayIds.length > 0">

          <FormTable 
            :schemaName="grid.relationSchema"
            :readonly="true"
            :ids="arrayIds"
            :objPagination="pagination"
            @pagina="eventPagination"
            @searchForm="eventSearch"
          />

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import FormTable from '../table/FormTable.vue';
import EntityService from '../../services/entityService';
export default {
    name: 'ExpasionPanelGrid',
    props: ['grid', 'entity'],
    components: {
      FormTable
    },
    data(){
        return {
          panel: [],
          arrayIds: [],
          pagination: {},
          page: 1,
          search: ''
        }
    },

    watch: {
      panel: {
        async handler(value){
          await this.getData(value)

        },
        immediate: true
      }
    },

    methods: {

      async getData(position){
        if(position.length > 0){
          let grid = this.grid.properties[position]
          
          if(grid){
            
            let entityService = new EntityService(grid.relationSchema)


            let data = await entityService.search(grid.fieldChild, `[${this.entity[grid.fieldParent]}],[${this.search}]`, this.page, 30)
              
            this.pagination = { pages: data.data.data.pages, page:  data.data.data.page }

            this.arrayIds = data.data.data.data.map((resp) => {
              return resp.id;
            })
          }
        }
      },

      async eventPagination(e){
        this.page = e;
        await this.getData(this.panel)
      },

      async eventSearch(value){
        this.search = value
        await this.getData(this.panel)
      }
    },

    created(){
    }
}
</script>

<style>

</style>