<template>
  <v-text-field v-bind="$attrs" type="number" :value="value" @input="$emit('input', parseFloat($event))" >
  </v-text-field>
</template>

<script>
export default {

  props: {
    value: {
      type: Number
    }
  }

}
</script>

<style>

</style>