const FILTER_OPERATORS = {
  equalsTo: {
    id: 'equalsTo',
    symbol: '==',
    label: 'Igual • ==',
    handler (value, reference) {
      return value == reference;
    }
  },
  notEqualsTo: {
    id: 'notEqualsTo',
    symbol: '!=',
    label: 'Diferente • !=',
    handler (value, reference) {
      return value != reference;
    }
  },
  greaterThan: {
    id: 'greaterThan',
    symbol: '>',
    label: 'Maior • >',
    handler (value, reference) {
      return value > reference;
    }
  },
  greaterThanOrEqualsTo: {
    id: 'greaterThanOrEqualsTo',
    symbol: '>=',
    label: 'Maior ou Igual • >=',
    handler (value, reference) {
      return value >= reference;
    }
  },
  lessThan: {
    id: 'lessThan',
    symbol: '<',
    label: 'Menor • <',
    handler (value, reference) {
      return value < reference;
    }
  },
  lessThanOrEqualsTo: {
    id: 'lessThanOrEqualsTo',
    symbol: '<=',
    label: 'Menor ou Igual • <=',
    handler (value, reference) {
      return value <= reference;
    }
  },
  contains: {
    id: 'contains',
    symbol: 'CONTÉM',
    label: 'Contém',
    handler (value, reference) {
      return value.toString().includes(reference);
    }
  },
  notContains: {
    id: 'notContains',
    symbol: 'NÃO CONTÉM',
    label: 'Não Contém',
    handler (value, reference) {
      return !value.toString().includes(reference);
    }
  },
}

export {
  FILTER_OPERATORS
}