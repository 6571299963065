<template>
  <div>
    <v-container class="justify-center flex-column">
      <h1>Configuração de autenticação</h1>
      <!--
      <v-card width="500px" height="250px"  class="card elevation-8">
        <v-card-title>
         Configuração de autenticação
        </v-card-title>--->
<!--
        <v-card-text class="text">
          Habilitar autenticação de dois fatores ?
        </v-card-text>--->

        <v-row>
          <v-col cols="12" sm="6" md="8">
          <div class="d-flex align-center">
            <span class="font-weight-bold">Habilitar autenticação de dois fatores ?</span>
            <v-switch class="switch" v-model="switch1" @click="operation()"></v-switch>
          </div>

          <div class="d-flex">
            <v-text-field
              outlined
              label="API KEY"
              readonly
              :value="apiKey"
              class="mr-10"
            ></v-text-field>
            
            <v-btn
              color="primary"
              @click="generatedApiKey">
                Gerar api key
            </v-btn>
          </div>
          <!--
      </v-card>--->
          </v-col>
        </v-row>
    </v-container>
  </div>
</template>


<script>
  export default {
    name: "ConfigutionCard",
    data () {
      return {
        userId: "",
        a2f: null,
        switch1: false,
        userParams: null,
        apiKey: ''
      }
      
      
    },

    methods:{

      async generatedApiKey(){
        let response = await this.$auth.generatedApiKey(this.userParams.accessToken)
        console.log(response)

        this.apiKey = response.data.data.data
        this.userParams.apiKey = this.apiKey
        //await this.$auth.saveUserParams(this.userParams);
        window.location.reload()
      },

      async operation(){


        if(this.switch1 == true){
          this.switch1 = true
          
          this.userId = this.userParams.userId
          this.a2f = 1;

          await this.$auth.enableDisable2fa(this.userId, this.a2f);
          this.updateIndexDb(this.a2f);
          window.location.reload();

        
        } else {
          this.switch1 = false
          this.userId = this.userParams.userId
          this.a2f = 0;
          
          await this.$auth.enableDisable2fa(this.userId, this.a2f);
          this.updateIndexDb(this.a2f);
          window.location.reload();

        }


      },

      async updateIndexDb(a2f){
              this.userParams.a2f = a2f;
              await this.$auth.saveUserParams(this.userParams);
      },

      async verifyA2f(){
        
        const a2f = this.userParams.a2f;

        //const a2f = this.$route.params.a2f;
        if(a2f == 0){
          this.switch1 = false;
          } else {
            this.switch1 = true;
          }
      }
    },

    async created(){
      this.userParams = await this.$auth.getUserParams();
      this.apiKey = this.userParams.apiKey
      this.verifyA2f();
    },
  }
</script>


<style scoped>

.card {
  margin-top: 50px;
}

.switch {
    padding-left: 15px;
}
/*
.teste {
    display: flex;
}
*/

.text {
    margin: auto;
    font-size: 16px;
}

.container{
    margin-top: -35px;
    padding: 12px;
    display: flex;
}

</style>