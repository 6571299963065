<template>
  <div>
    <form @submit.prevent="searchForm" @input.prevent="searchTimeOut">
      <v-btn v-if="getPermissionCrud(schema, 'Create') > 0" @click="newItem" color="primary" icon dark :disabled="readyall || readonly">
        <v-icon>mdi-plus</v-icon></v-btn
      >
      <v-text-field
        :readonly="readyall || readonly"
        v-if="objPagination === undefined"
        class="pt-5 pb-5"
        v-model="search"
        chips
        clearable
        hide-details
        hide-selected
        prepend-icon="mdi-magnify"
        label="Search"
        solo
        @click:prepend="searchForm"
        @click:append="searchForm"
      />

      <v-text-field
        v-else
        class="pt-5 pb-5"
        v-model="searchRelationLink"
        chips
        clearable
        hide-details
        hide-selected
        prepend-icon="mdi-magnify"
        label="Search"
        solo
        @click:prepend="searchForm"
        @click:append="searchForm"
      />
    </form>
    <div v-on:mousemove="updateMouseCoordinates" v-if="getPermissionCrud(schema, 'Read') > 0">
      <v-data-table
        :headers="headersComp"
        :items="items"
        :items-per-page="pagination.limit"
        class="elevation-1"
        disable-sort
        hide-default-footer
        @click:row="rowClick"
      >
      </v-data-table>
    </div>
    <div class="text-center">
      <v-pagination
        v-if="objPagination === undefined"
        @input="changePage"
        v-model="pagination.page"
        :length="pagination.pages"
      ></v-pagination>

      <v-pagination
        v-else
        @input="paginationRelationLink"
        v-model="objPagination.page"
        :length="objPagination.pages"
      ></v-pagination>

      <v-menu
        v-model="rowMenu.showMenu"
        :position-x="rowMenu.menuX"
        :position-y="rowMenu.menuY"
      >
        <v-list dense rounded v-if="!readonly && !readyall">
          <v-list-item
            v-for="(action, index) in actions.filter(a => a.title == 'Visualizar/Editar' && getPermissionCrud(schema, 'Update') > 0
              || a.title == 'Deletar' && getPermissionCrud(schema, 'Delete') > 0)"
            :key="index"
            link
            @click="action.handle(typeDelete)"
          >
            <v-list-item-icon>
              <v-icon :color="action.color">{{ action.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ action.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="schemaActions && schemaActions.length"></v-divider>
          <v-list-item
            v-for="action in schemaActions()"
            :key="action.id"
            link
            @click="requestRunSchemaAction(action)"
          >
            <v-list-item-icon>
              <v-icon>{{ action.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ action.description }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="addItem" @click="itemSchema(itemSelected)">
            <v-list-item-icon>
              <v-icon>mdi-database-search</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Adicionar Item</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <entity-form-dialog
        v-if="schema"
        v-model="formDialog"
        :isSave="isSave"
        :schema="schema"
        :form="schema.form"
        :entity="itemSelected"
        :actions="customActions"
        :loading="loading"
        @confirm="saveItem"
        @delete="deleteItem"
        @cancel="cancelFormDialog"
        @dialog="($event) => (formDialog = $event)"
      ></entity-form-dialog> 
    </div>

    <v-dialog v-model="actionParameterDialog" width="75vw" persistent>
      <ActionDialog
        v-if="actionParameterDialog"
        :schema="schema"
        :entity="itemSelected"
        :action="requestedAction"
        :entityId="itemSelected.id || ''"
        @close="actionParameterDialog = false"
      >
      </ActionDialog>
    </v-dialog>
  </div>
</template>

<script>
import EntityService from "../../services/entityService";
import entityPageMixin from "../../mixins/entityPageMixin";
import actionPermissionsMixins from '@/mixins/actionPermissionsMixins'
import ActionDialog from '../../components/dialogs/actionDialog.vue'

export default {
  name: "FormTable",
  props: ["schemaName", "ids", "relation", "item", "open", "addItem", "objPagination", "readyall", "readonly"],
  components: {
    ActionDialog,
    EntityFormDialog: () =>
      import("../../components/dialogs/entityFormDialog.vue"),
  },
  mixins: [entityPageMixin, actionPermissionsMixins],
  watch: {
    async open(open) {
      if (open) {
        await this.getItems();
        this.searchRelationLink = ""
        this.search = ""
      } else {
        this.$emit("properties", this.tabGeneralComp)
      }
    },

    formDialog(value){
      if(value == false){
        this.$emit('executeAction')
      }
    },
  },

  async created(){
    this.componentKey += 1

    let params = await this.$auth.getUserParams();
    await this.getActionsPermission(params.userId)


    // if(!this.objPagination && this.$route.name !== 'AccountProfilePage'){
    if(this.objPagination != undefined && this.$route.name !== 'AccountProfilePage'){
      this.typeDelete = 'deleteMany'
    } 
  },

  methods: {
    itemSchema(item){
      this.$emit('itemSelect', item)
    },

    paginationRelationLink(p){
      this.$emit('pagina', p)
    },

    searchTimeOut(){
      
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.searchForm()
      }, this.doneTypingInterval)
    
    },

    async searchForm(){
      if(this.objPagination === undefined){
        this.searchItems()
      }

      else {
        this.$emit("searchForm", this.searchRelationLink)
      }
    },

    schemaActions() {
      var actionId = [];
      for (var i = 0; i < this.actionsList.length; i++) {
        actionId.push(this.actionsList[i].actionId);
      }

      if (!this?.customActions?.length) return [];
      else
        return this.customActions.filter(
          (action) =>
            action.callType === "Schema" && actionId.indexOf(action.id) !== -1
        );
    },

    requestRunSchemaAction(action) {
      this.requestedAction = action;
      this.actionParameterDialog = true;
    },
  },

  data() {
    return {
      entityService: new EntityService(this.schemaName),
      componentKey: 0,
      searchRelationLink: "",
      typeDelete: '',
      actionParameterDialog: false,
      delayTimer: null,
      doneTypingInterval: 1500,
    };
  },
};
</script>
<style scoped>
form {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.v-application .pb-5 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
