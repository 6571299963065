<template>
  <v-row>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="btn-icon"
            color="primary"
            icon
            dark
            v-on="on"
            v-bind="attrs"
          >
            <v-icon> mdi-plus </v-icon></v-btn
          >
<!--
          <v-btn
            class="btn-icon"
            color="primary"
            icon
            dark
            v-on="on"
            v-bind="attrs"
          >
            <v-icon> mdi-account </v-icon></v-btn
          >--->

      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Grupo</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="groupName"
              label="Nome"
              placeholder="Nome do grupo"
              outlined
              dense
            ></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false; groupName = ''">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="addGroup()">
            Salvar grupo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    groupName: "",
    dialog: false,
  }),

  methods: {
    async addGroup() {
      var group = this.groupName;

      if (group == "") {
        this.$notfy.error("Nome do grupo não foi informado");
      } else {
        let data = await this.$acessGroup.addGroup(group);

        if (data.data.success) {
          this.$notfy.success("Grupo cadastrado");
        }
        
        this.$emit("close");
        this.groupName = "";
        this.dialog = false;
      }
    },
  },

  mounted() {},
};
</script>

<style scoped>
.btn-icon {
  margin-right: 1010px;
  margin-left: 28px;
}

.msg {
}
</style>
