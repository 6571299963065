<template>
  <div>
    <div v-for="(item, index) in items" :key="index">
      <v-row>
        <v-text-field
        :ref="`textfield-${item.id}`"
          :label="item.description"
          v-model="item.value"
          outlined
          @mouseup="rowClick(item)"
          @blur="saveItem(item)"
        ></v-text-field>
      </v-row>
    </div>
  </div>
</template>

<script>
import EntityService from "../../services/entityService.js";
import entityPageMixin from "../../mixins/entityPageMixin";

export default {
  props: ["schemaName", "ids", "relation"],
  mixins: [entityPageMixin],
  components: {
  },
  data() {
    return {
      entityService: new EntityService(this.schemaName),
      itemGroupService: new EntityService("ItemGroup"),
      itemGroupCaracService: new EntityService("ItemGroupCarac"),
      itemCaracService: new EntityService("ItemCarac"),
      itemGroup: null,
      itemGroupCaracs: [],
    };
  },
  watch: {
    async "item.itemGroupId"(itemGroupId) {
      this.clean();
      await this.getItems();

      if (this.itemGroup && (itemGroupId != this.itemGroup.id)) {
        await this.changeItemGroup();
      }
    },
  },
  methods: {
      async saveItem(item) {
      let res = await this.entityService.insertOrUpdate(item);
      if (!res.data.success){ 
        let index = this.items.indexOf(item);
        this.items[index] = Object.assign({},this.itemSelected);
         return;
      }
      this.formDialog = false;
      await this.saveIdsInProps(res);
      await this.clearItemSelected();
      await this.getItems();
      this.$emit("update-item");
    },
    clean() {
      this.itemSelected = {};
      this.items = [];
      this.itemGroup = null;
      this.itemGroupCarac = [];
    },
    async getItemGroup() {
      if (!this.item.itemGroupId) return;
      let res = await this.itemGroupService.getById(this.item.itemGroupId);
      if (res.data.success) {
        this.itemGroup = res.data.data;

        if (
          !this.itemGroup.itemGroupCaracIds ||
          this.itemGroup.itemGroupCaracIds.length == 0
        )
          return;

        let resItemGroupCarac = await this.itemGroupCaracService.getAll(
          null,
          null,
          null,
          null,
          this.itemGroup.itemGroupCaracIds
        );

        if (resItemGroupCarac.data.success) {
          this.itemGroupCaracs = resItemGroupCarac.data.data.data;
        }
      }
    },
    async changeItemGroup() {
      if (this.ids.length > 0) {
        let idsToDelete = this.ids.map((id) => {
          return { id };
        });

        await this.deleteManyItems(idsToDelete, false);
      }
    },
    async createItemCaracs() {
      if (
        !this.itemGroup ||
        !this.itemGroupCaracs ||
        this.itemGroupCaracs.length == 0
      ) {
        return;
      }

      let caracsToInserts = this.itemGroupCaracs.filter(
        (itemGroupCarac) =>
          !this.items.find(
            (itemCarac) => itemCarac.itemGroupCaracId == itemGroupCarac.id
          )
      );

      if (caracsToInserts.length == 0) return;

      let itemCaracsToInserts = caracsToInserts.map((itemGroupCarac) => {
        return {
          itemGroupCaracId: itemGroupCarac.id,
        };
      });

      await itemCaracsToInserts.forEach(async (itemCarac) => {
        await this.saveItem(itemCarac);
      });
    },
    async getItems() {

      await this.getItemGroup();
      if (this.relation != null && (!this.ids || this.ids.length == 0)) {
        await this.createItemCaracs();
        return;
      }

      let res = await this.entityService.getAll(
        null,
        null,
        null,
        null,
        this.ids
      );
      if (res.data.success) {
        this.pagination.pages = res.data.data.pages;
        this.items = res.data.data.data;

        await this.$forceUpdate();
      }

      await this.createItemCaracs();
    },
  },
};
</script>


<style>
</style>