
<template>
  <v-row>

    <v-col v-if="objStockVol">

        <h2>
            Informações do volume
        </h2>
        
        <div class="mt-12">
            <p>Volume: {{ volume }}</p>
            <p>Local: {{ objStockVol.stockLocalCode }}</p>
            
            <v-data-table
                :headers="headers"
                :items="stockContents"
                disable-sort
                hide-default-footer
            ></v-data-table>
        </div>

    </v-col>

    <v-col v-else>
        <h2 class="text-center">Volume não encontrado</h2>
    </v-col>

  </v-row>
</template>


<script>
import EntityService from '../../services/entityService';
export default {
    data(){
        return {
            volume: '',
            tenanty: '',
            objStockVol: null,
            stockContents: [],
            headers: [ 
                { 
                    text: 'Código',
                    value: 'loteCode'
                },
                {
                    text: 'Descrição',
                    value: 'descriptionItem'
                },
                {
                    text: 'Quantidade',
                    value: 'quantidade'
                }
            ]
        }
    },


    methods: {

        async searchStockVol() {

            let entityService = new EntityService('StockVol')
            let req = await entityService.search('code', this.volume, 1, 1, null, 'Equals')
            this.objStockVol = req.data.data.data[0]

        },


        async getContentsVol(){

            if(this.objStockVol !== undefined && this.objStockVol.stockVolCont.length > 0){
                let stockVolCount = this.objStockVol?.stockVolCont.map(resp => {
                    return resp;
                }).join(',');

                let entityService = new EntityService('StockVolCont')
                let req = await entityService.search(null, null, 1, 50, stockVolCount, null, null)

                
                this.stockContents = req.data.data.data
            }
        }

    },

    async mounted(){
        this.volume = this.$route.query.v;
        this.tenanty = this.$route.query.t;

        let token = await this.$auth.getUserToken();

        const lc = window.localStorage.getItem("user-login");
        const user = JSON.parse(lc);
        let tenanty = user[0];

        if(token && this.tenanty == tenanty){
            await this.searchStockVol();
            await this.getContentsVol();
        } else {
            this.$router.push("home");
        }
    }
}
</script>

<style>

</style>